import React, { useState } from 'react'
import imgAmigos from '../../img/amigos-02.png'
import daviplata from '../../img/daviplata.svg'
import nequi from '../../img/nequi.svg'
import { Link } from "react-router-dom";
import '../../css/inicio/SeccionSolicita.css'

function SeccionSolicita() {
  return (
    <div>
      <div className="row seccion-solicita">
        <div className="col">
          <h1 className='mover'>Préstamos Online para darte una solución</h1>
          <p>Porque en tu bongo no solo préstamos dinero, también te ayudamos a hacer realidad tus proyectos.</p>
          <button className="btn-enunciado-solicita"><Link  className="blanco" to='/paga_prestamo'>¡Solicítalo Ahora!</Link></button>
          <h3 className='mover'>Recuerda nuestros métodos de transferencia</h3>
          <div className="row">
            <div className="col">
              <img src={daviplata} className="img-solicita1" />
            </div>
            <div className="col">
              <img src={nequi} className="img-solicita" />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <img className="imgAmigos" src={imgAmigos}></img>
        </div>
      </div>
    </div>
  )
}

export default SeccionSolicita
