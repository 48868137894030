import React from 'react'
import Footer from './Footer'
import mujer from '../img/mujer-celular.png'
import grupo157 from '../img/grupo157.svg'
import grupo158 from '../img/grupo158.svg'
import grupo159 from '../img/grupo159.svg'
import amigos from '../img/amigos-nosotros.png'
import grupo319 from '../img/grupo319.svg'
import grupo318 from '../img/grupo318.svg'
import grupo317 from '../img/grupo317.svg'
import grupo316 from '../img/grupo316.svg'
import grupo311 from '../img/grupo311.svg'
import grupo312 from '../img/grupo312.png'
import chica from '../img/chica-con-Dinero.png'
import pareja from '../img/pareja.png'
import daviplata from '../img/daviplata.svg'
import nequi from '../img/nequi.svg'
import '../css/ComoFunciona.css'
import { Link } from "react-router-dom";

function ComoFunciona() {
  return (
    <div className="como-funciona ">   
          <div className='row col-10 offset-md-2'>     
          <h1 className=''>Te contamos como funciona tu bongo</h1>
          </div> 
          <br></br>
          <div className='row col-8 offset-md-2'>
          <h3 className=''>Para solicitar tu préstamo debes seguir 4 sencillos pasos:</h3> 
          </div>    
          <br></br>                 
          <div className="row">
            <div className="col-md-3"><img className="imgFuncionaP" src={grupo319} /></div>
            <div className="col-md-3"><img className="imgFuncionaP" src={grupo318} /></div>
            <div className="col-md-3"><img className="imgFuncionaP" src={grupo317} /></div>
            <div className="col-md-3"><img className="imgFuncionaP" src={grupo316} /></div>
          </div> 
          <div className='row col-md-8 offset-md-3'>
          <h2 className='funcih2'>¡Y listo! Te hacemos la transferencia a tu billetera virtual</h2>
          </div>
          <br></br>
          <h1 className='otrolado'>Características de tu bongo</h1>
          <div className="col"><img className="imgFuncionaP1" src={grupo311} /></div>
          <div className='row col-9 offset-md-1'>
           <h4 className='numC'>*Número de cuotas: Puedes elegir pagar tu bongo en 1, 2 o 3 cuotas.</h4>  
           </div>
           <div className='row'>
             <h1 className='ultimo'>Beneficios de tu bongo</h1>
             <div className='col-md-4'>
              <h3 className='mas'>- La cuota es fija.</h3>              
              <h3 className='mas'>- Sin codeudor ni experiencia crediticia.</h3>             
              <h3 className='mas'>- Descontamos la cuota de tu crédito directamente por nómina.</h3>
             </div>
             <div className='col-md-4'>
             <h3 className='mas'>- La mejor tasa de interés del mercado</h3>             
              <h3 className='mas'>- Altos estándares de seguridad</h3>
             </div>
             <div className='col-md-4'>
             <div className="col"><img className="imgFuncionaP2" src={grupo312} /></div>
             </div>             
          </div>      
      
        <h1 className="titulo-azul">¿Por qué estamos Aquí?</h1>
        <hr className='hr'/>
        <p className='p col-md-11'>Estamos aquí para apoyarte en una emergencia, porque sabemos que las entidades bancarias y otros prestamistas no te dan las garantías que nosotros podemos ofrecerte. Estamos aquí para brindarte una solución con confianza y respaldo en el momento que lo necesitas.
        </p>
        <div className="row">
          <div className="col-md-4 imagenF"><img className="imgFunciona" src={grupo157} /><br></br>
            <b className='texto2'>Solución</b></div>
          <div className="col-md-4 imagenF"><img className="imgFunciona" src={grupo158} /><br></br>
            <b className='texto2'>Confianza</b></div>
          <div className="col-md-4 imagenF"><img className="imgFunciona" src={grupo159} /><br></br>
            <b className='texto2'>Respaldo</b></div>
        </div>
        <br></br><br></br>
        <div className="row seccion-funciona">
          <div className="col-md-7">
            <h1 className="h11" >Sobre nosotros</h1>
            <h3 className='h' >Somos más que una empresa, un grupo de amigos que quieren brindarte una mano en el momento que lo necesitas. Contamos con representación física para que puedas sentirte en confianza. Tenemos el respaldo de inversionistas que buscan un bienestar colectivo.</h3>
          </div>
          <div className="col-md-5">
          <br></br>
            <img className="imgChicaTel" src={amigos}></img>
          </div>
        </div>
        <div className="row amigos">
        {/* imagen */}
        <div className="col-md-5">
          <img src={chica} className="imgAmigos1"></img>
        </div>
        <div className="col-md-4 offset-md-2 acomodar">
        <b className='texto1'>Propósito</b>
          <h3 className='h2-funci'>Nuestro propósito es que no te estreses, que te relajes. Sabemos que cuando necesitas una ayuda, es incómodo pedirle dinero a otra persona, que te pregunta para qué, porque, etc. Con nosotros no tienes que tener nada de eso, te ayudamos y ya.</h3>
        </div>
      </div>
      <div className="row seccion-funciona">
          <div className="col-md-7">
            <b className='texto2f'>Misión y Visión</b>
            <br></br> <br></br><br></br> <br></br>
            <b className='texto2'>Misión:</b> <br></br>  <br></br>
            <h3 className='h'> Dar un préstamo de tu bongo a todos los colombianos y estar allí para dar solución a los imprevistos económicos que tenemos día a día.</h3>  <br></br>
            <b className='texto2'>Visión:</b>  <br></br>  <br></br>
            <h3 className='h'>Ser la empresa Fintech, líder del mercado de los microcréditos, cubriendo mercados objetivos que no han logrado su integración al sistema financiero, o que por alguna razón obtuvieron una mala calificación financiera y se les han cerrado puertas, creyendo nuevamente en ellos.</h3>  <br></br>
          </div>
          <div className="col-md-5">
            <img className="imgAmigos12" src={pareja}></img>
          </div>
        </div>
        <br></br>
        <div className='calcu'>
        <button className="footer-card-pregu"><Link  className="blanco" to='/paga_prestamo'>¡Solicítalo Ahora!</Link>&nbsp;&nbsp;&nbsp;</button>
        </div>
        <br></br>
        <br></br>
       <div className='row col-md-8 offset-md-3'>
        <b className='texto3'>Recuerda nuestros métodos de transferencia</b>
        </div>
        <br></br>
        <br></br>
          <div className="row">
            <div className="col-6 center">
              <img src={daviplata} className="img-funciona1" />
            </div>
            <div className="col-6 center">
              <img src={nequi} className="img-funciona" />
            </div>
          </div>
          <br></br>
          <br></br>
          
      <Footer></Footer>
    </div>
    )
}

export default ComoFunciona
