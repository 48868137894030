import React from 'react';
import '../../../css/inicio/calculadora/SegundoSlide.css';
import PropTypes from 'prop-types'
import Bancos from '../../../Data/bancos.json'



class SegundoSlide extends React.Component {

 
  render() {

    
    
    const { propiedadTesteo } = this.props; // destructuring of props
    
    return (
      <div className="carta-calculadora">
        <div className="titulo-segundo">
          <h1>Ingrese la siguiente <br />
            <p>Ten a la mano</p>
          </h1>
        </div>

        <div className='form-datos'>
          <form className="row g-3">

            <select className="input" >
              <option selected>Tipo de Cuenta</option>
              <option value="1">Ahorros/Nómina</option>
              <option value="2">Corriente</option>
            </select>

            <input type="text" className='input' placeholder='Número de Cuenta' />


            <select className="input" >
              <option className="input-select">Seleccione un Banco</option>
                {Bancos.map((data) =>{
                  return(
                   <>
                      
                      <option  value={data.id}>{data.nombre}</option>
                  </>
                  )
                })}
            </select>
        
            <input type="text" className="input" placeholder='Número de Documento' />
            <input type="text" className="input" placeholder='Nombre'/>
            <input type="text" className="input" placeholder='Número de Celular' />
          </form>
        </div>

        <div className="titulo-segundo">
          <p>¡Sin ellos no vas a poder obtener el Préstamo!</p>
        </div>
        <button className="footer-card" onClick={()=>propiedadTesteo('tercerSlide')}>
          <h1>Continuar</h1>
        </button>
      </div>
    )
  }
}

SegundoSlide.propTypes = {
  propiedadTesteo: PropTypes.func.isRequired
}

export default SegundoSlide

