import React from 'react';
import { Link, Outlet } from "react-router-dom";
import '../css/header.css';
import grupo180 from '../img/grupo180.svg'
//import logouser from '../img/icon_user.png';

function Header() {
  return (
    <div className="header">
      <nav className="navbar navbar-expand-lg navbar-light menu">
        <div className="container-fluid">
          <div className="li-menu-bold"><img src={grupo180} className="img-responsive"/></div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div  className="collapse navbar-collapse" class="justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li className="nav-item ">
                <Link className="li-menu" to='/'>Inicio</Link>
              </li>
              <li className="nav-item">
                <Link className="li-menu" to='/como_funciona'>¿Cómo Funciona?</Link>
              </li>
              <li className="nav-item">
                <Link className="li-menu" to='/paga_prestamo'>Solicitud de Préstamo</Link>
              </li>
              <li className="nav-item">
                <Link className="li-menu" to='/preguntas_frecuentes'>Preguntas Frecuentes</Link>
              </li>
            </ul>
            {/* <Link className="d-flex perfil" to='/perfil'>
              <div className="icon"></div>
              <p className="">Tu perfil</p>
            </Link> */}
            {/* <Link className="ir-a-perfil" to='/perfil' > 
              <div class="d-flex perfil">
                <div><img src={logouser} className="icon" /></div>
                <div class="col tu_perfil">Tu perfil</div>
              </div>
            </Link>  */}
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  )
}

export default Header