import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import ComoFunciona from './components/ComoFunciona';
import PagaPrestamo from './components/PagaPrestamo';
import PagaPrestamoSegundo from './components/PagaPrestamoSegundo';
import PagaPrestamoTercero from './components/PagaPrestamoTercero';
import PagaPrestamoCuarto from './components/PagaPrestamoCuarto';
import PagaPrestamoQuinto from './components/PagaPrestamoQuinto';
import PreguntasFrecuentes from './components/PreguntasFrecuentes';
import PoliticasPrivacidad from './components/PoliticasPrivacidad';
import TerminosCondiciones from './components/TerminosCondiciones';
import Contrato from './components/Contrato';
import Correo from './components/Correo';
import Perfil from './components/Perfil';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Inicio from './components/Inicio';
import PagaPrestamoTerceroNuevo from './components/PagaPrestamoTerceroNuevo';


ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
        <Route path="" element={<Inicio />} />
        <Route path="como_funciona" element={<ComoFunciona />} />
        <Route path="paga_prestamo" element={<PagaPrestamo />} />
        <Route path="paga_prestamo_segundo" element={<PagaPrestamoSegundo />} />
        {/* no descomentar */}
        {/* <Route path="paga_prestamo_cuarto" element={<PagaPrestamoTercero />} /> */}
        <Route path="paga_prestamo_tercero" element={<PagaPrestamoTerceroNuevo />} />
        <Route path="paga_prestamo_cuarto" element={<PagaPrestamoCuarto />} />
        <Route path="paga_prestamo_quinto" element={<PagaPrestamoQuinto />} />
        <Route path="politicas_privacidad" element={<PoliticasPrivacidad titulo={"POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES"} />} />
        <Route path="terminos_condiciones" element={<TerminosCondiciones titulo={"TÉRMINOS Y CONDICIONES GENERALES DEL SERVICIO"} />} />
        <Route path="contrato" element={<Contrato />} />
        <Route path="correo" element={<Correo />} />
        <Route path="preguntas_frecuentes" element={<PreguntasFrecuentes />} />
        <Route path="perfil" element={<Perfil />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
