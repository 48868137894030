import React, { useState } from 'react';
import '../../../css/inicio/calculadora/TercerSlide.css';
import PropTypes from 'prop-types'
import imgNequi from '../../../img/nequi-logo.png'
import imgDaviplata from '../../../img/logo-daviplata.png'
import img24horas from '../../../img/24horas.png'


class TercerSlide extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  mostraForm(e) {
     this.minimoHoras = null;
     this.imgBeps = this.imgBeps == false ? true : false;
      if (this.imgBeps == true) {
 
       this.beps = 'true';
     } else if (this.imgBeps == false) {
       this.beps = 'false';
     }
   }

  render() {
    const { propiedadTesteo } = this.props; // destructuring of props

    return (
      <div className="carta-calculadora">
        <div className='contenedor-tercero'>
          <h1>¿En cuanto tiempo quieres tu dinero?</h1>
          <h6 className='parche'>Escoge tu mejor opcion</h6>

          <div className='form-check'>
            <form className="row g-3">
              
              <h6 className='tiempo-p'> Quieres que llegue en menos de 3 horas</h6>
              <div className='tiempo'>
                <input type="radio" className = "check" id="nequi" name="cuenta"  value="nequi" onclick="mostraForm($event)" href="#"/>
                <img src={imgNequi} className="imagen-registro" />
                <input type="radio" className = "check" id="daviplata" name="cuenta"  value="daviplata" onclick="mostraForm($event)" href="#"/>
                <img src={imgDaviplata} className="imagen-registro-davi" />
              </div>

              <h6 className='tiempo-p'> Quieres que llegue en 48 horas</h6>
              <div className='tiempo'>
                 <div className="">
                  <input type="radio" className = "check" id="horas24" name="cuenta"  value="horas24" onclick="mostraForm($event)" href="#"/>
                  <img src={img24horas} className="imagen-registro" />
                </div>          
              </div>
            </form>
          </div> 

          <div>
          </div>
          <h6 className='parche'>¡Haz parte del parche!</h6>
        </div>
        <button className="footer-card" onClick={() => propiedadTesteo('cuartoSlide')}>
          <h1>Validar</h1>
        </button>
      </div>
    )
  }
}

TercerSlide.propTypes = {
  propiedadTesteo: PropTypes.func.isRequired
}

export default TercerSlide