import React from "react";
import Footer from "./Footer";
import grupo199 from "../img/Arte_Neo_2_pasos.png";
import grupo203 from "../img/imegenPasotresnuevo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import PagaPrestamo from "./PagaPrestamo";
import "../css/PagaPrestamoSegundo.css";
const backend = require("../backend/enviroments");

class PagaPrestamoTerceroNuevo extends React.Component {
  constructor() {
    super();

    this.dataLocal = JSON.parse(localStorage.getItem("testeo"));
    this.restric = localStorage.getItem("restric");
    if (this.restric != "^d7#G7z65nw%") {
      window.location = "/";
      localStorage.clear();
    }

    this.state = {
      pregunta1: "",
      pregunta2: "",
      pregunta3: "",
      pregunta4: "",
      pregunta5: "",
      pregunta6: "",
      pregunta7: "",
      pregunta8: "",
    };
  }

  componentDidMount() {
    //funcion para esconder o mostar el modal de semana
    //document.getElementById("semana").style.display=" ";
    //document.getElementById("fechalert").style.display="block"
    //document.getElementById("alerta").style.display="block"
    //document.getElementById("capacidad").style.display="block"
    //document.getElementById("creditos").style.display="block"
    //document.getElementById("fecha_ingreso").style.display="block"
  }

  valuestate = async (target) => {
    if (target.id == "pregunta1") {
      this.state.pregunta1 = target.value;
    }
    if (target.id == "pregunta2") {
      this.state.pregunta2 = target.value;
    }
    if (target.id == "pregunta3") {
      this.state.pregunta3 = target.value;
    }
    if (target.id == "pregunta4") {
      this.state.pregunta4 = target.value;
    }
    if (target.id == "pregunta5") {
      this.state.pregunta5 = target.value;
    }
    if (target.id == "pregunta6") {
      this.state.pregunta6 = target.value;
    }
    if (target.id == "pregunta7") {
      this.state.pregunta7 = target.value;
    }
    if (target.id == "pregunta8") {
      this.state.pregunta8 = target.value;
    }

  };

  validacioncampos = async () => {
    let pregunta1 = document.getElementById("pregunta1");
    let pregunta2 = document.getElementById("pregunta2");
    let pregunta3 = document.getElementById("pregunta3");
    let pregunta4 = document.getElementById("pregunta4");
    let pregunta5 = document.getElementById("pregunta5");
    let pregunta6 = document.getElementById("pregunta6");
    let pregunta7 = document.getElementById("pregunta7");
    let pregunta8 = document.getElementById("pregunta8");

    if (pregunta1.value == "" || pregunta1.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta1").style.borderColor = "red";
    } else if (pregunta2.value == "" || pregunta2.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta2").style.borderColor = "red";
    } else if (pregunta3.value == "" || pregunta3.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta3").style.borderColor = "red";
    } else if (pregunta4.value == "" || pregunta4.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta4").style.borderColor = "red";
    } else if (pregunta5.value == "" || pregunta5.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta5").style.borderColor = "red";
    } else if (pregunta6.value == "" || pregunta6.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta6").style.borderColor = "red";
    } else if (pregunta7.value == "" || pregunta7.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta7").style.borderColor = "red";
    } else if (pregunta8.value == "" || pregunta8.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("pregunta8").style.borderColor = "red";
    } else {
        this.enviarDataImput()
    }
  };

  enviarDataImput = () => {
    localStorage.setItem("preguntas", JSON.stringify(this.state));
    localStorage.setItem("restric", "P;&{6K+qGxA5");
    window.location = '/paga_prestamo_cuarto';
  };

  render() {
    return (
      <div className="paga-prestamo-segundo">
        <nav className="navbar navbar-expand-lg navbar-light menu">
          <div className="container-fluid">
            <h1 className="letra">Paso 3: Cuéntanos más sobre ti</h1>
          </div>
        </nav>
        <div className="row">
          <div className="col-md-5 ">
            <img className="importante-segundo" src={grupo199}></img>
            <img className="imgMujerCedula" src={grupo203}></img>
          </div>
          <div className="col-md-5 offset-1">
            <div className="formularioCodigo-segundo">
              <h1 class Name="claro">
                Género
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta1"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>
              <h1 class Name="claro">
                Estado civil
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta2"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="Soltero/a">Soltero/a</option>
                  <option value="Casado/a">Casado/a</option>
                  <option value="Unión libre">Unión libre</option>
                  <option value="Divorciado/a">Divorciado/a</option>
                </select>
              </div>

              <h1 class Name="claro">
                Tipo de vivienda
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta3"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="Arrendada">Arrendada</option>
                  <option value="Familiar">Familiar</option>
                  <option value="Propia">Propia</option>
                </select>
              </div>

              <h1 class Name="claro">
                Nivel de escolaridad
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta4"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="Bachillerato">Bachillerato</option>
                  <option value="Técnico / Tecnólogo">
                    Técnico / Tecnólogo
                  </option>
                  <option value="Profesional">Profesional</option>
                  <option value="Especialización / Maestría">
                    Especialización / Maestría
                  </option>
                </select>
              </div>

              <h1 class Name="claro">
                Estrato
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta5"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>

              <h1 class Name="claro">
                Personas a cargo
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta6"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4 o más">4 o más</option>
                </select>
              </div>

              <h1 class Name="claro">
                Antigüedad en la empresa
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta7"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="De 3 a 6 meses">De 3 a 6 meses</option>
                  <option value="Más de 6 a 12 meses">
                    Más de 6 a 12 meses
                  </option>
                  <option value="Más de 12 a 24 meses">
                    Más de 12 a 24 meses
                  </option>
                  <option value="Más de 24 a 36 meses">
                    Más de 24 a 36 meses
                  </option>
                  <option value="Más de 36 meses">Más de 36 meses</option>
                </select>
              </div>

              <h1 class Name="claro">
                Promedio de ingreso mensual
              </h1>
              <div className="input-group mb-3">
                <select
                  id="pregunta8"
                  onChange={(event) => this.valuestate(event.target)}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="De 1 a 2">De 1 a 2 SMLV</option>
                  <option value="Más de 2 a 3">Más de 2 a 3 SMLV</option>
                  <option value="Más de 3 a 4">Más de 3 a 4 SMLV</option>
                  <option value="Más de 4">Más de 4 SMLV</option>
                </select>
              </div>

              <div
                className="back-modal"
                id="alerta"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    <div className="modal-body">
                      <h5 className="h5S">
                        Recuerda que para solicitar un préstamo, debes ser parte
                        del equipo de trabajo de las empresas aliadas.
                      </h5>
                      <h5 className="h5S5">
                        Asegúrate que tú número de documento este bien escrito
                      </h5>
                      <button
                        type="button"
                        onClick={(e) =>
                          (document.getElementById("alerta").style.display =
                            "none")
                        }
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        Aceptar{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="back-modal"
                id="fechalert"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <div className="modal-body">
                      <h5 className="h5S">
                        Verifica tu fecha de nacimiento. Recuerda que debes ser
                        mayor de edad para solicitar un préstamo.
                      </h5>
                      <button
                        type="button"
                        onClick={(e) =>
                          (document.getElementById("fechalert").style.display =
                            "none")
                        }
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        Aceptar{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="capacidad"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <div className="modal-body">
                      <h5 className="h5S">
                        Tu capacidad de endeudamiento es menor al valor del
                        prestamo que estas solicitando. Por favor intenta
                        nuevamente y solicita un nuevo valor
                      </h5>
                      <button
                        type="button"
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <Link className="blanco" to="/paga_prestamo">
                          Regresar
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="creditos"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <div className="modal-body">
                      <h5 className="h5S">
                        Ya cuentas con un préstamo activo, cuando concluyas con
                        los pagos, podrás solicitar un nuevo préstamo en nuestra
                        plataforma
                      </h5>
                      <button
                        type="button"
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <Link className="blanco" to="/">
                          Regresar
                        </Link>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="fecha_ingreso"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    <div className="modal-body">
                      <h5 className="h5S">
                        No cumples con los requisitos para solicitar un
                        préstamo.
                      </h5>
                      <h5 className="h5S5">
                        Recuerda que debes llevar al menos <b>3 meses</b>{" "}
                        vinculado con nuestras empresas aliadas.
                      </h5>
                      <button
                        type="button"
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <Link className="blanco" to="/">
                          OK
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="diligenciar"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    <div className="modal-body">
                      <h5 className="h5S">
                        No diligenciaste correctamente los campos requeridos,
                        valida nuevamente
                      </h5>
                      <button
                        type="button"
                        onClick={(e) =>
                          (document.getElementById(
                            "diligenciar"
                          ).style.display = "none")
                        }
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        Aceptar{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="terminos"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    <div className="modal-body">
                      <h5 className="h5S">
                        Para continuar con el proceso debes aceptar nuestros
                        términos y condiciones
                      </h5>
                      <button
                        type="button"
                        onClick={(e) =>
                          (document.getElementById("terminos").style.display =
                            "none")
                        }
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        Aceptar{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.check && (
                <div className="formularioCodigoTT espacioS">
                  <b className="c2">
                    Al aceptar términos, condiciones y políticas de privacidad,
                    autoriza a tu bongo, compartir su información personal para
                    la verificación del historial crediticio y otras consultas
                    pertinentes a la aprobación del crédito.{" "}
                  </b>
                </div>
              )}
              {/* boton continuar a tercer paso */}
              <button
                id="envio"
                type="submit"
                onClick={this.validacioncampos}
                className="btn-enunciado-paga"
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
        {/* MODAL DE PLATAFORMA EN MANTENIMIENTO */}
        {/* <div className="back-modal" id="semana" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-header">
                <h4 className='novedad'>ESTAMOS TRABAJANDO PARA TI</h4>
              </div>                           
              <div className="modal-body">
              <h5 className='h5'>Estamos trabajando para brindarte un mejor servicio.</h5>
                  <br></br>
                  <h5 style="font-weight:600;"> Pronto estaremos de regreso.</h5>
                  <br></br>                          
              </div> 
            </div>
          </div>
        </div>              */}
        <Footer></Footer>
      </div>
    );
  }
}
export default PagaPrestamoTerceroNuevo;
