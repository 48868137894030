import React, { useState } from 'react'
import hombreTarjeta from '../../img/hombre-pagando-online.png'
import '../../css/inicio/SeccionCalculadora.css'
import { Link } from "react-router-dom";
import Calculadora from './calculadora/Calculadora'
import SegundoSlide from './calculadora/SegundoSlide'
import TercerSlide from './calculadora/TercerSlide'

function SeccionCalculadora() {

  let testeo;
  const [componenteCalculadora = 'calculadora', setCalculadora] = useState();
  function cambiarCalculadora(testeo) {
    setCalculadora(testeo)
  }
  switch (componenteCalculadora) {
    case 'calculadora':
      testeo = <Calculadora propiedadTesteo={cambiarCalculadora}></Calculadora>
      break;
    case 'segundoSlide':
      testeo = <SegundoSlide propiedadTesteo={cambiarCalculadora}></SegundoSlide>
      break;
    case 'tercerSlide':
      testeo = <TercerSlide propiedadTesteo={cambiarCalculadora}></TercerSlide>
      break;
    default:
      testeo = <Calculadora propiedadTesteo={cambiarCalculadora}></Calculadora>
      break;
  }


  return (
    <div className="row row-calculadora">
      <div className='row'>
        <div className="col-md-8 enunciado-principal">
          <h1>Con tu bongo solicita tu préstamo en 4 sencillos pasos</h1>
          <div className='row'>
            <div className="col-md-7">
              <img className="primeraimagen" src={hombreTarjeta} />
            </div>
            <div className="col-md-5">
              <p className='letra1'>Consulta ahora mismo y en minutos te enviaremos el dinero a tu billetera digital.</p>
              <button className="btn-enunciado azul"><Link  className="blanco" to='/como_funciona'>¿Cómo Funciona?</Link>&nbsp;&nbsp;&nbsp; <i className="fas fa-chevron-right"></i></button>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-calculadora">
          {testeo}
        </div>
      </div>
    </div>
  )
}

export default SeccionCalculadora
