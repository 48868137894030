import React from "react";
import Footer from "./Footer";
import grupo199 from "../img/grupo199.png";
import grupo203 from "../img/grupo203.png";
import { Link } from "react-router-dom";
import axios from "axios";
import PagaPrestamo from "./PagaPrestamo";
import "../css/PagaPrestamoSegundo.css";
const backend = require("../backend/enviroments");

class PagaPrestamoSegundo extends React.Component {
  constructor() {
    super();

    this.dataLocal = JSON.parse(localStorage.getItem("testeo"));
    this.restric = localStorage.getItem("restric");
    if (this.restric != "c059svdP4*R@") {
      window.location = "/";
      localStorage.clear();
    }

    this.state = {
      tipo: "",
      nume: "",
      nom: "",
      ape: "",
      fecha: "",
      celu: "",
      empre: "",
      nit: "",
      ingre: "",
      correo: "",
      bille: "",
      base: false,
    };
  }

  componentDidMount() {
    //funcion para esconder o mostar el modal de semana
    //document.getElementById("semana").style.display=" ";
    //document.getElementById("fechalert").style.display="block"
    //document.getElementById("alerta").style.display="block"
    //document.getElementById("capacidad").style.display="block"
    //document.getElementById("creditos").style.display="block"
    //document.getElementById("fecha_ingreso").style.display="block"
  }

  valuestate = async (target) => {
    if (target.id == "tipo") {
      this.state.tipo = target.value;
    }
    if (target.id == "nume") {
      this.state.nume = target.value;
    }
    if (target.id == "nom") {
      this.state.nom = target.value;
    }
    if (target.id == "ape") {
      this.state.ape = target.value;
    }
    if (target.id == "fecna") {
      this.state.fecha = target.value;
    }
    if (target.id == "celu") {
      this.state.celu = target.value;
    }
    if (target.id == "empre") {
      this.state.empre = target.value;
    }
    if (target.id == "ingre") {
      this.state.ingre = target.value;
    }
    if (target.id == "bille") {
      this.state.bille = target.value;
    }
    if (target.id == "correo") {
      this.state.correo = target.value;
    }

    this.enviarDataImput();

    let inputcedula = document.getElementById("nume");
    inputcedula.addEventListener("input", function () {
      if (this.value.length > 11) this.value = this.value.slice(0, 11);
    });

    let inputcelular = document.getElementById("celu");
    inputcelular.addEventListener("input", function () {
      if (this.value.length > 10) this.value = this.value.slice(0, 10);
    });
  };

  validacionFecha = () => {
    let date = new Date();

    const anoActual = parseInt(date.getFullYear());
    const mesActual = parseInt(date.getMonth() + 1);
    const diaActual = parseInt(date.getDate());

    const anoNacimiento = parseInt(String(this.state.fecha).substring(0, 4));
    const mesNacimiento = parseInt(String(this.state.fecha).substring(5, 7));
    const diaNacimiento = parseInt(String(this.state.fecha).substring(8, 10));

    let edad = anoActual - anoNacimiento;
    if (mesActual < mesNacimiento) {
      edad--;
    } else if (mesActual === mesNacimiento) {
      if (diaActual < diaNacimiento) {
        edad--;
      }
    }

    if (edad < 18) {
      document.getElementById("envio").style.display = "none";
      document.getElementById("fechalert").style.display = "block";
    } else {
      document.getElementById("envio").style.display = "";
    }
  };

  validacionBD = async () => {
    const elem = (document.getElementById("nume") || {}).value || "";
    let res2 = await axios.get(backend.getoneclient(elem));
    let empleado = await axios.get(backend.getemployes(elem));
    console.log(empleado)
    let valorPrestamo = this.dataLocal["valorPrestamo"];
    let valorDeuda = 0;

    try {
      let validacion = res2["data"]["Cliente"];
      var otrosEstadosCount = 0;
      var empresasFinalizadoLiquidado = [];
      var empresasFinalizadoLiquidadoCount = 0;
      var tieneEnLiquidacion = false; // Nueva variable para verificar si hay algún préstamo con estado "En Liquidación"

      for (var i = 0; i < validacion.length; i++) {
        var estadoPrestamo = validacion[i].estado_prestamo;
        var empresa = validacion[i].empresa;

        if (
          estadoPrestamo !== "Finalizado" &&
          estadoPrestamo !== "Finalizado Liquidado" &&
          estadoPrestamo !== "Rechazado"
        ) {
          otrosEstadosCount++;
        }

        if (estadoPrestamo === "Finalizado Liquidado") {
          empresasFinalizadoLiquidado.push(empresa);
          empresasFinalizadoLiquidadoCount++;
        }

        // Verificamos si el estado es "En Liquidación"
        if (estadoPrestamo === "En Liquidacion") {
          tieneEnLiquidacion = true;
        }

      }
      
      if (tieneEnLiquidacion) {
        document.getElementById("alertLiquidacion").style.display = "block";
      } else if (otrosEstadosCount > 1) {
        document.getElementById("creditos").style.display = "block";
      } else if (empresasFinalizadoLiquidadoCount > 0) {
        let capacidad = empleado["data"][0]["capacidad_endeudamiento"];
        console.log(capacidad)
        let empresaEmpleado = empleado["data"][0]["empresa"];
        if (
          empresasFinalizadoLiquidado[
            empresasFinalizadoLiquidado.length - 1
          ] === empresaEmpleado
        ) {
          document.getElementById("alertLiquidacion").style.display = "block";
        } 
      } else {
        document.getElementById("creditos").style.display = "none";
        document.getElementById("alertLiquidacion").style.display = "none";
      }
    } catch (error) {
      console.error(error);
    }
  };

  camposDB = async () => {
    const elem = (document.getElementById("nume") || {}).value || "";

    let res = await axios.get(backend.getemployes(elem));

    try {
      let fecha_ingreso = res["data"]["0"]["fecha_ingreso"];

      let date = new Date();
      let fechaLimite =
        date.getFullYear() +
        "-" +
        String(date.getMonth() - 2).padStart(2, "0") +
        "-" +
        String(date.getDate() + 1).padStart(2, "0");

      if (fecha_ingreso >= fechaLimite) {
        document.getElementById("fecha_ingreso").style.display = "block";
        document.getElementById("envio").style.display = "none";
      } else {
        document.getElementById("fecha_ingreso").style.display = " ";
        document.getElementById("envio").style.display = "";
      }
    } catch (error) {
      console.error(error);
    }

    if (res["data"].length > 0) {
      let nombres = res["data"][0]["nombres"];
      let apellidos = res["data"][0]["apellidos"];
      let empresa = res["data"][0]["empresa"];
      let tipo = res["data"][0]["tipo_documento"];

      document.getElementById("envio").style.display = "";

      this.state.nit = res["data"]["0"]["nit"];
      this.state.nom = document.getElementById("nom").value = nombres;
      this.state.ape = document.getElementById("ape").value = apellidos;
      this.state.empre = document.getElementById("empre").value = empresa;
      this.state.tipo = document.getElementById("tipo").value = tipo;
    } else {
      document.getElementById("envio").style.display = "none";
      document.getElementById("alerta").style.display = "block";

      this.state.nume = document.getElementById("nume").value = "";
      this.state.nom = document.getElementById("nom").value = "";
      this.state.ape = document.getElementById("ape").value = "";
      this.state.empre = document.getElementById("empre").value = "";
      this.state.tipo = document.getElementById("tipo").value = "";
      this.state.bille = document.getElementById("bille").value = "";
      // this.state.ingre = document.getElementById("ingre").value="";
      this.state.celu = document.getElementById("celu").value = "";
      this.state.fecna = document.getElementById("fecna").value = "";
      this.state.correo = document.getElementById("correo").value = "";
    }
  };

  validacioncampos = async () => {
    let tipodoc = document.getElementById("tipo");
    let numdoc = document.getElementById("nume");
    let fecha_nacimiento = document.getElementById("fecna");
    //  let ingresos=document.getElementById("ingre");
    let billetera = document.getElementById("bille");
    let celular = document.getElementById("celu");
    let correo = document.getElementById("correo");
    let nombres = document.getElementById("nom");
    let apellidos = document.getElementById("ape");
    let empresa = document.getElementById("empre");

    let date = new Date();

    //codigo para validacion de correo//
    let emailField = document.getElementById("correo");
    // Define our regular expression.
    let validEmail = /^\w+([.-_+]?\w+)@\w+([.-]?\w+)(\.\w{2,10})+$/;
    // Using test we can check if the text match the pattern
    if (validEmail.test(emailField.value)) {
    } else {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("correo").style.borderColor = "red";
      return false;
    }

    if (celular.value[0] != 3 || celular.value[0] != "3") {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("celu").style.borderColor = "red";
      return false;
    }

    const elem = document.getElementById("nume").value;
    let respuesta = await axios.get(backend.getemployes(elem));
    let res2 = await axios.get(backend.getoneclient(elem));
    let capacidad = respuesta["data"][0]["capacidad_endeudamiento"];
    let valorPrestamo = this.dataLocal["valorPrestamo"];
    let validacionDeuda = res2["data"]["Cliente"];
    let valorDeuda = 0;

    for (var i = 0; i < validacionDeuda.length; i++) {
      var estadoPrestamo = validacionDeuda[i].estado_prestamo;
      if (
        estadoPrestamo !== "Finalizado" &&
        estadoPrestamo !== "Finalizado Liquidado" &&
        estadoPrestamo !== "Rechazado" &&
        estadoPrestamo !== "En Liquidacion"
      ) {
        valorDeuda = valorDeuda + validacionDeuda[i].monto_prestamo;
      }
    }

    const anoActual = parseInt(date.getFullYear());
    const mesActual = parseInt(date.getMonth() + 1);
    const diaActual = parseInt(date.getDate());

    const anoNacimiento = parseInt(String(this.state.fecha).substring(0, 4));
    const mesNacimiento = parseInt(String(this.state.fecha).substring(5, 7));
    const diaNacimiento = parseInt(String(this.state.fecha).substring(8, 10));

    let edad = anoActual - anoNacimiento;
    if (mesActual < mesNacimiento) {
      edad--;
    } else if (mesActual === mesNacimiento) {
      if (diaActual < diaNacimiento) {
        edad--;
      }
    }

    if (numdoc.value == "" || numdoc.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("nume").style.borderColor = "red";
    } else if (tipodoc.value == "" || tipodoc.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("tipo").style.borderColor = "red";
    } else if (fecha_nacimiento.value == "" || fecha_nacimiento.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("fecna").style.borderColor = "red";
    } else if (edad < 18) {
      document.getElementById("envio").style.display = "none";
      document.getElementById("fechalert").style.display = "block";
      document.getElementById("fecna").style.borderColor = "red";
    } else if (correo.value == "" || correo.value == null) {
      alert("*Campo correo electrónico obligatorio*");
    } else if (billetera.value == "" || billetera.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("bille").style.borderColor = "red";
    } else if ((parseInt(valorPrestamo) + parseInt(valorDeuda)) > parseInt(capacidad)) {
      document.getElementById("capacidad").style.display = "block";
    } else if (celular.value == "" || celular.value == null) {
      document.getElementById("diligenciar").style.display = "block";
      document.getElementById("celu").style.borderColor = "red";
    } else if(nombres.value === "" || nombres.value === null){
      document.getElementById("alerta").style.display = "block";
    } else if(apellidos.value === "" || apellidos.value === null){
      document.getElementById("alerta").style.display = "block";
    } else if(empresa.value === "" || empresa.value === null){
      document.getElementById("alerta").style.display = "block";
    } else {
      window.location = '/paga_prestamo_tercero';
    }
  };

  enviarDataImput = () => {
    localStorage.setItem("info", JSON.stringify(this.state));
    localStorage.setItem("restric", "^d7#G7z65nw%");

    let informacion = localStorage.getItem("info");
  };

  render() {
    return (
      <div className="paga-prestamo-segundo">
        <nav className="navbar navbar-expand-lg navbar-light menu">
          <div className="container-fluid">
            <h1 className="letra">Paso 2: Información Personal</h1>
          </div>
        </nav>
        <div className="row">
          <div className="col-md-5 ">
            <img className="importante-segundo" src={grupo199}></img>
            <img className="imgMujerCedula" src={grupo203}></img>
          </div>
          <div className="col-md-5 offset-1">
            <div className="formularioCodigo-segundo">
              <h1 class Name="claro">
                Tipo de documento
              </h1>
              <div className="input-group mb-3">
                <select
                  id="tipo"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={() => {
                    this.camposDB();
                    this.validacionBD();
                  }}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="CEDULA DE CIUDADANIA">
                    Cédula de Ciudadanía
                  </option>
                  <option value="CEDULA DE EXTRANJERIA">
                    Cédula de Extranjería
                  </option>
                </select>
              </div>
              <h1 class Name="claro">
                Número de documento
              </h1>
              <div className="input-group mb-3">
                <input
                  id="nume"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={() => {
                    this.camposDB();
                    this.validacionBD();
                  }}
                  type="number"
                  className="form-control imput-select"
                  aria-label="Text input with dropdown button"
                  placeholder="Documento"
                />
                {/* <span className="span-obligatorio" id="spanNume">Campo requerido</span> */}
              </div>

              <h1 class Name="claro">
                Fecha de nacimiento
              </h1>
              <div className="input-group mb-3">
                <input
                  id="fecna"
                  onChange={(event) => {
                    this.valuestate(event.target);
                    this.validacionFecha();
                  }}
                  onClick={() => {
                    this.camposDB();
                    this.validacionBD();
                  }}
                  type="date"
                  className="form-control imput-select"
                  aria-label="Text input with dropdown button"
                  placeholder="Fecha de nacimiento"
                  max={"2004-12-31"}
                />
                {/* <span className="span-obligatorio" id="spanFecha">Campo requerido</span> */}
              </div>

              <h1 class Name="claro">
                Nombres
              </h1>
              <div className="input-group mb-3">
                <input
                  disabled
                  data-bs-toggle="modal"
                  id="nom"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={this.camposDB()}
                  type="text"
                  className="form-control imput-select1"
                  aria-label="Text input with dropdown button"
                  placeholder="Nombres"
                  required
                />
                {/* <span className="span-obligatorio" id="spanNom">Campo requerido</span> */}
              </div>
              <h1 class Name="claro">
                Apellidos
              </h1>
              <div className="input-group mb-3">
                <input
                  disabled
                  id="ape"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={this.camposDB}
                  type="text"
                  className="form-control imput-select1"
                  aria-label="Text input with dropdown button"
                  placeholder="Apellidos"
                  required
                />
                {/* <span className="span-obligatorio" id="spanApe">Campo requerido</span> */}
              </div>

              <h1 class Name="claro">
                Empresa
              </h1>
              <div className="input-group mb-3">
                <input
                  disabled
                  id="empre"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={this.camposDB}
                  type="text"
                  className="form-control imput-select1"
                  aria-label="Text input with dropdown button"
                  placeholder="Empresa"
                  required
                />
              </div>

              <h1 class Name="claro">
                Correo electrónico
              </h1>
              <div className="input-group mb-3">
                <input
                  id="correo"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={() => {
                    this.camposDB();
                    this.validacionBD();
                  }}
                  type="text"
                  className="form-control imput-select"
                  aria-label="Text input with dropdown button"
                  placeholder="Correo electrónico"
                />
                {/* <span className="span-obligatorio" id="spanNume">Campo requerido</span> */}
              </div>

              <h1 class Name="claro">
                Número de teléfono celular
              </h1>
              <div className="input-group mb-3">
                <input
                  id="celu"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={() => {
                    this.camposDB();
                    this.validacionBD();
                  }}
                  type="number"
                  className="form-control imput-select"
                  aria-label="Text input with dropdown button"
                  placeholder="Celular"
                  pattern="[0-9]+"
                />
                {/* <span className="span-obligatorio" id="spanCelu">Campo requerido</span> */}
              </div>

              {/* <h1 class Name='claro'>Promedio de ingreso mensual</h1> 
            <div className="input-group mb-3">
            <select id='ingre' onChange={event => this.valuestate(event.target)} onClick={()=>{this.camposDB();this.validacionBD()}} className="imput-select" required>
              <option selected></option>
              <option value="0 a 2">0 a 2 SMLV</option>
              <option value="3 a 7">3 a 7 SMLV</option>
              <option value="8 o +">8 SMLV o mas</option>
            </select> */}
              {/* <span className="span-obligatorio" id="spanIngre">Campo requerido</span> */}
              {/* </div>  */}

              <h1 class Name="claro">
                Billetera digital
              </h1>
              <div className="input-group mb-3">
                <select
                  id="bille"
                  onChange={(event) => this.valuestate(event.target)}
                  onClick={() => {
                    this.camposDB();
                    this.validacionBD();
                  }}
                  className="imput-select"
                  required
                >
                  <option selected></option>
                  <option value="Nequi">Nequi</option>
                  <option value="Daviplata">Daviplata</option>
                </select>
                {/* <span className="span-obligatorio" id="spanBille">Campo requerido</span> */}
              </div>

              <div
                className="back-modal"
                id="alerta"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    <div className="modal-body">
                      <h5 className="h5S">
                        Recuerda que para solicitar un tu bongo, debes ser parte
                        del equipo de trabajo de las empresas aliadas.
                      </h5>
                      <h5 className="h5S5">
                        Asegúrate que tú número de documento este bien escrito
                      </h5>
                      <button
                        type="button"
                        onClick={(e) =>
                          (document.getElementById("alerta").style.display =
                            "none")
                        }
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        Aceptar{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="back-modal"
                id="fechalert"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <div className="modal-body">
                      <h5 className="h5S">
                        Verifica tu fecha de nacimiento. Recuerda que debes ser
                        mayor de edad para solicitar un tu bongo.
                      </h5>
                      <button
                        type="button"
                        onClick={(e) =>
                          (document.getElementById("fechalert").style.display =
                            "none")
                        }
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        Aceptar{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="capacidad"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <div className="modal-body">
                      <h5 className="h5S">
                        Tu capacidad de endeudamiento es menor al valor del
                        prestamo que estas solicitando. Por favor intenta
                        nuevamente y solicita un nuevo valor
                      </h5>
                      <button
                        type="button"
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <Link className="blanco" to="/paga_prestamo">
                          Regresar
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="creditos"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <div className="modal-body">
                      <h5 className="h5S">
                        Ya cuentas con dos préstamos activos, cuando concluyas
                        con los pagos, podrás solicitar un nuevo préstamo en
                        nuestra plataforma
                      </h5>
                      <button
                        type="button"
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <Link className="blanco" to="/">
                          Regresar
                        </Link>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="alertLiquidacion"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    <div className="modal-body">
                      <h5 className="h5S">
                        Lo sentimos, no puedes realizar el proceso de solicitud.
                      </h5>
                      <h5 className="h5S">
                        Recuerda que debes ser parte del equipo de nuestras
                        empresas aliadas.
                      </h5>
                      <button
                        type="button"
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <Link className="blanco" to="/">
                          Regresar
                        </Link>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="fecha_ingreso"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    <div className="modal-body">
                      <h5 className="h5S">
                        No cumples con los requisitos para solicitar un
                        préstamo.
                      </h5>
                      <h5 className="h5S5">
                        Recuerda que debes llevar al menos <b>3 meses</b>{" "}
                        vinculado con nuestras empresas aliadas.
                      </h5>
                      <button
                        type="button"
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <Link className="blanco" to="/">
                          OK
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="back-modal"
                id="diligenciar"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `none` }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content curva">
                    <div className="modal-body">
                      <h5 className="h5S">
                        No diligenciaste correctamente los campos requeridos
                        para solicitar un préstamo, valida nuevamente
                      </h5>
                      <button
                        type="button"
                        onClick={(e) =>
                          (document.getElementById(
                            "diligenciar"
                          ).style.display = "none")
                        }
                        className="btn-enunciado-paga9"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        Aceptar{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* boton continuar a tercer paso */}
              <button
                id="envio"
                type="submit"
                onClick={this.validacioncampos}
                className="btn-enunciado-paga"
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
        {/* MODAL DE PLATAFORMA EN MANTENIMIENTO */}
        {/* <div className="back-modal" id="semana" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-header">
                <h4 className='novedad'>ESTAMOS TRABAJANDO PARA TI</h4>
              </div>                           
              <div className="modal-body">
              <h5 className='h5'>Estamos trabajando para brindarte un mejor servicio.</h5>
                  <br></br>
                  <h5 style="font-weight:600;"> Pronto estaremos de regreso.</h5>
                  <br></br>                          
              </div> 
            </div>
          </div>
        </div>              */}
        <Footer></Footer>
      </div>
    );
  }
}
export default PagaPrestamoSegundo;
