import React, { useState } from 'react';
import '../../../css/inicio/SeccionCalculadora2.css';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";


// function Calculadora(props) {
class Calculadora2 extends React.Component {

  dataLocal = null;

  constructor(){
    super();
    //VARIABLES PARA EL CALCULO DE DIAS DE INTERES
    let date = new Date();
    
    const diaActual = parseInt(date.getDate());
    const diaIntereses = 30 - diaActual;
    
    const porcentajeAnual = 30;
    //VARIABLE DE INTERES DIARIOS
    const interesDiario =
      (Math.pow(1 + porcentajeAnual / 100, 1 / 360) - 1) * 100/100;
    //VARIBALE QUE CALCULO EL PORCENTAJE TOTAL DEPENDIENDO DE LOS DIAS RESTANTES DEL PRIMER MES
    const totalInteresDiario=interesDiario*diaIntereses;


    let valorPrestamo = 100000;
    let cuotas = 1;
    // let plazo = 30;
    let porcenInteres = 2.21;
    let fechaPlazo = 30;
    let interes = Math.round((((valorPrestamo * porcenInteres) / 100) * (cuotas-1)+totalInteresDiario));
  
    this.dataLocal = JSON.parse(localStorage.getItem('testeo'))
    if (!this.dataLocal){
      this.dataLocal = {
        
        valorPrestamo : valorPrestamo,
        cuotas : cuotas,
        fechaPlazo : fechaPlazo,
        interes : interes, 
        tarifaAdministrativa : 10000,
        totalInteresDiario:interesDiario,
        // ivaTarifaAdministrativa : (10000 * 19) / 100,
        ivaTarifaAdministrativa : 0,
        consultaPerfil : 3000,
        totalPagar : (100000 + 2000 + 10000 + 1900 + 3000) / 1,
        diaIntereses:diaIntereses
      }
    }
    this.state = {
      valorPrestamo: this.dataLocal.valorPrestamo,
      cuotas: this.dataLocal.cuotas,
      fechaPlazo: this.dataLocal.fechaPlazo,
      interes: this.dataLocal.interes,
      totalInteresDiario:this.dataLocal.totalInteresDiario,
      tarifaAdministrativa: this.dataLocal.tarifaAdministrativa,
      ivaTarifaAdministrativa: this.dataLocal.ivaTarifaAdministrativa,
      consultaPerfil: this.dataLocal.consultaPerfil,
      totalPagar: this.dataLocal.totalPagar,
      diaIntereses:this.dataLocal.diaIntereses
    }
  }

  enviarData = (e) =>{
    localStorage.setItem('testeo', JSON.stringify(this.state))
  }

    
  llenarData = (e) => {
    if (e.target.dataset.tipo == "valor" && (e.target.className == 'button-active' || e.target.className == 'button-inactive')) {
      let tarifa = 10000;
      // let ivaTarifa = (tarifa * 19) / 100;
      let porcenInteres = 2.210;
      let ivaTarifa = 0;
      let interes = Math.round((e.target.value * (porcenInteres * (this.state.cuotas == 0 ? 1-1 : (this.state.cuotas-1)+this.state.totalInteresDiario))) / 100);
      let total = Math.round((parseInt(e.target.value) + parseInt(interes) + parseInt(tarifa) + parseInt(ivaTarifa) + parseInt(this.state.consultaPerfil)) / (this.state.cuotas == 0 ? 1 : this.state.cuotas));
      this.setState({
        valorPrestamo: e.target.value,
        interes: interes,
        tarifaAdministrativa: tarifa,
        ivaTarifaAdministrativa: ivaTarifa,
        totalPagar: Math.round(total)
      })
    } else if (e.target.dataset.tipo == "cuota" && (e.target.className == 'button-active' || e.target.className == 'button-inactive')) {
      let tarifa = 10000;
      // let ivaTarifa = (tarifa * 19) / 100;
      let porcenInteres = 2.210;
      let ivaTarifa = 0;
      let interes = Math.round((this.state.valorPrestamo * ((porcenInteres * (e.target.value-1))+this.state.totalInteresDiario)) / 100);
      let total = Math.round((parseInt(this.state.valorPrestamo) + parseInt(interes) + parseInt(tarifa) + parseInt(ivaTarifa) + parseInt(this.state.consultaPerfil)) / parseInt(e.target.value));
      this.setState({
        cuotas: e.target.value,
        valorPrestamo: this.state.valorPrestamo,
        interes: interes,
        tarifaAdministrativa: tarifa,
        ivaTarifaAdministrativa: ivaTarifa,
        totalPagar: Math.round(total)
      });
    } else if (e.target.dataset.tipo == "plazo" && (e.target.className == 'button-active' || e.target.className == 'button-inactive')) {
      this.setState({
        plazo: e.target.value,
        fechaPlazo: e.target.value
      })
    }
  }


  render() {

    // const propiedadTesteo = this.props.propiedadTesteo;
    const { propiedadTesteo } = this.props;

    return (      
      <div className="carta-calculadora1 lados">
        <div className="row">
          <div className="col-md-6">
            <div className="titulo-carta1 header-card1">
              <h1>¿Cuánto te prestamos?</h1>
            </div>
            <div className="div-seleccion-opciones1">
              <div className="row">
                <div className="col-4 center">
                  <button className={(this.state.valorPrestamo == 100000 ? "button-active" : "button-inactive")} 
                    onClick={(e) => this.llenarData(e)} 
                    data-tipo="valor"
                    value="100000"
                    >100.000<i className="far fa-check-circle"></i>
                  </button>
                  </div>
                  <div className="col-4 center">
                  <button
                    className={(this.state.valorPrestamo == 200000 ? "button-active" : "button-inactive")}
                    onClick={(e) => this.llenarData(e)}
                    data-tipo="valor"
                    value="200000"
                    >200.000<i className="far fa-check-circle"></i>
                  </button>
                  </div>
                  <div className="col-4 center">
                  <button
                    className={(this.state.valorPrestamo == 300000 ? "button-active" : "button-inactive")}
                    onClick={(e) => this.llenarData(e)}
                    data-tipo="valor"
                    value="300000"
                  >300.000<i className="far fa-check-circle"></i></button>
                  </div>
                </div>
                <div className='row'>
                 <div className='col-6 center'>
                  <button
                    className={(this.state.valorPrestamo == 400000 ? "button-active" : "button-inactive")}
                    onClick={(e) => this.llenarData(e)}
                    data-tipo="valor"
                    value="400000"
                  >400.000<i className="far fa-check-circle"></i></button>
                  </div>
                  <div className='col-6 center'>
                  <button
                    className={(this.state.valorPrestamo == 500000 ? "button-active" : "button-inactive")}
                    onClick={(e) => this.llenarData(e)}
                    data-tipo="valor"
                    value="500000"
                  >500.000<i className="far fa-check-circle"></i></button>
                </div>          
              </div>  

              <div className="titulo-carta1 header-card1">
                <h1>¿A cuántas cuotas?</h1>
              </div>
              <div className="row">
                <div className="col-4 center">
                  <button
                    className={(this.state.cuotas == 1 ? "button-active" : "button-inactive")}
                    // onClick={(e) => setCuotas(1)}
                    onClick={(e) => this.llenarData(e)}
                    data-tipo="cuota"
                    value="1"
                  >1 Cuota<i className="far fa-check-circle"></i>
                  </button>
                  </div>
                  <div className="col-4 center">
                  <button
                    className={(this.state.cuotas == 2 ? "button-active" : "button-inactive")}
                    // onClick={(e) => setCuotas(2)}
                    onClick={(e) => this.llenarData(e)}
                    data-tipo="cuota"
                    value="2"
                  >2 Cuotas<i className="far fa-check-circle"></i></button>
                  </div>
                  <div className="col-4 center">
                  <button
                    className={(this.state.cuotas == 3 ? "button-active" : "button-inactive")}
                    // onClick={(e) => setCuotas(3)}
                    onClick={(e) => this.llenarData(e)}
                    data-tipo="cuota"
                    value="3"
                  >3 Cuotas<i className="far fa-check-circle"></i></button>
                </div>            
              </div>
            </div>
          </div>
          
        <div className="col-md-6">
        <div className="body-formulario-prestamo1">
          <p className="titulo">Resumen de préstamo</p>
          <div className="row">
            <div className="col-md-8">
              <p className="suave izquierda">Valor solicitado</p>
            </div>
            <div className="col">
              <p className="suave derecha">{`$ ` + new Intl.NumberFormat('es-MX').format(this.state.valorPrestamo)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="suave izquierda">Cuotas del prestamo</p>
            </div>
            <div className="col">
              <p className="suave derecha">{this.state.cuotas}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="suave izquierda">Intereses 2.21%</p>
            </div>
            <div className="col">
              <p className="suave derecha">{(this.state.interes == null ? "-" : `$ ${new Intl.NumberFormat('es-MX').format(this.state.interes)}`)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="suave izquierda">Tarifa Administrativa</p>
            </div>
            <div className="col">
              <p className="suave derecha">$ {new Intl.NumberFormat('es-MX').format(this.state.tarifaAdministrativa)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="suave izquierda">IVA Tarifa Administrativa</p>
            </div>
            <div className="col">
              <p className="suave derecha">$ {new Intl.NumberFormat('es-MX').format(this.state.ivaTarifaAdministrativa)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="suave izquierda">Consultar Perfil</p>
            </div>
            <div className="col">
              <p className="suave derecha">$ {new Intl.NumberFormat('es-MX').format(this.state.consultaPerfil)}</p>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-8">
              <p className="llenita izquierda">Total a Pagar por cada cuota</p>
            </div>
            <div className="col">
              <p className="llenita derecha">$ {new Intl.NumberFormat('es-MX').format(this.state.totalPagar)}</p>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-8">
              <p className="llenita izquierda">Fecha de Pago</p>
            </div>
            <div className="col">
              <p className="llenita derecha">{this.state.fechaPlazo}</p>
            </div>
          </div> */}
          <br></br>
          <br></br>
          <button className="footer-card1" onClick={(e) => this.enviarData(e)}><Link  className="blanco" to='/paga_prestamo'>¡Solicítalo Ahora!</Link>&nbsp;&nbsp;&nbsp;</button>
        </div>
        </div>
        
        </div>
        {/* footer card */}
        
      </div>
    );
  }


}

Calculadora2.propTypes = {
  propiedadTesteo: PropTypes.func.isRequired
}

export default Calculadora2




