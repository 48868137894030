export default function calcularFechas(n_cuotas) {
    const fechaActual = new Date();
    const diaActual = fechaActual.getDate();
    const mesActual = fechaActual.getMonth();
    const anioActual = fechaActual.getFullYear();
  
    let primeraFecha, segundaFecha, terceraFecha;
  
    // Función para obtener el último día del mes, teniendo en cuenta restar 1 si es 31
    function ultimoDiaDelMes(anio, mes) {
      const ultimoDia = new Date(anio, mes + 1, 0).getDate();
      return ultimoDia === 31 ? 30 : ultimoDia;
    }
  
    if (diaActual <= 9) { // se ajusta el dia de corte al 10 del mes 
      primeraFecha = new Date(
        anioActual,
        mesActual,
        ultimoDiaDelMes(anioActual, mesActual)
      );
      segundaFecha = new Date(
        anioActual,
        mesActual + 1,
        ultimoDiaDelMes(anioActual, mesActual + 1)
      );
      terceraFecha = new Date(
        anioActual,
        mesActual + 2,
        ultimoDiaDelMes(anioActual, mesActual + 2)
      );
    } else {
      primeraFecha = new Date(
        anioActual,
        mesActual + 1,
        ultimoDiaDelMes(anioActual, mesActual + 1)
      );
      segundaFecha = new Date(
        anioActual,
        mesActual + 2,
        ultimoDiaDelMes(anioActual, mesActual + 2)
      );
      terceraFecha = new Date(
        anioActual,
        mesActual + 3,
        ultimoDiaDelMes(anioActual, mesActual + 3)
      );
    }
  
    const opcionesFormato = { year: "numeric", month: "numeric", day: "numeric" };
    const formato = new Intl.DateTimeFormat("es-ES", opcionesFormato);
  
    const fechas = {
      fecha_primera_cuota: formato.format(primeraFecha),
      fecha_segunda_cuota: formato.format(segundaFecha),
      fecha_tercera_cuota: formato.format(terceraFecha),
      estado_cuota1: "Pendiente",
      estado_cuota2: "Pendiente",
      estado_cuota3: "Pendiente",
    };
  
    if (parseInt(n_cuotas) === 1) {
      fechas.fecha_segunda_cuota = "No Aplica";
      fechas.fecha_tercera_cuota = "No Aplica";
      fechas.estado_cuota2 = "No Aplica";
      fechas.estado_cuota3 = "No Aplica";
  
    } else if (parseInt(n_cuotas) === 2) {
      fechas.fecha_tercera_cuota = "No Aplica";
      fechas.estado_cuota3 = "No Aplica";
    }
    
    return fechas
  
  
    localStorage.setItem("fechas_pago", JSON.stringify(fechas));
}
