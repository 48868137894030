import { Helmet } from "react-helmet" 
import React from 'react'

export default function Component() {
     return ( <> <Helmet> <script src="https://web-button.getmati.com/button.js">
     </script>
     <mati-button
       clientid="6419f4de51f18f001bc2d624"
       flowId="6419f4de51f18f001bc2d623"
       id="metamap-button" 
     /> </Helmet> ... </> 
     ) 
    }
