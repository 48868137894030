//URL DE PRODUCCION 
let Url="https://apineopage.tubongo.com"
//URL DE PRE PREPRODUCCION 
//  let Url="http://190.109.16.229:10259"

function createclient () {
     
    return Url+"/api/add"

}

function getclients (){

    return Url+"/api/clientes"
}

function getoneclient (param){

    return Url+"/api/searchcliente/"+param
}

function getemployes (param){

    return Url+"/api/searchempleado/"+param
}

module.exports={
    createclient,
    getclients,
    getoneclient,
    getemployes,
}