import React, { useState } from 'react'

import grupo150 from '../../img/grupo150.svg'
import grupo151 from '../../img/grupo151.svg'
import grupo152 from '../../img/grupo152.svg'
import grupo153 from '../../img/grupo153.svg'
import '../../css/inicio/SeccionBurbujas.css'

function SeccionBurbujas() {
  return (
    <div>
      <div className="row padre-burbujas">
        <h1 className="titulo-azul-burbujas">Nuestros requisitos</h1>
        <hr />
        <p>
          Accede de manera muy sencilla a todos nuestros beneficios cumpliendo
          con muy pocos requisitos.
        </p>
        <div className="row">
          <div className="col-md-3 col-imagen">
            <img className="imgBurbujas" src={grupo150} /><br></br>
            <b className='aquiBu'>Tener billetera digital (Nequi o Daviplata)</b>
          </div>
          <div className="col-md-3 col-imagen">
            <img className="imgBurbujas"s src={grupo151} /><br></br>
            <b className='aquiBu'>Pertenecer a nuestras empresas con convenio</b>
          </div>
          <div className="col-md-3 col-imagen">
            <img className="imgBurbujas" src={grupo152} /><br></br>
            <b className='aquiBu'>Ser mayor de edad, ciudadano colombiano o extranjero en situación legal.</b>
          </div>
          <div className="col-md-3 col-imagen">
            <img className="imgBurbujas" src={grupo153} /><br></br>
            <b className='aquiBu'>Línea de celular propia y correo electrónico</b>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeccionBurbujas
