import React from "react";
import Footer from "./Footer";
import axios from "axios";
import grupo217 from "../img/grupo217.png";
import homem from "../img/homem.png";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import sendEmail from "../../src/sendEmail";
// import { useNavigate } from "react-router-dom";
import "../css/PagaPrestamoCuarto.css";
import { isObject } from "util";
import VerifyOTP from "../components/inicio/otp/VerifyOTP";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

class PagaPrestamoCuarto extends React.Component {
  // navigate = useNavigate()
  dataLocal = null;
  dataInfo = null;
  hoy = null;

  constructor() {
    super();
    // this.hoy = new Date()
    const Fecha = new Date();
    this.hoy = Fecha.toDateString();
    this.dataLocal = JSON.parse(localStorage.getItem("testeo"));
    this.numbilleteraDigital = JSON.parse(
      localStorage.getItem("num_billetera")
    );
    this.dataInfo = JSON.parse(localStorage.getItem("info"));
    this.restric = localStorage.getItem("restric");
    this.otpValidationResult = JSON.parse(
      localStorage.getItem("otpVerification")
    );

    if (this.restric != "P;&{6K+qGxA5") {
      window.location = "/";
      localStorage.clear();
    }
    this.state = {
      valorPrestamo: this.dataLocal.valorPrestamo,
      cuotas: this.dataLocal.cuotas,
      fechaPlazo: this.dataLocal.fechaPlazo,
      interes: this.dataLocal.interes,
      tarifaAdministrativa: this.dataLocal.tarifaAdministrativa,
      ivaTarifaAdministrativa: this.dataLocal.ivaTarifaAdministrativa,
      consultaPerfil: this.dataLocal.consultaPerfil,
      totalPagar: this.dataLocal.totalPagar,
      celu: this.dataInfo.celu,
      bille: this.dataInfo.bille,
      correo: this.dataInfo.correo,
      nom: this.dataInfo.nom,
      nume: this.dataInfo.nume,
      fecha: this.dataInfo.Fecha,
      empre: this.dataLocal.empre,
      nit: this.dataInfo.nit,
      ingre: this.dataInfo.ingre,
      check: false,
      check_des: false,
      numbille: "",
      confirmationResult: "",
      sendsms: false,
      intentos: 0,
      otp: "",
    };
  }

  enviarDataImput = () => {
    localStorage.setItem("info", JSON.stringify(this.state));
  };

  SendSMSFirebase = (e) => {
    let phoneNumber = "";
    let numbille = document.getElementById("numbille");
    numbille.value == ""
      ? (phoneNumber = "+57" + numbille.placeholder)
      : (phoneNumber = "+57" + numbille.value);
    const auth = getAuth();

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        },
        auth
      );
    }

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        this.state.intentos += 1;
        console.log(this.state.intentos);
        if (this.state.intentos > 1) {
          document.getElementById("botonsms").disabled = true;
        }
        console.log(confirmationResult);
        window.confirmationResult = confirmationResult;

        this.setState({ confirmationResult: confirmationResult });
        this.setState({ sendsms: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  regresar(){
    window.location = "/";
    localStorage.clear();
  }

  enviarEmail = (e) => {
    const fechaCalculadora = new Date(localStorage.getItem("fechaHoraActual"));
    var fechaHoraActual = new Date();

    // Calcular la diferencia en milisegundos
    var diferenciaEnMilisegundos = fechaHoraActual - fechaCalculadora;

    // Convertir la diferencia en minutos
    var diferenciaEnMinutos = diferenciaEnMilisegundos / (1000 * 60);

    let numbille = document.getElementById("numbille");
    let date = new Date();
    let fechaC =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear();
    let valorDeuda = this.dataLocal.totalPagar * this.dataLocal.cuotas;
    let hora =
      String(date.getHours()) +
      ":" +
      String(date.getMinutes()) +
      ":" +
      String(date.getSeconds());

    let habeasdata = document.getElementById("contrato").checked;
    let nomina = document.getElementById("nomina").checked;
    let otpValidation = JSON.parse(localStorage.getItem("otpVerification"));

    if (habeasdata == false && nomina == true) {
      document.getElementById("terminos").style.display = "block";
    } else if (habeasdata == true && nomina == false) {
      document.getElementById("nominaObli").style.display = "block";
    } else if (!otpValidation) {
      document.getElementById("otpError").style.display = "block";
    } else if (habeasdata == false && nomina == false) {
      document.getElementById("terminos").style.display = "block";
      document.getElementById("nominaObli").style.display = "block";
    } else if (diferenciaEnMinutos >= 20) {
      document.getElementById("ErrorFecha").style.display = "block";
    } else {
      if (numbille.value) {
        this.state.numbille = numbille.value;
      } else {
        this.state.numbille = numbille.placeholder;
      }

      localStorage.setItem("num_billetera", JSON.stringify(this.state));
      window.location = "/paga_prestamo_quinto";
    }
  };

  enviarData = (e) => {
    localStorage.setItem("testeo", JSON.stringify(this.state));
  };

  celustate = (target) => {
    let inputNumBille = document.getElementById("numbille");
    inputNumBille.addEventListener("input", function () {
      if (this.value.length > 10) this.value = this.value.slice(0, 10);
    });
    if (target.id == "numbille") {
      this.state.numbille = target.value;
      localStorage.setItem("num_billetera", JSON.stringify(this.state));
    }
  };

  billestate = (target) => {
    if (target.id == "bille4") {
      this.state.bille = target.value;
    }
  };

  mostrartexto = (event) => {
    event.preventDefault();
  };

  llenarData = (e) => {
    if (
      e.target.dataset.tipo == "valor" &&
      (e.target.className == "button-active" ||
        e.target.className == "button-inactive")
    ) {
      let tarifa = 10000;
      let ivaTarifa = (tarifa * 19) / 100;
      let interes =
        (e.target.value *
          (2 * (this.state.cuotas == 0 ? 1 : this.state.cuotas))) /
        100;
      let total =
        (parseInt(e.target.value) +
          parseInt(interes) / (this.state.cuotas == 0 ? 1 : this.state.cuotas) +
          parseInt(tarifa) +
          parseInt(ivaTarifa) +
          parseInt(this.state.consultaPerfil)) /
        (this.state.cuotas == 0 ? 1 : this.state.cuotas);
      this.setState({
        valorPrestamo: e.target.value,
        interes: interes,
        tarifaAdministrativa: tarifa,
        ivaTarifaAdministrativa: ivaTarifa,
        totalPagar: total,
      });
    } else if (
      e.target.dataset.tipo == "cuota" &&
      (e.target.className == "button-active" ||
        e.target.className == "button-inactive")
    ) {
      this.setState({
        cuotas: e.target.value,
        interes: (this.state.valorPrestamo * (2 * e.target.value)) / 100,
      });
    } else if (
      e.target.dataset.tipo == "plazo" &&
      (e.target.className == "button-active" ||
        e.target.className == "button-inactive")
    ) {
      this.setState({
        plazo: e.target.value,
        fechaPlazo: e.target.value,
      });
    }
  };

  terminos = () => {
    this.setState({
      check: !this.state.check,
    });
  };

  descuento = () => {
    this.setState({
      check_des: !this.state.check_des,
    });
  };

  render() {
    let valitadion = localStorage.getItem("otpVerification");
    console.log("valitadion", valitadion);
    return (
      <div className="paga-prestamo-cuarto">
        <div className="row">
          <div className="col-md-5 izquierdaT d-flex flex-column justify-content-between align-items-center">
            <h1 className="h1cuarto ">¡Confirma tu celular ahora!</h1>

            <h3 className="h3cuarto col-10">
              Solo falta verificar tu número de celular para completar el
              proceso. ¡No te lo pierdas!
            </h3>

            <h3 className="h3cuarto col-10">
              Continúa con el último paso para adquirir tu préstamo.
            </h3>

            <img className="imgHombre" src={homem}></img>
          </div>

          <div className="col-md-6 d-flex flex-column justify-align-content-around">
            <div className="formularioCodigo-cuartoB">
              <h1>Resumen del préstamo</h1>
              <div className="row">
                <div className="col-3 d-flex flex-column align-items-center">
                  <b className="cub">Valor solicitado</b>
                  <h3>
                    {" "}
                    {`$` +
                      new Intl.NumberFormat("es-MX").format(
                        this.state.valorPrestamo
                      )}
                  </h3>
                </div>

                <div className="col-3 d-flex flex-column align-items-center">
                  <b className="cub">Cuotas</b>
                  <h3> {this.state.cuotas}</h3>
                </div>

                <div className="col-3 d-flex flex-column align-items-center">
                  <b className="cub">Consulta de perfil</b>
                  <h3>
                    {" "}
                    $
                    {new Intl.NumberFormat("es-MX").format(
                      this.state.consultaPerfil
                    )}
                  </h3>
                </div>

                <div className="col-3 d-flex flex-column align-items-center">
                  <b className="cub">Día del préstamo</b>
                  <h3> {this.hoy} </h3>
                </div>

                <div className="col-3 d-flex flex-column align-items-center">
                  <b className="cub">Interés</b>
                  <h3>
                    {" "}
                    $
                    {new Intl.NumberFormat("es-MX").format(
                      this.state.interes.toFixed()
                    )}
                  </h3>
                </div>

                <div className="col-3 d-flex flex-column align-items-center">
                  <b className="cub">Tarifa administrativa </b>
                  <h3>
                    {" "}
                    $
                    {new Intl.NumberFormat("es-MX").format(
                      this.state.tarifaAdministrativa
                    )}
                  </h3>
                </div>

                <div className="col-3 d-flex flex-column align-items-center">
                  <b className="cub">IVA tarifa administrativa</b>
                  <h3>
                    {" "}
                    $
                    {new Intl.NumberFormat("es-MX").format(
                      this.state.ivaTarifaAdministrativa
                    )}
                  </h3>
                </div>
              </div>
              <div className="formularioCodigo-cuartoC">
                <div className="row">
                  <div className="col-6">
                    <p>Total a pagar por cada cuota</p>
                    {/* <p>Fecha primera cuota</p>
                    <p>Fecha Segunda cuota</p>
                    <p>Fecha tercer cuota</p> */}
                  </div>
                  <div className="col-6">
                    <h1>
                      $
                      {new Intl.NumberFormat("es-MX").format(
                        this.state.totalPagar.toFixed()
                      )}
                    </h1>
                    {/* <h1>Prueba</h1>
                      <h1>Prueba</h1>
                      <h1>Prueba</h1> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="formularioCodigo-cuarto">
              <h1 class Name="claro">
                PASO 4: Confirma tu información
              </h1>
              <p>
                Recuerda que la transferencia se realizará a éste número y
                billetera digital, por favor verifica la información
              </p>
              <p>Selecciona método de transferencia</p>
              <div className="input-group mb-3">
                <select
                  id="bille4"
                  className="form-control"
                  onChange={(event) => this.billestate(event.target)}
                >
                  <option value="Nequi" selected={this.state.bille == "Nequi"}>
                    Nequi
                  </option>
                  <option
                    value="Daviplata"
                    selected={this.state.bille == "Daviplata"}
                  >
                    Daviplata
                  </option>
                </select>
              </div>
              <div className="row">
                <div className="col-2">
                  <div className="input-group mb-3">
                    <select className="form-control-c">
                      <option selected>+57</option>
                      <option value="1"> </option>
                    </select>
                  </div>
                </div>
                {!this.otpValidationResult && (
                  <div className="col-5">
                    <div className="input-group mb-3">
                      <input
                        placeholder={
                          this.numbilleteraDigital
                            ? this.numbilleteraDigital.numbille
                            : this.state.celu
                        }
                        id="numbille"
                        onChange={(event) => this.celustate(event.target)}
                        type="number"
                        className="form-control"
                        aria-label="Text input with dropdown button"
                        pattern="[0-9]+"
                      />
                    </div>
                  </div>
                )}

                {this.otpValidationResult && (
                  <div className="col-5">
                    <div className="input-group mb-3">
                      <input
                        placeholder={
                          this.numbilleteraDigital
                            ? this.numbilleteraDigital.numbille
                            : this.state.celu
                        }
                        id="numbille"
                        onChange={(event) => this.celustate(event.target)}
                        type="number"
                        className="form-control"
                        aria-label="Text input with dropdown button"
                        pattern="[0-9]+"
                        disabled
                      />
                    </div>
                  </div>
                )}

                {!this.otpValidationResult && (
                  <div className="col-5">
                    <div className="input-group mb-3">
                      <button
                        id="botonsms"
                        className="btn sendsms"
                        onClick={(e) => this.SendSMSFirebase(e)}
                      >
                        Verificar Número
                      </button>
                    </div>
                    <div id="recaptcha-container"></div>
                  </div>
                )}

                {this.otpValidationResult && (
                  <div className="col-5">
                    <div className="input-group mb-3">
                      <button
                        id="botonsms"
                        className="btn sendsms"
                        onClick={(e) => this.SendSMSFirebase(e)}
                        disabled
                      >
                        Verificar Número
                      </button>
                    </div>
                    <div id="recaptcha-container"></div>
                  </div>
                )}
              </div>
              {this.state.sendsms && (
                <VerifyOTP confirmationResult={this.state.confirmationResult}>
                  {" "}
                </VerifyOTP>
              )}

              <input
                type="checkbox"
                className=" col-3 check-c"
                id="contrato"
                checked={this.state.check}
                name="contrato"
                onChange={this.terminos}
                href="#"
              />
              <b className="cc">
                Al momento de dar clic en "continuar", he leído, comprendo y
                acepto los
                <b
                  className="c1 cc1"
                  data-bs-toggle="modal"
                  data-bs-target="#Contrato"
                >
                  términos y condiciones
                </b>
              </b>
              <br></br>
              <input
                type="checkbox"
                className=" col-3 check-c"
                id="nomina"
                checked={this.state.check_des}
                name="contrato"
                onChange={this.descuento}
                href="#"
              />
              <b className="cc">
                Autorizo el descuento del préstamo por nómina
              </b>

              <div
                className="modal fade"
                id="Contrato"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title azulS" id="exampleModalLabel">
                        Contrato tu bongo
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body grisS">
                      <div className="formularioCodigo222S">
                        <div className="header-card-politicasS">
                          <h1>CONTRATO DE MUTUO CON INTERESES</h1>
                        </div>
                        <div className="input-group mb-3">
                          <p>
                            La plataforma TU BONGO es propiedad de MONONOVO
                            S.A.S.; sociedad comercial colombiana, legalmente
                            constituida, con domicilio en la ciudad de Bogotá
                            D.C. Colombia, identificada tributariamente con NIT:
                            901.648.093 - 5, la cual presta los servicios a
                            través de su Plataforma. Este documento contiene
                            información legal que le recomendamos leer
                            completamente en conjunto con la Política de
                            Privacidad. Por medio de la aprobación de los
                            presentes Términos y Condiciones se entiende que el
                            Usuario los ha leído y aceptado, en todas sus
                            partes, y entiende que estos le son legalmente
                            vinculantes y obligatorios. Por tanto, acepta las
                            condiciones de utilización y aprovechamiento de la
                            Plataforma, Contenido y Servicios. En caso
                            contrario, el Usuario deberá abstenerse de acceder a
                            la Plataforma y Servicios, ya sea directa o
                            indirectamente, y utilizar cualquier información o
                            servicio provisto por la misma.<br></br>
                            Mediante el acceso y uso de los Servicios, usted
                            acuerda vincularse jurídicamente por estos Términos
                            y Condiciones, que establecen una relación
                            contractual entre el Usuario de la Plataforma y
                            MONONOVO S.A.S. Si usted no acepta dichos Términos y
                            Condiciones, no podrá acceder o usar los Servicios.
                            Estos Términos y Condiciones sustituyen expresamente
                            los acuerdos o compromisos previos con usted.
                            MONONOVO S.A.S. podrá poner fin de inmediato a estos
                            Términos y Condiciones o cualquiera de los Servicios
                            respecto de usted o, en general, dejar de ofrecer o
                            denegar el acceso a los Servicios o cualquier parte
                            de ellos, en cualquier momento y por cualquier
                            motivo.
                            <br></br>
                            MONONOVO S.A.S. podrá modificar los Términos y
                            Condiciones relativos a los Servicios cuando lo
                            considere oportuno. Las modificaciones se harán
                            efectivas después de la publicación por parte de
                            MONONOVO S.A.S. de los Términos y Condiciones
                            actualizados en esta ubicación o las políticas
                            modificadas o condiciones suplementarias sobre el
                            Servicio aplicable. Su acceso o uso continuado de
                            los Servicios después de dicha publicación
                            constituye su consentimiento a vincularse por los
                            Términos y Condiciones y sus modificaciones. La
                            recopilación y el uso que hacemos de la información
                            personal en relación con los Servicios es conforme
                            se dispone en la Política de Privacidad de la
                            plataforma TU BONGO.<br></br>
                            <br></br>
                            1. DEFINICIONES<br></br>
                            Los términos cuya primera letra figura en mayúscula
                            (salvo cuando se deba exclusivamente a que inician
                            una oración o se trate de un nombre propio), tienen
                            el significado que se les asigna a continuación, o
                            el que se les asigne expresamente en estos Términos
                            y Condiciones o en la Política de Privacidad.
                            <br></br>
                            La Plataforma: Página web por medio de la cual las
                            personas podrán solicitar préstamos de bajo monto
                            online de libre destinación y a corto plazo. La
                            plataforma podrá brindar un préstamo y un usuario
                            para identificar los plazos y montos que deberá
                            pagar al momento de su consulta. Del mismo modo,
                            tendrá el detalle de las cuotas de forma tal que se
                            podrá identificar la cantidad de cuotas, el valor y
                            la fecha del primer pago luego de otorgado el
                            préstamo.<br></br>
                            <br></br>
                            Operadora de La Plataforma: Encargado de administrar
                            operativa y funcionalmente la Plataforma,
                            representado para los efectos de los presentes
                            términos por MONONOVO S.A.S., o por la persona
                            natural o jurídica que ésta designe.<br></br>
                            Usuario: Toda persona natural mayor de dieciocho
                            (18) años que, como destinatario final, use La
                            Plataforma con el fin de obtener los servicios de
                            préstamos online a través de la plataforma digital
                            de libre destinación, bajo monto y corto plazo.
                            <br></br>
                            Proveedores: Toda persona natural o jurídica que
                            provea de algún servicio o bien directamente a
                            MONONOVO S.A.S. para el desarrollo de las
                            actividades desarrolladas por la plataforma.
                            <br></br>
                            Navegadores de la plataforma: Todas aquellas
                            personas que sean consideradas como Usuarios,
                            Proveedores, Clientes, Comerciantes y/o
                            colaboradores de contenido.<br></br>
                            Préstamo: Monto de dinero dado en calidad de mutuo
                            mercantil al Usuario, en virtud de una solicitud
                            formalmente realizada por medio de la Plataforma,
                            con la finalidad de ser restituida con posterioridad
                            una vez se cumpla el plazo establecido en la
                            plataforma.<br></br>
                            Tasas e intereses: Réditos que generan los préstamos
                            que han sido otorgados a los Usuarios en virtud de
                            la plataforma, mismos que surgen como consecuencia
                            del otorgamiento de un monto de dinero con finalidad
                            de ser restituida en un periodo de tiempo
                            determinado.<br></br>
                            Garantías: Todas aquellas figuras jurídicas que sean
                            celebradas entre el Usuario y MONONOVO S.A.S. con la
                            finalidad de garantizar el préstamo que ha sido
                            otorgado por medio de la plataforma, mismos que
                            pueden constituirse por medio de títulos valores o
                            cualquier otro documento legal.<br></br>
                            Calificación crediticia: Análisis e investigación
                            realizada por los Proveedores o por MONONOVO S.A.S.
                            directamente para identificar la viabilidad
                            económica de la celebración del presente vinculo
                            jurídico.<br></br>
                            Programa de referidos: Programa ejecutado por
                            MONONOVO S.A.S., por medio de la cual, los Usuarios
                            podrán obtener un beneficio económico promocionado
                            en la comunicación publicitaria que realice MONONOVO
                            S.A.S., una vez el Usuario haya referido otro
                            Usuario efectivo que obtenga un préstamo por parte
                            en la plataforma de TU BONGO.
                            <br></br>
                            Política de Privacidad: Acuerdo celebrado entre
                            MONONOVO S.A.S. y el Usuario con la finalidad de
                            ejecutar las obligaciones que adquieren cada una de
                            las partes, por medio del cual, se establecen las
                            principales directrices por medio de las cuales los
                            datos personales de los Usuarios van a ser tratados;
                            la aceptación de la Política de Privacidad es un
                            requisito sine qua non para la celebración de los
                            Términos y Condiciones.<br></br>
                            <br></br>
                            2. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES<br></br>
                            Mediante la aceptación de los Términos y Condiciones
                            establecidos en esta Plataforma el Usuario acepta
                            las condiciones aquí contenidas, así como la
                            Política de Privacidad de MONONOVO S.A.S.. Así
                            mismo, se entiende que acepta las demás reglas de
                            operación, políticas, procedimientos y demás
                            actualizaciones desde la entrada de operación de la
                            sociedad a la fecha, que puedan ser publicados en la
                            Plataforma, cada uno de los cuales se incorpora por
                            referencia. Sin perjuicio de lo anterior, algunos
                            servicios que se ofrecen en la Plataforma pueden
                            estar sujetos a Términos y Condiciones adicionales,
                            en este caso, el uso que el Usuario haga de dichos
                            servicios estará sujeto a la aceptación específica
                            por parte del Cliente. En caso de que el Usuario no
                            los acepte, deberá abstenerse de utilizar en
                            cualquier forma dichos servicios.<br></br>
                            <br></br>
                            3. CAPACIDAD<br></br>
                            De conformidad con la Ley Colombiana, el Usuario que
                            acepta estos Términos y Condiciones debe ser
                            legalmente apto y contar con las autorizaciones
                            pertinentes para la vinculación de la persona a la
                            que representa. De conformidad con lo anterior, el
                            Usuario garantiza y MONONOVO S.A.S. entiende que el
                            Usuario que acepta los presentes Términos y
                            Condiciones conoce previamente si está o no en
                            capacidad de celebrar contratos a nombre de la
                            persona que se determine como Usuario.<br></br>
                            <br></br>
                            4. ALCANCE DEL SERVICIO<br></br>
                            TU BONGO es una Plataforma tecnológica de soluciones
                            y ayuda financiera, por medio de la cual, los
                            Usuarios podrán solicitar en cualquier momento
                            préstamos online y digitales de libre destinación,
                            bajo monto y a corto plazo. Por medio de la
                            Plataforma los Usuarios podrán acceder a préstamos
                            de hasta un millón de pesos colombianos (COP
                            $1.000.000) sin perjuicio que MONONOVO S.A.S. de forma
                            unilateral y sin previo aviso realice modificaciones
                            en relación a los valores o montos a los que podrán
                            acceder los Usuarios. Para el desarrollo y ejecución
                            de la Plataforma, los Usuarios deberán cumplir con
                            los requisitos que se encuentran establecidos en el
                            presente instrumento jurídico, mismos que el Usuario
                            acepta libre y espontáneamente, entendiendo las
                            consecuencias jurídicas y económicas que pueden
                            producirse con la adquisición de los préstamos
                            otorgados por MONONOVO S.A.S. De esa forma, MONONOVO
                            S.A.S. se presenta como un servicio y producto que
                            otorga soluciones económicas a sus usuarios a través
                            de principios de flexibilidad, rapidez y ausencia de
                            trámites físicos. Así mismo, MONONOVO S.A.S. a
                            travez de la plataforma TU BONGO otorgará los
                            respectivos préstamos a los Usuarios por medio de
                            cuotas o plazos determinados, que serán previamente
                            aceptados por los Usuarios.<br></br>
                            Del mismo modo, MONONOVO S.A.S. podrá solicitar de
                            forma previa o en desarrollo y ejecución del
                            presente acuerdo de voluntades, información personal
                            y sensible para evaluar las posibilidades y
                            factibilidad al momento de otorgar préstamos o para
                            identificar la probabilidad de cumplimiento en el
                            desarrollo de las obligaciones que se encuentran a
                            cargo del Usuario. Las solicitudes de crédito
                            realizadas por medio de la Plataforma podrán ser o
                            no otorgadas a discreción y unilateralmente por
                            parte de MONONOVO S.A.S., no obstante, toda negación
                            por parte de MONONOVO S.A.S deberá estar respaldada
                            por criterios objetivos. La determinación de
                            viabilidad del préstamo se producirá por medio del
                            análisis de la calificación crediticia y la
                            transferencia o transmisión de los datos personales
                            o sensibles de los Usuarios a entidades crediticias
                            certificadas para evaluar la capacidad económica y
                            de endeudamiento de los Usuarios. Sin perjuicio de
                            lo anterior, MONONOVO S.A.S se reserva el derecho de
                            entregar o no información a los Operadores de la
                            Plataforma en relación con la negativa en el
                            otorgamiento de los préstamos, de modo tal que esto
                            no podrá constituir violación alguna de sus
                            obligaciones. Unilateralmente, MONONOVO S.A.S podrá
                            renunciar al derecho de reservarse dicha información
                            siempre y cuando la solicitud de la negativa sea
                            producida por la persona titular de la información o
                            de una autoridad judicial por medio de providencia
                            judicial en firme.<br></br>
                            <br></br>
                            MONONOVO S.A.S realizará el desembolso del préstamo
                            una vez se haya realizado las validaciones y
                            verificaciones para la aprobación definitiva del
                            préstamo. El préstamo se desembolsará en los tiempos
                            permitidos por los ciclos ACH (“Automated Clearing
                            House”), más los tiempos de acreditación del banco
                            receptor en la cuenta bancaria que para tal fin haya
                            determinado el Usuario y que se debe encontrar a su
                            nombre. No podrá realizarse desembolso alguno de
                            dinero a cuentas bancarias que no se encuentren a
                            titularidad del solicitante, o en su defecto, la
                            entrega de recursos sólo y únicamente sin excepción,
                            será al titular de los datos personales presentando
                            su documento de identidad en original en cualquier
                            plataforma de pagos especializada en recaudos, pagos
                            y giros a nivel nacional y la entrega de los
                            recursos se llevará a cabo cuando el Usuario se
                            acerque al punto de servicio o sucursal más cercana
                            de acuerdo a sus horarios de atención, siempre que
                            en la plataforma de TU BONGO se oferte dicho
                            servicio y el convenio se encuentre vigente con el
                            proveedor para la prestación del servicio al momento
                            del otorgamiento del préstamo, previa autorización
                            del DEUDOR. MONONOVO S.A.S podrá solicitar, para la
                            aprobación del respectivo préstamo, la suscripción
                            un pagaré desmaterializado en alguna entidad
                            descentralizada de valores, misma que podrá
                            constituirse como requisito sine qua-non para el
                            perfeccionamiento del presente instrumento jurídico.
                            El incumplimiento de las obligaciones por parte del
                            Usuario podrá hacerse exigible por vía de proceso
                            ejecutivo la totalidad del valor que ha sido
                            otorgado por medio del préstamo, los intereses y la
                            mora que se produzca como consecuencia del no pago
                            de las cuotas o plazos fijados por medio de la
                            Plataforma. Del mismo modo, MONONOVO S.A.S exigirá
                            el pago de la responsabilidad adquirida por el
                            Usuario en los tiempos establecidos a través de la
                            Plataforma o entidades de pago designadas para tal
                            fin, sin perjuicio del pago anticipado de manera
                            total o parcial.<br></br>
                            <br></br>
                            4.1. ALCANCE DEL SERVICIO EN RELACIÓN CON MONONOVO
                            S.A.S
                            <br></br>
                            MONONOVO S.A.S se compromete a prestar una
                            Plataforma que cuente con los mejores estándares de
                            seguridad, así como una plataforma estable en
                            términos de conexión, interconexión y utilidad para
                            los Usuarios. Del mismo modo, MONONOVO S.A.S.
                            celebrará con el Usuario un contrato de mutuo
                            mercantil sobre los valores que sean solicitados por
                            medio de la página. La celebración del contrato se
                            perfeccionará una vez MONONOVO S.A.S. autorice y
                            efectúe la transferencia bancaria a favor del
                            Usuario. Una vez se perfeccione el respectivo
                            contrato, MONONOVO S.A.S. tendrá como obligación
                            desembolsar el dinero al usuario conforme a las
                            condiciones señaladas y aceptadas, quien lo
                            restituirá en la forma y términos especificados o
                            establecidos a través de la Plataforma. MONONOVO
                            S.A.S. deberá realizar y ejecutar el contrato en los
                            términos que se pactaron.<br></br>
                            <br></br>
                            4.2. ALCANCE DEL SERVICIO EN RELACIÓN CON LOS
                            USUARIOS:
                            <br></br>
                            Los Usuarios podrán acceder a la Plataforma en
                            cualquier momento y solicitar préstamos de corta
                            duración y en los términos que se evidencian en el
                            presente instrumento jurídico. En esos términos,
                            para la solicitud del respectivo préstamo, el
                            Usuario debió aceptar el presente instrumento
                            jurídico, así como la Política de Protección y
                            Tratamiento de Datos, misma que autoriza a MONONOVO
                            S.A.S. a utilizar información personal o sensible
                            para evidenciar la viabilidad económica y jurídica
                            del contrato. La relación jurídica del Usuario
                            respecto de MONONOVO S.A.S. empezará desde el
                            momento exacto en el que solicite el préstamo por
                            medio de la plataforma de TU BONGO, momento mismo en
                            el que adquiere la obligación de realizar cualquier
                            actividad bajo el principio de buena fe mercantil,
                            desde el momento precontractual hasta el
                            postcontractual.<br></br>
                            En esos términos, una vez aprobado el préstamo y
                            efectuado el desembolso de la suma de dinero
                            solicitada por medio de la Plataforma, el Usuario se
                            compromete a restituir el dinero dado en mutuo con
                            los intereses que estos hayan generado al momento
                            del pago teniendo en cuenta la información que fue
                            suministrada y aceptada previamente por el Usuario a
                            través de la Plataforma en el momento de la
                            solicitud del crédito. Del mismo modo, el Usuario se
                            compromete a suministrar toda la información que sea
                            necesaria para desarrollar la respectiva evaluación
                            de viabilidad de los respectivos préstamos; toda
                            información suministrada por el Usuario deberá ser
                            veraz y autentica, de forma tal que, el
                            incumplimiento de esta obligación podría generar la
                            exigibilidad de la cláusula penal por parte de
                            MONONOVO S.A.S. Al momento de la ejecución del
                            presente contrato, el Usuario se compromete a
                            suscribir un pagaré, mismo que servirá como garantía
                            del negocio subyacente. La duración del contrato se
                            entenderá celebrada por el término en que se
                            solicite el préstamo y mismo que estará definido en
                            la Plataforma y almacenado en la información que se
                            suministre en el Usuario entregado por MONONOVO
                            S.A.S. al momento de efectuar el registro.
                            <br></br>
                            <br></br>
                            5. DDISPOSICIONES GENERALES A TODOS LOS SERVICIOS
                            OFRECIDOS POR MONONOVO<br></br>
                            <br></br>
                            5.1. SERVICIOS PRESTADOS:<br></br>
                            Por medio de la Plataforma de TU BONGO, MONONOVO
                            S.A.S. otorgará préstamos de corta duración a los
                            Usuarios, mismos que serán efectuados mediante la
                            celebración de un contrato de mutuo mercantil, a
                            través del cual, MONONOVO S.A.S. efectuará la
                            entrega material de consignación de los dineros
                            solicitados en el término que fue previsto en las
                            condiciones pactadas entre las partes, teniendo en
                            cuenta el interés que fue fijado de forma previa,
                            condiciones que han sido aceptadas por el Usuario al
                            momento de solicitar el préstamo. La solicitud del
                            préstamo se entenderá como aceptación completa y sin
                            excepción de los presentes términos y condiciones.
                            El no pago de alguno de los de restitución o cuotas
                            definidas por medio de la Plataforma constituirá
                            incumplimiento de los Términos y Condiciones, dando
                            la posibilidad de hacer exigible la cláusula penal,
                            la solicitud por vía judicial y la exigibilidad de
                            las sumas de dinero por medio del pagaré
                            desmaterializado, mismo que debió haber sido
                            suscrito de forma previa a la solicitud, como
                            requisito para el perfeccionamiento del contrato
                            subyacente. Las condiciones, requisitos y elementos
                            del contrato de mutuo mercantil se reglamentarán por
                            medio de los presentes Términos y Condiciones que
                            podrán ser actualizados por parte de MONONOVO S.A.S.
                            siempre y cuando no vulneren derechos fundamentales
                            del Usuario.<br></br>
                            <br></br>
                            5.2. INSCRIPCIÓN Y REGISTRO<br></br>
                            En relación con el registro de los Usuario estará
                            sujeta a los presentes Términos y Condiciones.
                            MONONOVO S.A.S. se reserva el derecho de afiliación,
                            uso y exclusión de Usuarios en su Plataforma. Este
                            registro se realizará a través de la página web.
                            Para el desarrollo de la inscripción y registro de
                            la Plataforma se podrá solicitar, entre otras, la
                            siguiente información personal la cual va a ser
                            tratada de conformidad con la Política de Protección
                            de Datos Personales:<br></br>
                            Nombre y Apellido.<br></br>
                            Número de teléfono celular.<br></br>
                            Dirección de correo.<br></br>
                            Contraseña.<br></br>
                            Dirección.<br></br>
                            Fotografía del documento de identidad de la persona
                            titular de la cuenta.<br></br>
                            Fotografía personal.<br></br>
                            Datos laborales, empleador, ocupación, teléfono
                            laboral, desprendible de pago o nómina.<br></br>
                            Extractos o certificaciones bancarias.<br></br>
                            Teléfonos de referencias personales.<br></br>
                            Cualquier otro documento o información que sea
                            necesaria para la aprobación del préstamo.<br></br>
                            <br></br>
                            5.3 AUTORIZACIÓN POR DESCUENTO POR NÓMINA.<br></br>
                            Los productos adquiridos serán deducidos bajo la
                            modalidad de descuento de nómina, siempre y cuando
                            el adquirente del servicio, acepte de manera expresa
                            dicho descuento y la empresa empleadora sea aliada
                            al proyecto TU BONGO como extremo contractual de la
                            alianza estratégica suscrita con MONONOVO S.A.S. En
                            ese sentido, se expedirá certificado por descuento
                            de nómina, con el objetivo que el adquirente adose a
                            su empleador la autorización y pueda protocolizar el
                            pago del crédito por aquel mecanismo. Asimismo, es
                            pertinente indicar que, el descuento por nómina
                            opera siempre y cuando el adquirente sea trabajador
                            vigente de la empresa empleadora. Así, en caso de
                            terminación anticipada del término del contrato
                            laboral, la empresa empleadora podrá descontar de la
                            liquidación definitiva el saldo pendiente; pero si,
                            aquel, supera el monto de liquidación, será de
                            obligación propia del adquirente &quot;
                            <br></br>
                            <br></br>
                            5.4 VALIDACIÓN DE LA IDENTIDAD PERSONAL POR
                            BIOMETRÍA
                            <br></br>
                            El Usuario conoce que al tratarse de una plataforma
                            100% online, suministra los datos de su información
                            personal de forma voluntaria y autoriza de este
                            mismo modo la validación de su identidad a través
                            del proceso de biometría que se llevará a cabo por
                            uno de los mejores proveedores a nivel mundial que
                            presten el servicio de validación de identidad
                            biométrica, debidamente contratado por la sociedad y
                            que se encuentre avalado por las respectivas
                            autoridades, contando con todos los protocolos de
                            seguridad de la información, para que ejecute la
                            acción de la verificación de identidad para aquellos
                            solicitantes que cumplan con el perfil crediticio
                            requerido por MONONOVO S.A.S., por tanto el Usuario
                            autoriza la aplicación del sistema de verificación
                            biométrica, en donde se le solicitará imágenes de su
                            documento de identidad por ambos lados y una selfie,
                            realizando ciertos gestos para confirmar que se
                            trata sobre un humano y no sobre una fotografía.
                            Este verificador a su vez, comparará la imagen del
                            documento de identidad con la selfie el cual
                            arrojará un resultado de coincidencia, donde, si el
                            resultado no es coincidente, se rechaza la solicitud
                            por posible suplantación de identidad. De este modo,
                            el Usuario autoriza mediante el análisis biométrico
                            se verifique que su documento de identidad no posea
                            ningún tipo de alteración, que la imagen contentiva
                            en el documento de identidad y los rasgos faciales
                            coincidan en un alto porcentaje de asertividad con
                            la selfie tomada por él mismo, por lo que el Usuario
                            reconoce que de no presentarse el nivel requerido de
                            validación de identidad su solicitud será RECHAZADA
                            de manera automática.<br></br>
                            <br></br>
                            5.5 INSTRUMENTACIÓN DEL CONTRATO A TRAVÉS DE LA
                            FIRMA ELECTRÓNICA<br></br>
                            El Usuario reconoce y autoriza que la
                            instrumentación del contrato de crédito y servicios
                            adicionales, se llevará a cabo a través del
                            mecanismo de firma electrónica aceptando a su vez
                            los términos, condiciones y autorizaciones para la
                            adquisición del préstamo requerido. En consecuencia,
                            la instrumentación del contrato se llevará a cabo en
                            cumplimiento a la legislación colombiana en Ley 527
                            de 1999 Art.7 y el Decreto 2364 de 2012, en
                            concordancia a la Ley 1564 de 2012 Art. 244, en
                            donde establece que los documentos en forma de
                            mensaje datos se presumen auténticos, entendiéndose
                            como firma electrónica, como una clave de validación
                            conformada por códigos, contraseñas, datos
                            biométricos, o claves criptográficas privadas, que
                            permite identificar a una persona, en relación con
                            un mensaje de datos, partiendo desde la
                            autenticidad, confiabilidad y propiabilidad del
                            mismo, en relación al fin para el cual se hace uso
                            este mecanismo de firma, por lo que se presume que
                            los mecanismos o técnicas de identificación personal
                            o de identificación electrónica que fue acordado
                            entre las partes con la aceptación de los términos y
                            condiciones al momento del registro en la plataforma
                            web.<br></br>
                            <br></br>
                            5.6. TIEMPOS DE DESEMBOLSO<br></br>
                            MONONOVO S.A.S. realizará el desembolso del préstamo
                            una vez se haya realizado las validaciones y
                            verificaciones para la aprobación definitiva del
                            préstamo. El préstamo se desembolsará en los tiempos
                            permitidos por los ciclos ACH, más los tiempos de
                            acreditación del banco receptor en la cuenta
                            bancaria que para tal fin haya determinado el
                            Usuario y que se debe encontrar a su nombre. El
                            desembolso puede demorar entre 24 y 48 horas hábiles
                            aproximadamente: las solicitudes iniciadas el fin de
                            semana se gestionan a partir del primer día hábil.
                            Si la cuenta bancaria no es válida, el banco nos
                            avisará dentro de las 72 horas. No podrá realizarse
                            desembolso alguno de dinero a cuentas bancarias que
                            no se encuentren a nombre del titular que
                            efectivamente solicite el préstamo respectivo. O en
                            su defecto, el desembolso se llevará a cabo a
                            través, de cualquier plataforma de pagos
                            especializada en recaudos, pagos y giros a nivel
                            nacional y la entrega de los recursos se dará cuando
                            el Usuario se acerque al punto de servicio o
                            sucursal más cercana de acuerdo a sus horarios de
                            atención, siempre que MONONOVO S.A.S. oferte dicho
                            servicio y el convenio se encuentre vigente con el
                            proveedor para la prestación del servicio al momento
                            del otorgamiento del préstamo, previa autorización
                            del DEUDOR. <br></br>
                            <br></br>
                            5.7. CONDICIONES TRANSPARENTES<br></br>
                            <br></br>
                            PRÉSTAMO DESEMBOLSADO: Monto de dinero aprobado.
                            <br></br>
                            INTERÉS REMUNERATORIO (TEA): La tasa de interés
                            efectiva anual legalmente constituida.<br></br>
                            EMISIÓN DE PAGARÉ: Emisión del título de crédito
                            digital en el que el emisor se compromete a pagar
                            una determinada suma de dinero en una fecha
                            establecida, su firma digital y custodia en entidad
                            especializada de administración de títulos valores.
                            <br></br>
                            VERIFICACIÓN Y PROTECCIÓN PERSONAL: Requisito “Sine
                            qua non” para la celebración del contrato donde se
                            gestiona el cobro de las herramientas utilizadas
                            para la protección, administración y tratamiento de
                            la información personal suministrada por los
                            usuarios. Este es un cargo asociado por pago a
                            terceros, EL DEUDOR se exceptúa del cobro de este
                            servicio, de acuerdo a la clasificación de riesgo
                            definida por MONONOVO S.A.S.<br></br>
                            IDENTIFICACIÓN BIOMÉTRICA: Uso de plataforma de
                            validación de identidad en tiempo real que permite a
                            través del uso de tecnología de identificación
                            biométrica proteger, validar y determinar la
                            legitimidad de las personas que realizan las
                            solicitudes de préstamo.<br></br>
                            ADMINISTRACIÓN: Valor correspondiente a la cuota de
                            disponibilidad del cupo de crédito rotativo
                            utilizable, beneficio a favor del cliente en función
                            de cuota de manejo que garantiza el valor del monto
                            mientras se tenga uno o más desembolsos activos.
                            <br></br>
                            RECUADO: Habilitación de múltiples mecanismos que
                            facilitan, diversifican y acercan distintas
                            posibilidades de pago para el cliente.<br></br>
                            CENTRALES DE RIESGO: Consulta ante centrales de
                            riesgo crediticio de la información del usuario que
                            permite generar un estudio a partir del análisis
                            fragmentado de las variables que componen su score y
                            sobre el cual se determinara la concesión del
                            préstamo. EL DEUDOR se exceptúa del cobro de este
                            servicio, de acuerdo a la clasificación de riesgo
                            definida por TU BONGO.<br></br>
                            OPERACIÓN ACH OTROS BANCOS: Asociado a los costos
                            transaccionales derivados del método de pago usado
                            por TU BONGO y/o EL DEUDOR, tanto en proceso de
                            desembolso del préstamo y pago de deuda.<br></br>
                            VERIFICACIÓN TELEFÓNICA Y REFERENCIACIÓN: Validación
                            telefónica a los números registrados por EL DEUDOR y
                            comprobación de las referencias familiares y
                            comerciales relacionadas.<br></br>
                            VERIFICACIÓN ANTIFRAUDE: Envió a través de mail, SMS
                            y otros, de códigos únicos de validación de
                            identidad que permitan al usuario tener mayores
                            herramientas de seguridad al momento de realizar la
                            solicitud del préstamo.<br></br>
                            <br></br>
                            5.8. AUTORIZACIÓN DE DÉBITO AUTOMÁTICO<br></br>
                            El Usuario otorga mediante la aceptación de los
                            términos y condiciones al momento de continuar con
                            el registro en el formulario web otorga de manera
                            inequívoca a MONONOVO S.A.S. autorización para que,
                            a la orden de ésta, se cursen los débitos en su
                            cuenta bancaria y demás cuentas bancarias
                            registradas en plataforma, con el fin de aplicarlos
                            a los servicios otorgados. Estos débitos se
                            aplicarán por el tiempo que se encuentre vigente la
                            obligación, a partir del vencimiento estipulado, por
                            sistema de débito automático, adhiriéndose al
                            sistema de débito directo o indirecto normados,
                            sobre los cuales, desde la aceptación de los
                            presentes términos y condiciones, autorizando a
                            MONONOVO S.A.S. debitar de cualesquiera de las
                            cuentas bajo su titularidad los montos y cargos
                            relacionados con los compromisos asumidos. Cada una
                            de las cuotas serán debitadas de manera consecutiva
                            por el tiempo que MONONOVO S.A.S. lo indique, por
                            los montos correspondientes al pago de los
                            compromisos asumidos, más los intereses causados en
                            caso de mora, atraso o pago parcial que
                            correspondieren, según indique MONONOVO S.A.S. La
                            presente autorización es permanente e irrevocable,
                            mientras subsista la obligación referenciada
                            precedentemente.<br></br>
                            <br></br>
                            5.9. DESISTIMIENTO<br></br>
                            El DEUDOR, en ejercicio de su derecho al retracto
                            podrá solicitar el desistimiento del préstamo
                            requerido, hasta UN (01) día después del desembolso
                            del préstamo, por lo que EL DEUDOR deberá realizar
                            la devolución de los recursos desembolsados
                            asumiendo a su vez los costos de adquisición del
                            servicio, emisión de pagaré, verificación y
                            protección personal, antifraude, uso de la
                            plataforma online, administración, verificación
                            antifraude y biometría.<br></br>
                            5.10. VIOLACIÓN DEL SISTEMA O BASES DE DATOS
                            <br></br>
                            Con la utilización de la Plataforma, los Usuarios
                            aceptan que se encuentra prohibido y no está
                            permitida ninguna acción o uso de dispositivo,
                            software o cualquier otro medio tendiente a
                            interferir tanto en las actividades y operativa de
                            MONONOVO S.A.S. como en las ofertas, descripciones,
                            cuentas o bases de datos de MONONOVO S.A.S.
                            Cualquier intromisión, tentativa o actividad
                            violatoria o contraria a las leyes sobre derecho de
                            propiedad intelectual y/o a las prohibiciones
                            estipuladas en el contrato, harán posible a su
                            responsable de las acciones legales pertinentes, y a
                            las sanciones previstas por este acuerdo, así como
                            responsable de indemnizar los daños ocasionados.
                            <br></br>
                            <br></br>
                            5.11. SANCIONES POR INCUMPLIMIENTO DE LOS TÉRMINOS Y
                            CONDICIONES<br></br>
                            MONONOVO S.A.S. podrá efectuar sanciones a los
                            Usuarios de la Plataforma por el incumplimiento de
                            las obligaciones consagradas en los Términos y
                            Condiciones o por el incumplimiento de las
                            obligaciones de ley en cualquier momento en
                            desarrollo de las relaciones contractuales entre las
                            Partes. Las sanciones que podrán ser efectuadas por
                            parte de MONONOVO S.A.S. son: sanciones que impacten
                            negativamente la reputación de la Marca o Local.
                            MONONOVO S.A.S. se reserva el derecho de establecer
                            cuál debe ser la sanción respectiva por el
                            incumplimiento de las obligaciones de los términos y
                            condiciones. Para establecer la sanción respectiva
                            por el incumplimiento de las obligaciones, MONONOVO
                            S.A.S. calificará el hecho en leve, grave o
                            gravísimo dependiendo de los efectos que haya
                            generado el hecho u acto del Usuario. Las Sanciones
                            realizadas por MONONOVO S.A.S. se realizarán
                            siguiendo los siguientes parámetros, a saber:
                            <br></br>
                            Se realicen actos que vulneren disposiciones
                            normativas del ordenamiento jurídico colombiano, o
                            de cualquiera de las estipulaciones que se
                            encuentran consagradas en los Términos y
                            Condiciones. Se realicen actos que se encuentren en
                            contravía de la buena fe comerciales y de las sanas
                            costumbres mercantiles.<br></br>
                            Se realicen actos que generen perjuicios para otros
                            Usuarios por medio de conductas dolosas o
                            fraudulentas.
                            <br></br>
                            Si luego de realizar el registro o inscripción,
                            MONONOVO S.A.S. no pueda corroborar la identidad del
                            Usuario o cualquier información suministrada por
                            éste sea errónea o falsa Cuando el Usuario publique
                            contenido falso, no veraz o acorde con la realidad
                            por medio del comercio social.
                            <br></br>
                            Cuando TU BONGO entendiera que las publicaciones u
                            otras acciones puedan ser causa de responsabilidad
                            para el Usuario que las publicó, para MONONOVO
                            S.A.S. o para los Usuarios en general.<br></br>
                            Cualquier otro acto que vaya en detrimento de
                            derechos o intereses de Usuarios o terceras
                            personas.<br></br>
                            En caso de que un Usuario sea objeto de sanciones
                            por los comportamientos señalados con anterioridad
                            por parte de MONONOVO S.A.S., todas las solicitudes
                            que haya realizado, así como cualquier contenido
                            generado por el Usuario podrán ser eliminados de
                            forma discrecional por parte de MONONOVO S.A.S. y el
                            Usuario no podrá realizar reclamaciones o solicitar
                            indemnizaciones al aceptar que su conducta no se
                            encuentra admitida en la Plataforma.
                            <br></br>
                            <br></br>
                            5.12. FALLAS EN EL SISTEMA<br></br>
                            MONONOVO S.A.S. no se hace responsable por cualquier
                            daño, perjuicio o pérdida a los Usuarios de la
                            plataforma que sean causados por fallas en el
                            sistema, en los servidores o en el Internet.
                            MONONOVO S.A.S. tampoco se considerará como
                            responsable por cualquier virus que pudiera infectar
                            el equipo de los Usuarios como consecuencia del
                            acceso, uso o examen o a raíz de cualquier
                            transferencia de datos, archivos, imágenes, textos o
                            audio contenidos en la Plataforma. De esa forma, los
                            Usuarios no podrán imputar responsabilidad a
                            MONONOVO S.A.S. o solicitar el reconocimiento de
                            perjuicios resultantes de dificultades técnicas o
                            fallas en los sistemas o en internet.<br></br>
                            <br></br>
                            5.13. REQUISITOS PARA REALIZAR EL REGISTRO EN LA
                            PLATAFORMA<br></br>
                            Los Usuarios de la Plataforma deberán contar, cuanto
                            menos, con los siguientes requisitos, a saber:
                            <br></br>
                            Ser mayor de 18 años.<br></br>
                            Ser residente en la República de Colombia.<br></br>
                            Tener domicilio y residir en la República de
                            Colombia.
                            <br></br>
                            Tener capacidad legal para contratar.<br></br>
                            Contar con un documento nacional de identidad
                            válidamente emitido por la República de Colombia y
                            en condiciones de ser leído electrónicamente.
                            <br></br>
                            Teléfono celular propio de legitima tenencia y uso
                            <br></br>
                            Cuenta de correo electrónico propia y de uso
                            personal.
                            <br></br>
                            Ser titular de una cuenta corriente o de ahorros o
                            cuenta digital en una entidad financiera supervisada
                            por el Banco de la República de Colombia.<br></br>
                            <br></br>
                            5.14. EXONERACIÓN DE RESPONSABILIDAD POR ATAQUES
                            INFORMÁTICOS<br></br>
                            MONONOVO S.A.S. no se hará responsable por ataques
                            informáticos exógenos de la debida diligencia en
                            desarrollo de sus deberes de protección a ataques
                            informáticos.<br></br>
                            <br></br>
                            5.15. MEJORAMIENTO CONTINUO O MANTENIMIENTO DE LA
                            PLATAFORMA<br></br>
                            MONONOVO S.A.S. manifiesta que en el desarrollo de
                            sus actividades de mejoramiento o mantenimiento de
                            la Plataforma podrá interrumpir el acceso a los
                            Usuarios. En esos términos, la Plataforma podrá no
                            estar disponible, en tal caso se procurará
                            restablecerla con la mayor celeridad posible sin que
                            por ello pueda imputársele algún tipo de
                            responsabilidad.<br></br>
                            <br></br>
                            5.16. ADQUISICIÓN DE SEGURO PARA LA SOLICITUD DEL
                            PRÉSTAMO
                            <br></br>
                            El Usuario manifiesta con la aceptación de los
                            Términos y Condiciones que, para el
                            perfeccionamiento de la relación contractual,
                            solicitará a MONONOVO S.A.S. que en su nombre
                            adquiera un seguro contra riesgos en caso de muerte
                            y otros siniestros probables que puedan repercutir
                            en el impago de la deuda para que, en caso de
                            producirse alguno de los siniestros mencionados en
                            la presente cláusula, se garantice el cumplimiento
                            de la totalidad de la deuda adquirida, incluidos los
                            cobros por concepto de cobranza, intereses e IVA en
                            la prestación del servicio. La solicitud de la
                            adquisición del seguro se realizará de forma
                            automática con la aceptación de los Términos y
                            Condiciones, de forma tal que, al efectuarse la
                            aceptación de este clausulado, MONONOVO S.A.S.
                            entenderá que se encuentra obligado a adquirir el
                            respectivo seguro en favor del Usuario para el pago
                            de la deuda que solicitó en caso de la ocurrencia de
                            los respectivos siniestros. El Usuario manifiesta
                            que la solicitud para la adquisición del seguro se
                            efectúa con la finalidad de prever una garantía de
                            su obligación en pro de los intereses de sus
                            familiares y de su propio patrimonio.<br></br>
                            <br></br>
                            5.17. COSTOS POR CUENTA Y ORDEN DE TERCEROS EN LOS
                            PRÉSTAMOS<br></br>
                            MONONOVO S.A.S. podrá realizar a los Usuarios cobros
                            por cuenta y orden de terceros por diferentes
                            conceptos derivados de la identificación, seguridad,
                            validación, verificaciones, fondo de
                            garantías/avalista, análisis crediticios, y gastos
                            de recaudos en los préstamos que realiza, de ese
                            modo, el Usuario conoce y acepta que, al momento de
                            solicitar un préstamo de capital, MONONOVO S.A.S.
                            podría incorporar los gastos anteriormente
                            descritos. El valor de todos los gastos por cuenta y
                            orden de terceros se verán reflejados en la
                            Plataforma al momento de solicitar el préstamo, por
                            lo que el Usuario conocerá el valor de los gastos
                            con anterioridad a la solicitud del préstamo; en
                            esos términos, los gastos garantizan al Usuario una
                            estructura tecnología que permita la solicitud de
                            préstamos en forma online e inmediata desde la
                            comodidad no presencial del usuario. Los gastos
                            brindan al usuario las condiciones de seguridad
                            informática, seguridad biométrica, análisis de los
                            bureaus de crédito en milisegundos para una
                            efectividad óptima y necesidad urgente del usuario.
                            <br></br>
                            <br></br>
                            5.18. GASTOS DE COBRANZA EN LOS PRÉSTAMOS<br></br>
                            MONONOVO S.A.S. realizará a los Usuarios un cobro
                            por concepto de cobranza en los casos en los que EL
                            DEUDOR se encuentre en mora respecto de sus
                            obligaciones de pago en virtud del contrato
                            celebrado por medio de los presentes Términos y
                            Condiciones. Los gastos de cobranza equivalen a los
                            porcentajes enunciados en el presente documento,
                            mismos que se calculan en relación con el total de
                            la deuda y el incremento periódico de intereses de
                            acuerdo con los días de mora de la cartera. Los
                            gastos de cobranza son cobros que se realizan al
                            DEUDOR correspondientes a conceptos como: personal
                            de cobranza especializado, gastos de mensajerías
                            (SMS), envío de mensajes de voz (IVR), llamados y
                            mensajes de whatsapp a los teléfonos celulares
                            registrados ante MONONOVO S.A.S. con la finalidad de
                            obtener el pago total de la obligación; que no han
                            sido cancelados por parte del DEUDOR en las fechas
                            fijadas y convenidas de forma previa en el contrato.
                            El Usuario al aceptar los presentes Términos y
                            Condiciones autoriza incondicionalmente a MONONOVO
                            S.A.S. para efectuar el cobro por gastos de
                            cobranza, así como la realización de todas las
                            actividades que resulten necesarias para garantizar
                            el cobro de la deuda. Los gastos de cobranza se
                            realizarán de conformidad con la siguiente formula y
                            porcentajes:
                            <br></br>
                            Gastos de cobranza<br></br>
                            Saldo capital * porcentaje de Mora + IVA de este
                            resultado
                            <br></br>
                            Días de mora<br></br>
                            Porcentaje de mora<br></br>
                            20 a 29 días<br></br>
                            30 días a 39 días<br></br>
                            40 días a 59 días<br></br>
                            Mayor a 60 días <br></br>
                            <br></br>
                            <br></br>
                            5.19. VINCULATORIEDAD DE LOS MONTOS SOLICITADOS POR
                            MEDIO DE LA PLATAFORMA<br></br>
                            El Usuario manifiesta que, una vez solicitado un
                            préstamo de capital por medio de la Plataforma, los
                            montos discriminados, número de cuotas y el
                            vencimiento de cada una de estas serán vinculantes
                            para la relación contractual. En esos términos, se
                            entenderá que, al realizar la solicitud, ingresar
                            los datos personales del Usuario, aportar los
                            documentos solicitados y validar su identidad
                            personal, la información suministrada será de
                            obligatorio cumplimiento y corresponde a los
                            elementos esenciales de la relación contractual,
                            asimismo, la aceptación y la información entregada
                            será información con valor probatorio en la
                            República de Colombia o en el extranjero, de
                            conformidad con la Ley 527 de 1999.
                            <br></br>
                            <br></br>
                            5.20. VINCULATORIEDAD DE LOS MONTOS SOLICITADOS POR
                            MEDIO DE LA PLATAFORMA<br></br>
                            EL DEUDOR acepta y manifiesta que, al solicitar y al
                            ser aprobado un préstamo por medio de la Plataforma,
                            estará obligado a cancelar el monto solicitado,
                            intereses y cualquier otro monto dinerario adeudado
                            a MONONOVO S.A.S. por medio de un pagaré, mismo que
                            estará en blanco y deberá diligenciarse única y
                            exclusivamente de conformidad con la carta de
                            instrucciones del respectivo título valor.
                            <br></br>
                            <br></br>
                            5.21. AUTORIZACIÓN DE DIFUSIÓN DE DATOS A TERCEROS,
                            CONSULTA, ADMINISTRACIÓN Y REPORTE A CENTRALES DE
                            RIESGO, EMPRESAS DE VALIDACIÓN DE IDENTIDAD,
                            BIOMETRÍA Y ANÁLISIS DE DATOS.<br></br>
                            En calidad de Titular de la información, actuando
                            libre y voluntariamente, autorizo a MONONOVO S.A.S.
                            y/o a Experian Colombia S.A. (en adelante
                            DataCrédito Experian), Carrera 7 No. 76-35 Tel
                            3191400 de Bogotá D.C., o quien represente sus
                            derechos, a acceder a mis datos personales
                            contenidos en la base de datos de MONONOVO S.A.S.,
                            Aportes en Línea (Aportes En Línea S.A.
                            900147238-2), Colfondos y/o administradoras de
                            pensiones, y demás Operadores de información de
                            seguridad social autorizados por el Ministerio de
                            Salud y Protección Social, a mis datos personales
                            recolectados por medio del presente formulario, y a
                            mis datos personales contenidos en la base de datos
                            de DataCrédito Experian (Operador de información
                            crediticia), en adelante mi información personal,
                            para darle tratamiento en los términos expresados en
                            la Política de Tratamiento de la Información
                            Personal de MONONOVO S.A.S. y/o DataCrédito Experian
                            y para finalidades de gestión de riesgo crediticio
                            tales como: (i) elaboración y circulación a terceros
                            de scores crediticios, herramientas de validación de
                            ingresos, herramientas predictivas de ingresos,
                            herramientas para evitar el fraude y en general,
                            herramientas que le permitan a los Suscriptores de
                            DataCrédito Experian, adelantar una adecuada gestión
                            del riesgo crediticio. (ii) Compararla, contrastarla
                            y complementarla con la información financiera,
                            comercial, crediticio, de servicios y proveniente de
                            terceros países de DataCrédito Experian. (iii)
                            Compararla, contrastarla y complementarla con la
                            información personal de Midatacrédito.<br></br>
                            Así mismo, el Usuario acepta y manifiesta que, al
                            solicitar un préstamo autoriza de manera expresa,
                            concreta, suficiente, voluntaria, informada,
                            irrevocable e ilimitada a MONONOVO S.A.S. o a través
                            de terceros, o a quien represente sus derechos o a
                            quien ostente en el futuro la calidad de acreedor,
                            en virtud de las obligaciones derivadas de sus
                            relaciones contractuales para: (i) consultar, (ii)
                            reportar, (iii) enviar, (iv) procesar, (v) solicitar
                            y (vi) divulgar a las Centrales de Información
                            Financiera y crediticia, a los bureaus de créditos,
                            y a cualquier entidad que maneje o administre bases
                            de datos que tengan como fines de información
                            financiera, crediticia, de control, validación de
                            identidad, biometría, comerciales y de consolidación
                            de información, todos los datos personales y
                            económicos, incluyendo la información referente al
                            comportamiento comercial y crediticio del Usuario,
                            tales como el nacimiento, modificación o extinción
                            de obligaciones contraídas con anterioridad o que se
                            llegasen a contraer fruto de la relación contractual
                            contraída. Asimismo, acepta y autoriza a MONONOVO
                            S.A.S. al envío de las fotos de las cédulas de
                            identidad y foto “selfie” personal, grabación de
                            video y audio del dispositivo celular a proveedores
                            terceros de verificación de identidad y de firma
                            digital y electrónica. De igual forma, autoriza a
                            MONONOVO S.A.S. al envío de mensajes de texto,
                            whatsapps, IVR (mensajes de voz) para las
                            validaciones y para el cobro de las obligaciones
                            inherentes al préstamo. Por último, El usuario
                            autoriza a MONONOVO S.A.S. al contacto de sus
                            referencias laborales y personales por estos medios
                            de contacto. Todo lo anterior implica que, el
                            cumplimiento o incumplimiento de las obligaciones
                            del Usuario, pueden reflejarse en las mencionadas
                            bases de datos.<br></br>
                            <br></br>
                            6. CONSULTA DE LA PLANILLA PILA<br></br>
                            Los Usuarios de la Plataforma aceptan que MONONOVO
                            S.A.S. realice consultas de la planilla “Pila”, de
                            forma tal que, con la aceptación de los presentes
                            Términos y Condiciones, MONONOVO S.A.S. tiene
                            capacidad de solicitar a los Operadores de
                            Información del Pila, y a éstos, para que le
                            suministren a MONONOVO S.A.S. por el medio que
                            considere, de tal forma que, todos los datos
                            personales de los Usuarios relacionados con la
                            afiliación y pago de los aportes al Sistema de
                            Seguridad Social Integral, tales como ingreso base
                            de cotización y demás información relacionada con mi
                            situación laboral y empleador. MONONOVO S.A.S. podrá
                            conocer dicha información cuantas veces lo requiera,
                            mantenerla actualizada y en general tratarla,
                            directamente o a través de un encargado, con la
                            finalidad de analizar mi perfil crediticio en aras
                            de establecer una relación comercial y/o de
                            servicios con el Usuario, así como también para
                            ofrecerle productos o servicios que se adecuen al
                            perfil crediticio del Usuario. En todo caso, el
                            Usuario declara expresamente conocer el carácter
                            facultativo de la autorización, los derechos que le
                            asisten como titular de la información y entender
                            que el uso y manejo que se dará a los datos
                            personales se efectuará de forma responsable y
                            respetando las normas y principios generales
                            establecidos en la Ley 1581 de 2012 y sus decretos
                            reglamentarios.
                            <br></br>
                            <br></br>
                            7. OBLIGACIONES DE MONONOVO S.A.S.<br></br>
                            MONONOVO S.A.S. prestará los servicios contratados
                            con plena autonomía profesional e independencia,
                            comprometiéndose a cumplir con las siguientes
                            obligaciones, sin perjuicio de las obligaciones
                            especiales que se encuentren estipuladas en estos
                            Términos y Condiciones:<br></br>
                            Prestar los servicios que se encuentran plasmados en
                            los Términos y Condiciones en las mejores
                            condiciones de calidad, salvo la existencia de
                            defectos informáticos, ataques informáticos,
                            mantenimiento para el mejoramiento del servicio,
                            fuerza mayor o casos fortuitos.<br></br>
                            Brindar la información suficiente para que los
                            Usuarios puedan ejercer sus derechos que se
                            encuentran plasmados en los Términos y Condiciones
                            de manera oportuna.<br></br>
                            Brindar a los Usuarios las mejores condiciones de
                            seguridad para el uso de los servicios que se
                            encuentran suministrados por medio de la Plataforma.
                            <br></br>
                            Realizar el mantenimiento de la Plataforma de forma
                            tal que los servicios que se presten se produzcan en
                            las mejores condiciones.<br></br>
                            Efectuar la entrega de las sumas de dinero que
                            fueron solicitadas por los Usuarios previa
                            observancia de la viabilidad del préstamo.<br></br>
                            Actuar de buena fe en el desarrollo de las
                            obligaciones que se encuentran plasmadas en este
                            acápite o a lo largo de los Términos y Condiciones.
                            <br></br>
                            Brindar asesoría a los Usuarios por defectos que se
                            encuentren en la Plataforma.<br></br>
                            Notificar a los Usuarios las modificaciones que se
                            realicen respecto de los Términos y Condiciones o
                            respecto de los porcentajes de comisión en la
                            sección de comisiones dentro de la plataforma.
                            <br></br>
                            Desarrollar sus actividades sin abusar de los
                            derechos que posee.<br></br>
                            Los demás que se encuentren expresamente consagrados
                            en los Términos y Condiciones y en las disposiciones
                            legales y constitucionales.<br></br>
                            <br></br>
                            8. OBLIGACIONES DE LOS USUARIOS<br></br>
                            Los Usuarios de la Plataforma se comprometen, de
                            conformidad con las obligaciones propias del
                            contrato de mutuo mercantil, a desarrollar y
                            ejecutar, entre otras, las siguientes obligaciones:
                            <br></br>
                            Utilizar la Plataforma para las finalidades que se
                            encuentran señaladas en los Términos y Condiciones.
                            <br></br>
                            Suministrar información veraz, auténtica e idónea
                            para la realización del registro e inscripción en la
                            Plataforma.
                            <br></br>
                            No suplantar identidad.<br></br>
                            El uso de un teléfono celular propio, personal y que
                            autorice a recibir todo tipo de comunicaciones.
                            <br></br>
                            El uso de la cuenta de correo electrónico propio,
                            personal y autorice a recibir todo tipo de
                            comunicaciones.<br></br>
                            Resguardar las credenciales de acceso para mantener
                            la confidencialidad que serán de entera
                            responsabilidad del usuario.<br></br>
                            No utilizar credenciales de terceros.<br></br>
                            El usuario es responsable de todas las operaciones
                            realizadas con su cuenta.<br></br>
                            El usuario es responsable de mantener actualizado el
                            teléfono celular, la cuenta de correo electrónico en
                            la plataforma, y es su obligación notificar los
                            cambios de esta información mientras el préstamo
                            esté en vigencia.
                            <br></br>
                            Suscribir el respectivo pagaré que de respaldo al
                            negocio subyacente.<br></br>
                            Efectuar el pago de las sumas de dinero que fueron
                            solicitadas y aprobadas por medio de la Plataforma
                            con los intereses y demás cargos que sean convenidos
                            de conformidad con las condiciones señaladas en los
                            Términos y Condiciones.<br></br>
                            Utilizar la Plataforma bajo los supuestos señalados
                            en la Ley y la jurisprudencia en relación con la
                            buena fe mercantil, de tal forma que no sea
                            utilizada para realizar fraude a los preceptos
                            consagrados en los Términos y Condiciones o a los
                            preceptos consagrados en el ordenamiento jurídico
                            colombiano.<br></br>
                            Responsabilizarse de las obligaciones que establezca
                            la ley en relación con la celebración de contratos
                            de mutuo mercantil, en los términos de los artículos
                            1163 del Código de Comercio y disposiciones conexas
                            o concordantes.
                            <br></br>
                            <br></br>
                            9. DERECHOS DE MONONOVO S.A.S<br></br>
                            En virtud de la prestación de las actividades aquí
                            referenciadas MONONOVO S.A.S. adquiere,
                            especialmente, los siguientes derechos:<br></br>
                            Derecho a no garantizar el acceso y uso continuado e
                            ininterrumpido de la Plataforma.<br></br>
                            Derecho a realizar modificaciones a los Términos y
                            Condiciones de la Plataforma.<br></br>
                            Derecho a no hacerse responsable respecto de daños,
                            perjuicios o pérdida al Usuario ocasionado por
                            fallas en la Plataforma, servidores, Internet o
                            respecto de cualquier inconveniente informático
                            ajeno.<br></br>
                            Derecho a no hacerse responsable por cualquier virus
                            que pudiera infectar al Usuario como consecuencia
                            del acceso, uso o examen del aplicativo o a raíz de
                            cualquier transferencia de datos, archivos,
                            imágenes, textos o audio contenidos en el mismo.
                            <br></br>
                            Se reserva el derecho de aceptar o negar las
                            solicitudes de préstamos efectuados por los
                            Usuarios.<br></br>
                            Derecho a guardar la información de los Usuarios
                            inscritos o registrados en la Plataforma.<br></br>
                            Se reserva el derecho de cobrar o exigir
                            judicialmente las sumas de dinero dadas en el
                            contrato mutuo mercantil, los intereses corrientes y
                            moratorios y demás gastos en los que incurra
                            MONONOVO S.A.S. por la necesidad de hacer exigibles
                            las obligaciones por vía judicial.
                            <br></br>
                            10. DERECHOS DE LOS USUARIOS<br></br>
                            En virtud de la prestación de las actividades aquí
                            referenciadas EL USUARIO adquiere, especialmente,
                            los siguientes derechos:<br></br>
                            Se reserva el derecho de realizar quejas, reclamos,
                            sugerencias o peticiones por los mecanismos
                            consagrados en los Términos y Condiciones.<br></br>
                            Se reserva el derecho de exigir a MONONOVO S.A.S.
                            las razones por las cuales fue negado un préstamo
                            solicitado, bajo la salvedad de que estas podrán ser
                            dadas o no por parte de MONONOVO S.A.S.<br></br>
                            Se reserva el derecho de solicitar el retiro y la
                            exclusión de sus datos e información confidencial o
                            sensible de las bases de datos de MONONOVO S.A.S.
                            <br></br>
                            Se reserva el derecho de ejercer cualesquiera
                            derechos que se encuentren inmersos en los Términos
                            y Condiciones o que sean conexos o complementarios,
                            así como cualquiera de los derechos que contemple la
                            Ley, la Constitución Política y la jurisprudencia.
                            <br></br>
                            <br></br>
                            11. MEDIOS DE CONTACTO DEL USUARIO CON MONONOVO
                            <br></br>
                            El Usuario podrá contactarse a través de los canales
                            de atención debidamente autorizados por la sociedad
                            MONONOVO S.A.S. y que se encuentran publicados en la
                            plataforma, tales como:<br></br>
                            Recaudos: escribir al whatsapp 314 3764313 en Bogotá
                            D.C.
                            <br></br>
                            Chat de WhatsApp: 314 3764313 habilitado en la
                            página web www.______.co.<br></br>
                            Radicación de PQR´S: Botón de “Atención al cliente”,
                            botón” Línea de Transparencia”, botón “Fallas
                            técnicas” ubicados en la parte inferior derecha de
                            la página web.
                            <br></br>
                            <br></br>
                            12. TERMINACIÓN<br></br>
                            La relación de las Partes a la que se refiere los
                            presentes Términos y Condiciones podrá darse por
                            terminado por las causales que determina la ley y en
                            especial por las siguientes:<br></br>
                            Por el mutuo acuerdo entre las Partes;<br></br>
                            Por el incumplimiento de una de las Partes de las
                            obligaciones nacidas en virtud de estos Términos y
                            Condiciones, lo que facultará a la otra para darlo
                            por terminado, sin perjuicio de las acciones legales
                            pertinentes, y de lo establecido en los numerales
                            siguientes;<br></br>
                            Por cumplimiento de todas las obligaciones
                            estipuladas en los Términos y Condiciones;<br></br>
                            En caso de que alguna de las Partes sea vinculada
                            por las autoridades competentes a cualquier tipo de
                            Investigación por delitos de narcotráfico,
                            terrorismo, secuestro, lavado de activos,
                            financiación del terrorismo y administración de
                            recursos relacionados con dichas actividades, sin
                            que se limite a éstas;<br></br>
                            En caso de que alguna de las Partes sea incluida en
                            listas para el control de lavado de activos y
                            financiación del terrorismo administradas por
                            cualquier autoridad nacional o extranjera, tales
                            como la lista de la Oficina de Control de Activos en
                            el Exterior OFAC emitida por la Oficina del Tesoro
                            de los Estados Unidos de Norte América, la lista de
                            la Organización de las Naciones Unidas y otras
                            listas públicas relacionadas con el tema del lavado
                            de activos y financiación del terrorismo;<br></br>
                            En caso de que alguna de las Partes, suministre o
                            haya suministrado información falsa o incompleta o
                            negarse a actualizar la información que se requiera
                            para la relación contractual cuando la ley así lo
                            requiera.<br></br>
                            <br></br>
                            13. AUTONOMÍA E INDEPENDENCIA<br></br>
                            sus empleados y colaboradores, actuarán por su
                            propia cuenta, con absoluta autonomía y no estarán
                            sometidos a subordinación por parte de los Usuarios.
                            Sus derechos se limitarán de acuerdo con la
                            naturaleza de la relación entre las Partes a exigir
                            el cumplimiento de las obligaciones de los Usuarios
                            y al pago del valor estipulado en la plataforma o
                            cualquier otro costo que se estipule por medio de
                            los Términos y Condiciones, o para el cumplimiento
                            de las finalidades de las relaciones jurídicas aquí
                            plasmadas. Las personas que participen en la
                            ejecución de la relación entre las Partes son
                            empleados y/ o contratistas exclusivamente de
                            MONONOVO S.A.S. y no tendrán relación alguna de
                            empleo con los Usuarios.
                            <br></br>
                            <br></br>
                            14. RELACIÓN ENTRE LAS PARTES<br></br>
                            Estos Términos y condiciones son potestativos y nada
                            de lo incluido en el mismo deberá interpretarse en
                            el sentido de que se crea una relación de trabajo,
                            patrón, empleado, socio y asociado entre MONONOVO
                            S.A.S. y los Usuarios. Ninguna de las Partes estará
                            facultada para representar y obligar a la otra de
                            manera alguna, y cada una de las Partes serán
                            responsables exclusivamente de sus propios actos.
                            Por el contrario, la relación jurídica desarrollada
                            en las presentes cláusulas corresponderá a un
                            contrato típico de mutuo mercantil bajo las
                            especificidades aquí señaladas.
                            <br></br>
                            <br></br>
                            15. RESPONSABILIDAD MONONOVO S.A.S.<br></br>
                            Responderá única y exclusivamente frente a terceros
                            y frente a los Usuarios en aquellos casos en que se
                            demuestre culpa grave o dolo por parte de MONONOVO
                            S.A.S., en la causa que originó el daño o evento por
                            el que se deba responder. Su responsabilidad se
                            limita exclusivamente a las obligaciones que se
                            encuentran consagradas en estos Términos y
                            Condiciones o aquellas que pertenezcan a la
                            tipicidad de los contratos de mutuo mercantil. Los
                            Usuarios al utilizar la Plataforma aceptan la
                            limitación de responsabilidad asignada a MONONOVO
                            S.A.S.<br></br>
                            <br></br>
                            16. CASO FORTUITO O FUERZA MAYOR<br></br>
                            Ninguna de las Partes será responsable por
                            incumplimiento de los presentes Términos cuando éste
                            sea motivado por caso fortuito o fuerza mayor. No
                            obstante, la existencia de caso fortuito o fuerza
                            mayor no serán motivo para que la Parte afectada no
                            pague oportunamente las cantidades o cumpla con las
                            obligaciones consignadas en los Términos y
                            Condiciones, previas a la aparición de estas
                            situaciones.
                            <br></br>
                            La Parte afectada por tal situación notificará a la
                            otra por escrito en un plazo que no deberá exceder
                            de los diez (10) días calendario siguiente a que se
                            tenga conocimiento sobre la existencia de un caso
                            fortuito o de fuerza mayor. En el supuesto de que
                            las consecuencias de caso fortuito o fuerza mayor
                            persista por más de diez (10) días calendario a
                            partir de que la Parte afectada notifique de su
                            existencia, cualquiera de las Partes podrá dar por
                            terminada la Relación Comercial mediante aviso por
                            escrito a la otra Parte, dicha terminación entrará
                            en vigencia a los cinco (5) días calendario
                            posteriores a su notificación y sin necesidad de que
                            medie resolución judicial para tal efecto.<br></br>
                            <br></br>
                            17. RESOLUCIÓN DE CONTROVERSIAS<br></br>
                            Las diferencias y conflictos que surjan en
                            desarrollo del objeto contractual, se solucionarán a
                            través de los mecanismos de Conciliación, amigable
                            composición y transacción previstos en la Ley 446 de
                            1998, Ley 640 de 2001, el Decreto 1716 de 2009, ley
                            1564 de 2012 y demás normas concordantes<br></br>
                            <br></br>
                            18. CLÁUSULA PENAL<br></br>
                            Las Partes acuerdan que, en caso de incumplimiento
                            grave a sus obligaciones contenidas en los presentes
                            Términos y Condiciones, este dará lugar a
                            terminación de la relación entre las Partes, y la
                            Parte que incumpla se obliga a pagar a la Parte
                            afectada una pena convencional equivalente al valor
                            total del bien ofertado, comprado o vendido, o en
                            caso de que el incumplimiento no se pueda expresar
                            por el valor total del bien, corresponderá a diez
                            (10) salarios mínimos legales mensuales vigentes.
                            Asimismo, las Partes acuerdan expresamente que no
                            será necesario el requerimiento judicial o
                            extrajudicial para constituirse en mora respecto del
                            pago de la pena convencional, por tanto, la Parte
                            obligada al pago de la pena convencional deberá
                            pagar un interés moratorio mensual equivalente a la
                            Tasa Máxima de Interés, mismo que se calculará desde
                            el momento en que la obligación de pagar la pena
                            convencional debió ser liquidada y hasta el momento
                            en que efectivamente se pague la cantidad adeudada.
                            <br></br>
                            <br></br>
                            19. PROTECCIÓN DE DATOS PERSONALES<br></br>
                            Las Partes acuerdan que, para el caso en que, con
                            motivo de la prestación de los Servicios, MONONOVO
                            S.A.S. tenga que recabar Datos Personales de
                            particulares, las Partes acuerdan que el Cliente
                            será el “Responsable” de los Datos Personales que de
                            los particulares se recaben y de su Tratamiento, y
                            MONONOVO S.A.S. fungirá como “Encargado” de dichos
                            Datos Personales con el objeto de poder darle
                            Tratamiento a los mismos. Para efectos de lo
                            establecido en el presente numeral, se entenderá por
                            los términos “Datos Personales”, “Responsable”,
                            “Encargado” y “Tratamiento” la definición que para
                            dichos términos establece la Ley Estatutaria de
                            Protección de Datos Personales (Ley 1581 de 2012)
                            (en adelante “Ley de Datos Personales”) por lo que
                            las Partes asumen las obligaciones que la Ley de
                            Datos Personales les impone como “Responsable” y
                            “Encargado”, según corresponda. Asimismo, cualquier
                            Dato Personal que se recabe del Cliente o Usuario,
                            sus socios, accionistas, personal o trabajadores,
                            por parte de MONONOVO S.A.S. será tratado de
                            conformidad con el Aviso de Privacidad de MONONOVO
                            S.A.S., el cual puede ser consultado en la
                            plataforma.
                            <br></br>
                            <br></br>
                            20. INTERPRETACIÓN<br></br>
                            Los presentes Términos y Condiciones se
                            interpretarán de conformidad con el principio de la
                            buena fe y atendiendo a la finalidad del objeto por
                            virtud del cual se ha contratado, incorporando así
                            los criterios que de que tratan los artículos
                            propios del mutuo mercantil, así como de las
                            disposiciones normativas y jurisprudenciales acordes
                            al negocio jurídico pertinente. No obstante, se
                            consultará primero a la intención antes que a la
                            literalidad de las cláusulas convenidas. Estos
                            Términos y Condiciones se regirán e interpretarán
                            por las leyes aplicables a la República de Colombia,
                            particularmente por lo dispuesto en el Código de
                            Comercio Colombiano y la jurisprudencia desarrollada
                            por la Corte Constitucional, la Corte Suprema de
                            Justicia, el Consejo de Estado y la Superintendencia
                            de Industria y Comercio en desarrollo de sus
                            funciones jurisdiccionales.<br></br>
                            <br></br>
                            21. SARLAFT<br></br>
                            Las Partes declaran que sus negocios y los recursos
                            utilizados para la ejecución y pago de los Servicios
                            contratados, no provienen ni se destinarán al
                            ejercicio de ninguna actividad ilícita, lavado de
                            activos o financiación del terrorismo. Asimismo, se
                            comprometen a entregar toda la información que les
                            sea solicitada para dar cumplimiento a las
                            disposiciones relacionadas con la prevención del
                            lavado de activos y financiación del terrorismo y
                            declaran que la misma es veraz y verificable. Las
                            Partes se obligan a realizar todas las actividades
                            encaminadas a asegurar que todos sus socios,
                            empleados, contratistas, administradores, clientes,
                            proveedores y los recursos de éstos, no se
                            encuentren relacionados o provengan de actividades
                            ilícitas; En todo caso, si durante el plazo de
                            vigencia de los presentes Términos y Condiciones las
                            Partes o alguno de sus socios, administradores,
                            clientes, proveedores, contratistas o empleados
                            llegarán a resultar inmiscuidos en una investigación
                            de cualquier tipo como penal, administrativa, o de
                            cualquier otra índole, relacionada con actividades
                            ilícitas, lavado de activos o financiación del
                            terrorismo, o fuesen incluidos en listas de control
                            como las de la ONU, OFAC, etc., cualquiera de las
                            Partes tiene derecho a terminar unilateralmente la
                            relación entre las Partes.<br></br>
                            <br></br>
                            22. MODIFICACIONES<br></br>
                            MONONOVO S.A.S. podrá, a su sola y absoluta
                            discreción, cambiar por sí y sin aviso previo, estos
                            Términos y Condiciones. Sin embargo, tales cambios
                            sólo se aplicarán desde el momento en que sean
                            publicados en la Plataforma y regirán desde dicho
                            momento. A menos que se especifique lo contrario en
                            estos Términos de Uso, todos los avisos o
                            modificaciones serán considerados debidamente
                            entregados desde el momento de su publicación en la
                            Plataforma, o bien desde el momento en que sea
                            notificado al Usuario, según corresponda. Si el
                            Usuario tiene alguna duda respecto de los Términos y
                            Condiciones, Política de Privacidad, uso de la
                            Plataforma o de su Perfil, podrá ponerse en contacto
                            con MONONOVO S.A.S. vía el correo electrónico. Los
                            mensajes serán atendidos a la mayor brevedad
                            posible. Este canal será el de respuesta más rápida,
                            siendo los demás datos de contacto de uso
                            exclusivamente administrativo.<br></br>
                            <br></br>
                            23. ACUERDO COMPLETO<br></br>
                            Las Partes manifiestan que estos Términos y
                            Condiciones constituyen un acuerdo completo y total
                            acerca de su objeto, reemplazando cualquier otro
                            contrato verbal o escrito celebrado entre las mismas
                            Partes con anterioridad respecto del mismo objeto,
                            inclusive respecto de contratos anteriores que se
                            hayan cedido.<br></br>
                            Fecha de aprobación de la política y entrada en
                            vigor La presente Política entra en vigor a partir
                            del 23 de septiembre 2022 en su versión No. 01 y
                            reemplaza los términos y condiciones anteriores.
                            <br></br>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.check && (
                <div
                  className="formularioCodigoContrato"
                  id="infocontra"
                  aria-hidden="true"
                >
                  <b className="c2">
                    Al aceptar términos y condiciones, está firmando un contrato
                    con TU BONGO y autoriza que se realicen los descuentos por
                    nómina para el pago del crédito.
                  </b>
                </div>
              )}

              {/* boton continuar paso quinto */}
              {!this.otpValidationResult && (
                <div className="container-btn-terminar">
                  <button
                    id="envio4"
                    className="btn-enunciado-btnTerminar-disabled"
                    onClick={this.enviarEmail}
                    disabled
                  >
                    Solicitar préstamo
                  </button>
                </div>
              )}

              {this.otpValidationResult && (
                <div className="container-btn-terminar">
                  <button
                    id="envio4"
                    className="btn-enunciado-btnTerminar"
                    onClick={this.enviarEmail}
                  >
                    Solicitar préstamo
                  </button>
                </div>
              )}

              {/* <Link id="envio4" onClick={(e)=>{this.datosApi();this.enviarEmail(e)}} className="btn-enunciado-pagaC blanco" to='/paga_prestamo_quinto'> Continuar</Link>                               */}
            </div>
          </div>
        </div>
        <div
          className="back-modal"
          id="ErrorFecha"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-body">
                <h5 className="h5S">
                ¡Hola! Parece que ha pasado un tiempo desde que iniciaste la solicitud de tú préstamo. <br></br><br></br>
                Por tu seguridad, por favor inicia el proceso nuevamente.
                </h5>
                
                
                <button
                  type="button"
                  onClick={(e) => this.regresar(e)}
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="back-modal"
          id="terminos"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-body">
                <h5 className="h5S">
                  Para continuar con el proceso debes aceptar nuestros términos
                  y condiciones
                </h5>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("terminos").style.display = "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="back-modal"
          id="nominaObli"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-body">
                <h5 className="h5S">
                  Para seguir con el proceso debe aceptar el descuento por
                  nominá
                </h5>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("nominaObli").style.display =
                      "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="otpError"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-body">
                <h5 className="h5S">
                  Para seguir con el proceso, por favor verifique su número de
                  celular
                </h5>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("otpError").style.display = "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default PagaPrestamoCuarto;
