import React, { useState } from 'react'
import imgAmigos from '../../img/amigos.png'
import grupo225 from '../../img/grupo225.png'
import grupo226 from '../../img/grupo226.png'
import grupo228 from '../../img/grupo228.png'
import '../../css/inicio/SeccionCodigoAmigos.css'

function SeccionCodigoAmigos() {
  return (
    <div>
      <div className="row row-enunciado-centrado">
        <div className="col">
          <h1>Beneficios de tener un préstamo</h1>
          <hr />
        </div>
     
      <div className="row amigos">
        <div className="col-md-5">
          <img className="imgAmigoscodigo" src={imgAmigos}></img>
        </div>
        <div className="col-md-6">          
          <div className='row'>            
            <img className="col-md-2 imgder" src={grupo225}/>
            <p className='peque col-md-4'>La mejor tasa de interés del mercado</p>
          </div>
          <div className='row'>
            <img className="col-md-2 imgder" src={grupo226}/>
            <p className='peque col-md-4'>Solicita tu Préstamo en <b>10 minutos</b></p>
          </div>
          <div className='row'>
            <img className="col-md-2 imgder" src={grupo228}/>
            <p className='peque col-md-4'>Todo tu proceso está respaldado por altos estándares de seguridad</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default SeccionCodigoAmigos
