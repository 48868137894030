//Función de PAGO (excel) 
function calcularPago(tasa, periodos, prestamo) {
    var tasaInteres = tasa;
    var pago = prestamo * (tasaInteres / (1 - Math.pow(1 + tasaInteres, -periodos))); // Fórmula de PAGO
    return parseFloat(pago); //Pasar el número decimal para los decimales de la operación 
}
  
// Funcion para representar (($C$39*($C$9-$C$7+1)*$C$16)/$C$40) del primer condicional   
function calcularOperacion(monto, finMes, dia, tasa, plazo){
    var calculo = ((monto * (finMes - dia + 1) * tasa) / plazo)
    return calculo;
}

// Funcion para representar ($C$39*($C$9-$C$7+1)*$C$16)+$C$39 del segundo condicional
function calcularOperacionDos(monto, finMes, dia, tasa){
    var calculo = ((monto * (finMes - dia + 1) * tasa) + monto)
    return calculo;
}

// Funcion para representar (($C$39*($C$7-1)*$C$16)/$C$40)) del else del segundo conficional
function calcularOperacionTres(monto,dia, tasa, plazo){
    var calculo = ((monto * (dia - 1) * tasa) / plazo)
    return calculo;
}


export default { calcularPago: calcularPago, calcularOperacion: calcularOperacion, calcularOperacionDos: calcularOperacionDos, calcularOperacionTres:calcularOperacionTres };