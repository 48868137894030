import React from 'react'
import Footer from './Footer'
import neo from '../img/grupo182.svg'
import chica from '../img/chica-con-Dinero.png'
import '../css/Correo.css'
import Calculadora from './inicio/calculadora/Calculadora2'

class Correo extends React.Component{
  // navigate = useNavigate()
  dataLocal = null;
  hoy = null
  

    constructor() {
      super();
      // this.hoy = new Date()
      const Fecha = new Date()
      this.hoy = Fecha.toDateString()
      this.dataLocal = JSON.parse(localStorage.getItem('testeo'))
      this.dataInfo = JSON.parse(localStorage.getItem('info'))
      this.state = {
        valorPrestamo: this.dataLocal.valorPrestamo,
        cuotas: this.dataLocal.cuotas,
        fechaPlazo: this.dataLocal.fechaPlazo,
        interes: this.dataLocal.interes,
        tarifaAdministrativa: this.dataLocal.tarifaAdministrativa,
        ivaTarifaAdministrativa: this.dataLocal.ivaTarifaAdministrativa,
        consultaPerfil: this.dataLocal.consultaPerfil,
        totalPagar: this.dataLocal.totalPagar,
        celu: this.dataInfo.celu,
        bille: this.dataInfo.bille,
        nom: this.dataInfo.nom,
        ape: this.dataInfo.ape,
        nume: this.dataInfo.nume,
        fecha: this.dataInfo.Fecha,
        correo: this.dataInfo.correo,
        empre: this.dataInfo.empre,
        ingre: this.dataInfo.ingre,
        check: false
      }
    }

    enviarData = (e) =>{
      localStorage.setItem('testeo', JSON.stringify(this.state))
    }

    celustate = (target) =>{
      // this.state.celu.set(target.value)   
      this.setState((prevstate) => {
        return {celu:target.valu}
      })   
    }

    billestate = (target) =>{
      // this.state.celu.set(target.value)   
      this.setState((prevstate) => {
        return {bille:target.valu}
      })   
    }
    mostrartexto = (event) => {
      event.preventDefault()
  
    }

    llenarData = (e) => {
      if (e.target.dataset.tipo == "valor" && (e.target.className == 'button-active' || e.target.className == 'button-inactive')) {
        let tarifa = 10000;
        let ivaTarifa = (tarifa * 19) / 100;
        let interes = (e.target.value * (2 * (this.state.cuotas == 0 ? 1 : this.state.cuotas))) / 100;
        let total = (parseInt(e.target.value) + (parseInt(interes) / (this.state.cuotas == 0 ? 1 : this.state.cuotas)) + parseInt(tarifa) + parseInt(ivaTarifa) + parseInt(this.state.consultaPerfil)) / (this.state.cuotas == 0 ? 1 : this.state.cuotas);
        this.setState({
          valorPrestamo: e.target.value,
          interes: interes,
          tarifaAdministrativa: tarifa,
          ivaTarifaAdministrativa: ivaTarifa,
          totalPagar: total
        })
      } else if (e.target.dataset.tipo == "cuota" && (e.target.className == 'button-active' || e.target.className == 'button-inactive')) {
        this.setState({
          cuotas: e.target.value,
          interes: (this.state.valorPrestamo * (2 * e.target.value)) / 100
        });
      } else if (e.target.dataset.tipo == "plazo" && (e.target.className == 'button-active' || e.target.className == 'button-inactive')) {
        this.setState({
          plazo: e.target.value,
          fechaPlazo: e.target.value
        })
      }
    }

    terminos = () => {
     
      this.setState({
        check: !this.state.check
      })
      
    }

    
    validacioncampos=()=>{
     
      let habeasdata=document.getElementById("contrato").checked;
      
   
      
      if(habeasdata == false){
       alert("*Para seguir con el proceso debe aceptar las condiciones de contrato*");
      }
      
      else {
       window.location = '/paga_prestamo_quinto';
      }
    }  

    render(){ 
    return (
        <div className="correo">
            <div className="row">
              <div className='col-md-6'>
           <img src={neo} className="img-correo"></img>
           </div>
           <div className='col-md-4 offset-md-2'>
            <div className='formularioCodigo-correo'>
            <img src={chica} className="imgcorreo1"></img>
            </div>
            </div>
            </div>
              <div className="col-8 ">
                <h1 className='h1correo'>¡TE DAMOS LA BIENVENIDA A TU FAMILIA NEOPRESTAMOS!</h1><br></br>
                <h1 className='h1correop'>..........................................</h1><br></br>
                <h3 className='h3correo'>Gracias por elegirnos, a continuación te relacionamos el resumen de tu bongo</h3> <br></br>
              </div>
              <div className='row'>
                <div className='col-md-1'>
                <div className='formularioCodigo-correom'>
                </div>
              </div>
              <div className="col-md-8 offset-md-1">
                <div className="formularioCodigo-correo1">
                  <div className='row'>
                    <div className='col-4'>
                  <h1>Fecha de la solicitud:</h1>
                  </div>
                  <div className='col-4'>
                  <h3 className='cb1'> {this.hoy} </h3> 
                  {/* <h3><Moment format='DD/MM/YYYY'> {this.hoy} </Moment></h3> */}
                  </div>
                  </div>
                  <div className='row'>
                  <div className='col-4'>
                  <h1>nombres:</h1>
                  </div>
                  <div className='col-4'>
                  <h1 className='cb1'> {this.state.nom} </h1>
                  </div>
                  </div>
                  <div className='row'>
                  <div className='col-4'>
                  <h1>Apellidos:</h1>
                  </div>
                  <div className='col-4'>
                  <h1 className='cb1'> {this.state.ape} </h1>
                  </div>
                  </div>
                  <div className='row'>
                  <div className='col-4'>
                  <h1>Número de documento:</h1>
                  </div>
                  <div className='col-4'>
                  <h1 className='cb1'> {this.state.nume} </h1>
                  </div>
                  </div>
                  <div className='row'>
                  <div className='col-4'>
                  <h1>Correo electronico:</h1>
                  </div>
                  <div className='col-5'>
                  <h1 className='cb2'> {this.state.correo} </h1>
                  </div>
                  </div>
                  <div className='row'>
                  <div className='col-4'>
                  <h1>Empresa:</h1>
                  </div>
                  <div className='col-4'>
                  <h1 className='cb1'> {this.state.empre} </h1>
                  </div>
                  </div>
                  <div className='row'>
                  <div className='col-4'>
                  <h1>Monto solicitado:</h1>
                  </div>
                  <div className='col-4'>
                  <h1> {`$ ` + new Intl.NumberFormat('es-MX').format(this.state.valorPrestamo)}</h1> 
                  </div> 
                  </div>
                  <div className='row'>
                  <div className='col-4'>              
                  <h1>Numero de cuotas:</h1>
                  </div>
                  <div className='col-4'>
                  <h1> {this.state.cuotas}</h1>
                  </div>
                  </div>  
                  <div className='row'>
                  <div className='col-4'>                       
                  <h1>Valor de cada cuota:</h1>
                  </div>
                  <div className='col-4'>
                  <h1>$ {new Intl.NumberFormat('es-MX').format(this.state.totalPagar)}</h1>
                  </div> 
                  <div className='row'></div> 
                  <div className='col-4'>            
                  <h1>Billetera digítal:</h1>
                  </div>
                  <div className='col-4'>
                  <h1 className='cb1'> {this.state.bille} </h1>
                  </div>  
                  </div>   
                  <div className='row'>    
                  <div className='col-4'>      
                  <h1>Valor total de deuda:</h1>
                  </div>
                  <div className='col-4'>
                  <h1> $ {new Intl.NumberFormat('es-MX').format(this.state.valorPrestamo)}</h1>
                  </div>
                  </div>
                </div>         
              </div>
              </div>
              <div className="formularioCodigo-correo2">
                  <h1 class Name='claro'>Puedes consultar nuestros terminos y condiciones en nuestra pagina web</h1> 
                  <p className='center letra-gris-correo'>Recuerda que has firmado un contrato con nosotros, por lo cual se debe cumplir en su totalidad</p>              
                </div> 
                <img src={neo} className="img-correo3"></img>  
        </div>
    )
}

}
export default Correo
