import React from "react";
import Footer from "./Footer";
import grupo215 from "../img/Tu_bongo.png";
import grupo207 from "../img/grupo207.png";
import { Link } from "react-router-dom";
import sendEmail from "../../src/sendEmail";
import sendEmail2 from "../../src/sendEmail2";
import axios from "axios";
import "../css/PagaPrestamoQuinto.css";
import calcularFechas from "./FechasPagos";
const backend = require("../backend/enviroments");
class PagaPrestamoQuinto extends React.Component {
  dataLocal = null;
  hoy = null;

  constructor() {
    super();
    // this.hoy = new Date()
    const Fecha = new Date();
    this.hoy = Fecha.toDateString();
    this.dataLocal = JSON.parse(localStorage.getItem("testeo"));
    this.num_billetera = JSON.parse(localStorage.getItem("num_billetera"));
    this.dataInfo = JSON.parse(localStorage.getItem("info"));
    this.preguntas = JSON.parse(localStorage.getItem("preguntas"));
    this.infoIntereses = JSON.parse(localStorage.getItem("infoIntereses"));
    this.state = {
      valorPrestamo: this.dataLocal.valorPrestamo,
      cuotas: this.dataLocal.cuotas,
      fechaPlazo: this.dataLocal.fechaPlazo,
      interes: this.dataLocal.interes,
      tarifaAdministrativa: this.dataLocal.tarifaAdministrativa,
      ivaTarifaAdministrativa: this.dataLocal.ivaTarifaAdministrativa,
      consultaPerfil: this.dataLocal.consultaPerfil,
      totalPagar: this.dataLocal.totalPagar,
      celu: this.dataInfo.celu,
      bille: this.dataInfo.bille,
      correo: this.dataInfo.correo,
      nom: this.dataInfo.nom,
      nume: this.dataInfo.nume,
      fecha: this.dataInfo.Fecha,
      empre: this.dataLocal.empre,
      nit: this.dataInfo.nit,
      ingre: this.preguntas.pregunta8,
      check: false,
      numbille: this.num_billetera.numbille,
      conteo_dias: this.dataInfo.diaIntereses,
      pregunta1: this.preguntas.pregunta1,
      pregunta2: this.preguntas.pregunta2,
      pregunta3: this.preguntas.pregunta3,
      pregunta4: this.preguntas.pregunta4,
      pregunta5: this.preguntas.pregunta5,
      pregunta6: this.preguntas.pregunta6,
      pregunta7: this.preguntas.pregunta7,
      usoDinero: this.dataLocal.usoDinero,
      saldoc1: this.infoIntereses.saldoc1,
      saldoc2: this.infoIntereses.saldoc2,
      saldoc3: this.infoIntereses.saldoc3,
      interesc1: this.infoIntereses.interesc1,
      interesc2: this.infoIntereses.interesc2,
      interesc3: this.infoIntereses.interesc3,
      amortizacionc1: this.infoIntereses.amortizacionc1,
      amortizacionc2: this.infoIntereses.amortizacionc2,
      amortizacionc3: this.infoIntereses.amortizacionc3,
    };
  }

  cleardata() {
    localStorage.clear();
    window.location = "/";
  }
  componentDidMount = async () => {
    // Verificar si ya se ha realizado el envío del prestamo
    const envioRealizado = localStorage.getItem("envioRealizado");

    if (envioRealizado) {
      // Si ya se realizó el envío, redirigir a otra página y borrar el local storage de todos los pasos
      localStorage.clear();
      window.location = "/";
      return; // Detener la ejecución
    }

    console.log(this.dataLocal.totalInteresDiario);

    let date = new Date();

    //Ajuste de fecha de pago de las cuotas depenediendo del dia que se solicita el credito

    let fecha_cuota = "";
    let fecha_cuota2 = "";
    let fecha_cuota3 = "";

    let fechasPagos = await calcularFechas(parseInt(this.state.cuotas));
    fecha_cuota = fechasPagos.fecha_primera_cuota;
    fecha_cuota2 = fechasPagos.fecha_segunda_cuota;
    fecha_cuota3 = fechasPagos.fecha_tercera_cuota;

    let fecha_solicitud =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    let valorDeuda = this.dataLocal.totalPagar * this.dataLocal.cuotas;

    // let email = `katherine.arevalo@bongoanalytics.com,michael.pena@bongoanalytics.com,kevin.betancourt@bongoanalytics.com`;
    let email = `michael.pena@bongoanalytics.com`;

    let emailUser = `${this.state.correo}`;

    let tarifaAdministrativa = this.dataLocal.tarifaAdministrativa;
    let consultaPerfil = this.dataLocal.consultaPerfil;

    await axios({
      method: "post",
      url: backend.createclient(),
      data: {
        nombres: this.state.nom,
        apellidos: this.dataInfo.ape,
        fecha_nacimiento: this.dataInfo.fecha,
        correo: this.dataInfo.correo,
        numero_documento: parseInt(this.state.nume),
        celular: parseInt(this.dataInfo.celu),
        numero_billetera:
          this.state.numbille == ""
            ? this.dataInfo.celu
            : parseInt(this.state.numbille),
        fecha_cuota: fecha_cuota,
        habeas_data: true,
        metodo_desembolso: this.state.bille,
        valor_deuda: parseInt(valorDeuda),
        monto_cuotas: parseInt(this.dataLocal.totalPagar),
        monto_prestamo: parseInt(this.dataLocal.valorPrestamo),
        empresa: this.dataInfo.empre,
        nit: parseInt(this.dataInfo.nit),
        ingresos: this.state.ingre,
        cuotas: parseInt(this.dataLocal.cuotas),
        easybio: true,
        estado_desembolso: "Pendiente",
        estado_prestamo: "Preaprobado",
        cuota1: "Pendiente",
        cuota2: "Pendiente",
        cuota3: "Pendiente",
        fecha_solicitud: fecha_solicitud,
        firma_contrato: true,
        descuento_nomina: true,
        fecha_desembolso: "null",
        fecha_cuota1: "null",
        fecha_cuota2: "null",
        fecha_cuota3: "null",
        fechaestimada_cuota2: fecha_cuota2,
        fechaestimada_cuota3: fecha_cuota3,
        fecha_finalizado: "null",
        tasa_interes: parseFloat(this.dataLocal.totalInteresDiario),
        conteo_dias: parseInt(this.dataLocal.diasTotales),
        tarifa_administrativa: parseInt(tarifaAdministrativa),
        consulta_perfil: parseInt(consultaPerfil),
        uso_dinero: this.state.usoDinero,
        pr_genero: this.state.pregunta1,
        pr_estado_civil: this.state.pregunta2,
        pr_tipo_vivienda: this.state.pregunta3,
        pr_nivel_escolaridad: this.state.pregunta4,
        pr_estrato: this.state.pregunta5,
        pr_personas_cargo: this.state.pregunta6,
        pr_antiguedad_empresa: this.state.pregunta7,
        tasa_interes_em: this.dataLocal.tasa_interes_em,
        tasa_interes_ea: this.dataLocal.tasa_interes_ea,
        valor_interes: this.dataLocal.interes.toFixed(),
        novedad: "NO",
        observacion: "SIN OBSERVACIONES",
        saldoc1: this.state.saldoc1,
        saldoc2: this.state.saldoc2,
        saldoc3: this.state.saldoc3,
        interesc1: this.state.interesc1,
        interesc2: this.state.interesc2,
        interesc3: this.state.interesc3,
        amortizacionc1: this.state.amortizacionc1,
        amortizacionc2: this.state.amortizacionc2,
        amortizacionc3: this.state.amortizacionc3,
      },
    });

    let datos = await axios.get(
      backend.getoneclient(parseInt(this.state.nume))
    );

    let validacion = datos["data"]["Cliente"];

    let resultId = validacion[validacion.length - 1]["_id"];

    //ENVIO DE CORREO USUARIO SOLICITUS EXITOSA

    let dataEmail = JSON.stringify({
      email: emailUser,
      emailcco: email,
      id: resultId,
    });

    // URL PRODUCCION
    let url1 = "https://apiemail.tubongo.com/email-confirmation-user";
    // URL PRE PRODUCCION
    // let url1 = "http://190.109.16.229:1092/email-confirmation-user";
    axios
      .post(url1, dataEmail)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    //CAMBIO DE AMBIENTE PRE PROD LINEA 341-348, SI SE TRATA DE PROD POR FAVOR DESCOMENTAR LA LINEA 351-358

    let emailRH = " ";

    //Pre producción
    // if (this.dataInfo.empre === "BONGO ANALYTICS") {
    //   emailRH =
    //     "cristian.silva@bongoanalytics.com,katherine.arevalo@bongoanalytics.com"; //establecer el email de RRHH
    // } else {
    //   emailRH =
    //     "michael.pena@bongoanalytics.com,kevin.betancourt@bongoanalytics.com";
    // }

    if (this.dataInfo.empre === "BONGO ANALYTICS") {
      emailRH = 'jeimmy.lopez@bongoanalytics.com,sergio.carrera@bongoanalytics.com'; //establecer el email de RRHH
    } else {
      emailRH ='rhpearsolutions@gmail.com,lorena.correal@pearsolutions.com.co,ana.espejo@pearsolutions.com.co';
    }

    let data = JSON.stringify({
      email: emailRH,
      id: resultId,
    });

    // URL PRODUCCION
    let url = "https://apiemail.tubongo.com/send-email";
    // URL PRE PRODUCCION
    // let url = 'http://190.109.16.229:1092/send-email';

    axios
      .post(url, data)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    localStorage.setItem("envioRealizado", "true");
    setInterval(this.cleardata, 60000);
  };

  render() {
    return (
      <div className="paga-prestamo-quinto">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <h1 className="h1 rightq">¡FELICIDADES!</h1>
            <h3 className="h3Q arribaq col-10">
              Ya haces parte de la familia TU BONGO tu préstamo se encuentra Pre
              aprobado
            </h3>
            <h3 className="h3Q arribaq col-10">
              al instante se realizará la transferencia a tu billetera digital.
            </h3>
            <h3 className="h3Q arribaq col-10">
              Recuerda que las cuotas se descontarán de tu nómina.
            </h3>
            <img className="imgpequeñaq" src={grupo215}></img>
            <button className="btn-enunciado-paga-quinto">
              <Link className="blanco" to="/" onClick={this.cleardata}>
                Terminar
              </Link>
            </button>
          </div>
          <div className="col-md-5 ">
            <img className="importante-quinto" src={grupo207}></img>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
export default PagaPrestamoQuinto;
