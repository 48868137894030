import React, { useDebugValue, useState } from "react";
import "../../../css/inicio/SeccionCalculadora.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import imagen_advertencia from "../../../img/imagen_advertencia.png";
import imagen_advertencia from "../../../img/Mantenimiento.png";
import formulaCalculos from "./FormulaCalculoFinancieros";
import Pago from "./Pago";
import procedimientoIntereses from "./ProcedimientoIntereses";
import AmortizacionIntereses from "./AmortizacionIntereses";
import axios from "axios";

// function Calculadora(props) {
class Calculadora extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valorPrestamo: 200000,
      cuotas: 1,
      totalInteresDiario: 0,
      fechaPlazo: 30,
      interes: 0,
      tarifaAdministrativa: 0,
      ivaTarifaAdministrativa: 0,
      consultaPerfil: 0,
      totalPagar: 0,
      fecha_Actual: "",
      interes_actual: 0,
      diaIntereses: 0,
      diasTotales: 0,
      tasa_interes_em: 0,
      tarifas: "",
    };
  }

  async componentDidMount() {
    // obtener tasas y tarifas de la db
    // //Produccion
    let requestTarifas = await axios.get(
      "https://apineopage.tubongo.com/api/tarifas"
    );
    //Pre-Produccion
    // let requestTarifas = await axios.get(
    //   "http://190.131.244.220:10/api/tarifas"
    // );
    let tarifas = requestTarifas.data[requestTarifas.data.length - 1];
    //VARIABLES PARA EL CALCULO DE DIAS DE INTERES
    let date = new Date();
    let fecha_Actual =
      String(date.getDate()).padStart(2, "0") +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();

    const diaActual = parseInt(date.getDate());
    const diaIntereses = 30 - diaActual;
    let interes_actual = 0;
    let diasTotales = 0;
    if (fecha_Actual > "10 12:00:00") {
      diasTotales = diaIntereses + 30;
    } else {
      diasTotales = diaIntereses;
    }

    //Nuevas variables para calculos financieros
    let tasaEA = tarifas.tarifa_efectiva_anual / 100;
    let tasaEM = Math.pow(1 + tasaEA, 1 / 12) - 1;
    let tarifaAdministrativa = 40000;
    let ivaTarifaAdministrativa = 0;
    let consultaPerfil = tarifas.validacion_perfil;
    let intereses = 200000 * 1 * tasaEM;
    let totalDeuda = 200000 + intereses + tarifaAdministrativa + consultaPerfil;
    let totalInteresDiario = Math.pow(1 + tasaEA, 1 / 360) - 1;
    let fCalculos = formulaCalculos.formulaCalculos(200000, tasaEM, 1);
    let valorCuota = formulaCalculos.valorCuota(
      fCalculos,
      tarifaAdministrativa,
      consultaPerfil,
      1
    );
    intereses = procedimientoIntereses(
      100000,
      tasaEM,
      valorCuota,
      tarifaAdministrativa,
      consultaPerfil,
      1
    );

    //Nueva forma de calcular el valor de la cuota y reducir el margen de error
    date.setHours(0, 0, 0, 0);
    var diaActualFormula = parseInt(fecha_Actual.substring(0, 2));
    var numCuotas = 1;

    // Obtener el primer día del siguiente mes
    var mesSiguiente = new Date(date.getFullYear(), date.getMonth() + 2, 1);

    // Obtener el último día del siguiente mes
    var ultimoDiames = new Date(
      mesSiguiente.getFullYear(),
      mesSiguiente.getMonth(),
      0
    );

    var ultimodiamesactualFecha = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    );

    var ultimodiamesactual = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    // Obtener la diferencia en días
    var diferenciaDiasCasoUno = (
      (ultimoDiames - date) /
      (1000 * 60 * 60 * 24)
    ).toFixed();

    // Obtener la diferencia en días para el caso 2

    var diferenciaDiasCasoDos =
      (ultimodiamesactualFecha - date) / (1000 * 60 * 60 * 24);

    let costosAdim = (tarifaAdministrativa + consultaPerfil) / 1;

    if (diaActualFormula >= "10") {
      if (numCuotas === 1) {
        valorCuota =
          200000 *
            Math.pow(1 + totalInteresDiario, diferenciaDiasCasoUno) *
            1.00048789549231 +
          costosAdim;
      } else {
        var pago = Pago.calcularPago(tasaEM, 1, 200000);
        var operacion = Pago.calcularOperacion(
          200000,
          ultimodiamesactual,
          diaActualFormula,
          totalInteresDiario,
          1
        );
        valorCuota = pago + operacion + costosAdim;
      }
    } else {
      if (numCuotas === 1) {
        valorCuota =
          200000 * Math.pow(1 + totalInteresDiario, diferenciaDiasCasoDos) +
          costosAdim;
      } else {
        var pago = Pago.calcularPago(tasaEM, 1, 200000);
        var operacion = Pago.calcularOperacionTres(
          200000,
          diaActualFormula,
          totalInteresDiario,
          1
        );
        console.log("diaActualFormula", diaActualFormula);
        console.log("pago", pago);
        console.log("operacion", operacion);
        valorCuota = (pago - operacion) * 0.99927 + costosAdim;
      }
    }

    intereses = AmortizacionIntereses(
      200000,
      numCuotas,
      valorCuota,
      costosAdim,
      diferenciaDiasCasoUno,
      diferenciaDiasCasoDos,
      totalInteresDiario,
      tasaEM,
      ultimoDiames,
      ultimodiamesactualFecha,
      mesSiguiente,
      diaActualFormula
    );

    // console.log(totalInteresDiario, tasaEM)
    this.setState({
      valorPrestamo: 200000,
      cuotas: "1",
      totalInteresDiario: totalInteresDiario,
      fechaPlazo: 30,
      interes: intereses,
      tarifaAdministrativa: tarifaAdministrativa,
      ivaTarifaAdministrativa: ivaTarifaAdministrativa,
      consultaPerfil: consultaPerfil,
      totalPagar: valorCuota,
      fecha_Actual: fecha_Actual,
      interes_actual: interes_actual,
      diaIntereses: diaIntereses,
      diasTotales: diasTotales,
      tasa_interes_em: tasaEM,
      tarifas: tarifas,
    });
  }

  llenarData = (e) => {
    const btn2 = document.getElementById("btn-cuota-2");
    const btn1 = document.getElementById("btn-cuota-1");

    btn2.disabled = false;
    btn1.disabled = false;
    btn2.style.cursor = "pointer"
    btn1.style.cursor = "pointer"
    
    // btn2.classList.remove("button-disabled")


    if (this.state.fecha_Actual > "10 12:00:00") {
      switch (e.target.value) {
        case "1":
          this.state.interes_actual =
            (this.state.diaIntereses + 30) * this.state.totalInteresDiario;
          this.state.diasTotales = this.state.diaIntereses + 30;
          break;

        case "2":
          this.state.interes_actual =
            (this.state.diaIntereses + 60) * this.state.totalInteresDiario;
          this.state.diasTotales = this.state.diaIntereses + 60;
          break;

        case "3":
          this.state.interes_actual =
            (this.state.diaIntereses + 90) * this.state.totalInteresDiario;
          this.state.diasTotales = this.state.diaIntereses + 90;
          break;

        default:
          break;
      }
    } else {
      switch (e.target.value) {
        case "1":
          this.state.interes_actual =
            this.state.diaIntereses * this.state.totalInteresDiario;
          this.state.diasTotales = this.state.diaIntereses;
          break;

        case "2":
          this.state.interes_actual =
            (this.state.diaIntereses + 30) * this.state.totalInteresDiario;
          this.state.diasTotales = this.state.diaIntereses + 30;
          break;

        case "3":
          this.state.interes_actual =
            (this.state.diaIntereses + 60) * this.state.totalInteresDiario;
          this.state.diasTotales = this.state.diaIntereses + 60;

          break;

        default:
          break;
      }
    }

    if (
      e.target.dataset.tipo == "valor" &&
      (e.target.className == "button-active" ||
        e.target.className == "button-inactive")
    ) {
      //asignar la tarifa administrativa segun el numero de cuotas
      let tarifaAdministrativa = 0;
      const arregloTarifas = {
        200000: [40000, 45000, 50000],
        400000: [50000, 55000, 60000],
        600000: [60000, 65000, 70000],
        800000: [70000, 75000, 80000],
        1000000: [80000, 85000, 90000],
      };
      const valorTarifa = arregloTarifas[parseInt(e.target.value)];

      if (
        parseInt(e.target.value) === 1000000 ||
        parseInt(e.target.value) === 800000
      ) {
        this.state.valorPrestamo = e.target.value;
        document.getElementById("btn-cuota-3").click();
        const btn2 = document.getElementById("btn-cuota-2");
        const btn1 = document.getElementById("btn-cuota-1");

        btn2.disabled = true;
        btn1.disabled = true;
        btn2.style.cursor = "not-allowed"
        btn1.style.cursor = "not-allowed"
        return;
      }

      switch (parseInt(this.state.cuotas)) {
        case 1:
          tarifaAdministrativa = valorTarifa[0];
          break;
        case 2:
          tarifaAdministrativa = valorTarifa[1];
          break;
        case 3:
          tarifaAdministrativa = valorTarifa[2];
          break;
        default:
          break;
      }
      let tasaEA = this.state.tarifas.tarifa_efectiva_anual / 100;
      let tasaEM = Math.pow(1 + tasaEA, 1 / 12) - 1;
      let ivaTarifaAdministrativa = 0;
      let consultaPerfil = this.state.tarifas.validacion_perfil;
      let intereses =
        parseInt(e.target.value) * parseInt(this.state.cuotas) * tasaEM;
      let totalDeuda =
        parseInt(e.target.value) +
        intereses +
        tarifaAdministrativa +
        consultaPerfil;
      let fCalculos = formulaCalculos.formulaCalculos(
        parseInt(e.target.value),
        tasaEM,
        parseInt(this.state.cuotas)
      );
      let valorCuota = formulaCalculos.valorCuota(
        fCalculos,
        tarifaAdministrativa,
        consultaPerfil,
        parseInt(this.state.cuotas)
      );

      intereses = procedimientoIntereses(
        e.target.value,
        tasaEM,
        valorCuota,
        tarifaAdministrativa,
        consultaPerfil,
        parseInt(this.state.cuotas)
      );

      //Nueva forma de calcular el valor de la cuota y reducir el margen de error
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      var diaActualFormula = parseInt(this.state.fecha_Actual.substring(0, 2));
      var numCuotas = parseInt(this.state.cuotas);

      // Obtener el primer día del siguiente mes
      var mesSiguiente = new Date(date.getFullYear(), date.getMonth() + 2, 1);

      // Obtener el último día del siguiente mes
      var ultimoDiames = new Date(
        mesSiguiente.getFullYear(),
        mesSiguiente.getMonth(),
        0
      );

      var ultimodiamesactualFecha = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );

      var ultimodiamesactual = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      // Obtener la diferencia en días
      var diferenciaDiasCasoUno = (
        (ultimoDiames - date) /
        (1000 * 60 * 60 * 24)
      ).toFixed();

      // Obtener la diferencia en días para el caso 2
      var diferenciaDiasCasoDos = (
        (ultimodiamesactualFecha - date) /
        (1000 * 60 * 60 * 24)
      ).toFixed();

      let costosAdim = (tarifaAdministrativa + consultaPerfil) / numCuotas;

      if (diaActualFormula >= "10") {
        if (numCuotas === 1) {
          valorCuota =
            parseInt(e.target.value) *
              Math.pow(
                1 + this.state.totalInteresDiario,
                diferenciaDiasCasoUno
              ) *
              1.00048789549231 +
            costosAdim;
        } else {
          var pago = Pago.calcularPago(
            tasaEM,
            numCuotas,
            parseInt(e.target.value)
          );
          var operacion = Pago.calcularOperacion(
            parseInt(e.target.value),
            ultimodiamesactual,
            diaActualFormula,
            this.state.totalInteresDiario,
            numCuotas
          );
          valorCuota = (pago + operacion) * 1.00048789549231 + costosAdim;
        }
      } else {
        if (numCuotas === 1) {
          valorCuota =
            parseInt(e.target.value) *
              Math.pow(
                1 + this.state.totalInteresDiario,
                diferenciaDiasCasoDos
              ) +
            costosAdim;
        } else {
          var pago = Pago.calcularPago(
            tasaEM,
            numCuotas,
            parseInt(e.target.value)
          );
          var operacion = Pago.calcularOperacionTres(
            parseInt(e.target.value),
            diaActualFormula,
            this.state.totalInteresDiario,
            numCuotas
          );
          valorCuota = (pago - operacion) * 0.99927 + costosAdim;
        }
      }

      intereses = AmortizacionIntereses(
        parseInt(e.target.value),
        numCuotas,
        valorCuota,
        costosAdim,
        diferenciaDiasCasoUno,
        diferenciaDiasCasoDos,
        this.state.totalInteresDiario,
        tasaEM,
        ultimoDiames,
        ultimodiamesactualFecha,
        mesSiguiente,
        diaActualFormula
      );

      this.setState({
        valorPrestamo: e.target.value,
        interes: intereses,
        tarifaAdministrativa: tarifaAdministrativa,
        ivaTarifaAdministrativa: ivaTarifaAdministrativa,
        totalPagar: valorCuota,
      });
    } else if (
      e.target.dataset.tipo == "cuota" &&
      (e.target.className == "button-active" ||
        e.target.className == "button-inactive")
    ) {
      //asignar la tarifa administrativa segun el numero de cuotas
      let tarifaAdministrativa = 0;
      const arregloTarifas = {
        200000: [40000, 45000, 50000],
        400000: [50000, 55000, 60000],
        600000: [60000, 65000, 70000],
        800000: [70000, 75000, 80000],
        1000000: [80000, 85000, 90000],
      };
      const valorTarifa = arregloTarifas[parseInt(this.state.valorPrestamo)];
      switch (parseInt(e.target.value)) {
        case 1:
          tarifaAdministrativa = valorTarifa[0];
          break;
        case 2:
          tarifaAdministrativa = valorTarifa[1];
          break;
        case 3:
          tarifaAdministrativa = valorTarifa[2];
          break;
        default:
          break;
      }

      //Nuevas variables para calculos financieros
      let tasaEA = this.state.tarifas.tarifa_efectiva_anual / 100;
      let tasaEM = Math.pow(1 + tasaEA, 1 / 12) - 1;
      let ivaTarifaAdministrativa = 0;
      let consultaPerfil = this.state.tarifas.validacion_perfil;
      let intereses =
        parseInt(this.state.valorPrestamo) * parseInt(e.target.value) * tasaEM;
      let totalDeuda =
        parseInt(this.state.valorPrestamo) +
        intereses +
        tarifaAdministrativa +
        consultaPerfil;
      let fCalculos = formulaCalculos.formulaCalculos(
        parseInt(this.state.valorPrestamo),
        tasaEM,
        parseInt(e.target.value)
      );
      let valorCuota = formulaCalculos.valorCuota(
        fCalculos,
        tarifaAdministrativa,
        consultaPerfil,
        parseInt(e.target.value)
      );

      intereses = procedimientoIntereses(
        this.state.valorPrestamo,
        tasaEM,
        valorCuota,
        tarifaAdministrativa,
        consultaPerfil,
        parseInt(e.target.value)
      );
      //Nueva forma de calcular el valor de la cuota y reducir el margen de error
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      var diaActualFormula = parseInt(this.state.fecha_Actual.substring(0, 2));
      var numCuotas = parseInt(e.target.value);

      // Obtener el primer día del siguiente mes
      var mesSiguiente = new Date(date.getFullYear(), date.getMonth() + 2, 1);

      // Obtener el último día del siguiente mes
      var ultimoDiames = new Date(
        mesSiguiente.getFullYear(),
        mesSiguiente.getMonth(),
        0
      );

      var ultimodiamesactualFecha = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );

      var ultimodiamesactual = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      // Obtener la diferencia en días
      var diferenciaDiasCasoUno = (
        (ultimoDiames - date) /
        (1000 * 60 * 60 * 24)
      ).toFixed();

      // Obtener la diferencia en días para el caso 2
      var diferenciaDiasCasoDos = (
        (ultimodiamesactualFecha - date) /
        (1000 * 60 * 60 * 24)
      ).toFixed();

      let costosAdim = (tarifaAdministrativa + consultaPerfil) / numCuotas;

      if (diaActualFormula >= "10") {
        if (numCuotas === 1) {
          valorCuota =
            parseInt(this.state.valorPrestamo) *
              Math.pow(
                1 + this.state.totalInteresDiario,
                diferenciaDiasCasoUno
              ) *
              1.00048789549231 +
            costosAdim;
        } else {
          var pago = Pago.calcularPago(
            tasaEM,
            numCuotas,
            parseInt(this.state.valorPrestamo)
          );
          var operacion = Pago.calcularOperacion(
            parseInt(this.state.valorPrestamo),
            ultimodiamesactual,
            diaActualFormula,
            this.state.totalInteresDiario,
            numCuotas
          );
          valorCuota = (pago + operacion) * 1.00048789549231 + costosAdim;
        }
      } else {
        if (numCuotas === 1) {
          valorCuota =
            parseInt(this.state.valorPrestamo) *
              Math.pow(
                1 + this.state.totalInteresDiario,
                diferenciaDiasCasoDos
              ) +
            costosAdim;
        } else {
          var pago = Pago.calcularPago(
            tasaEM,
            numCuotas,
            parseInt(this.state.valorPrestamo)
          );
          var operacion = Pago.calcularOperacionTres(
            parseInt(this.state.valorPrestamo),
            diaActualFormula,
            this.state.totalInteresDiario,
            numCuotas
          );
          valorCuota = (pago - operacion) * 0.99927 + costosAdim;
        }
      }

      intereses = AmortizacionIntereses(
        parseInt(this.state.valorPrestamo),
        numCuotas,
        valorCuota,
        costosAdim,
        diferenciaDiasCasoUno,
        diferenciaDiasCasoDos,
        this.state.totalInteresDiario,
        tasaEM,
        ultimoDiames,
        ultimodiamesactualFecha,
        mesSiguiente,
        diaActualFormula
      );

      this.setState({
        cuotas: e.target.value,
        valorPrestamo: this.state.valorPrestamo,
        interes: intereses,
        tarifaAdministrativa: tarifaAdministrativa,
        ivaTarifaAdministrativa: ivaTarifaAdministrativa,
        totalPagar: valorCuota,
      });
    } else if (
      e.target.dataset.tipo == "plazo" &&
      (e.target.className == "button-active" ||
        e.target.className == "button-inactive")
    ) {
      this.setState({
        plazo: e.target.value,
        fechaPlazo: e.target.value,
      });
    }
  };

  enviarData = (e) => {
    var fechaHoraActual = new Date();
    localStorage.setItem("fechaHoraActual", fechaHoraActual);
    localStorage.setItem("testeo", JSON.stringify(this.state));
  };

  render() {
    // const propiedadTesteo = this.props.propiedadTesteo;
    const { propiedadTesteo } = this.props;

    return (
      <div className="carta-calculadora">
        <div className="titulo-carta header-card">
          <h1>¿Cuánto te prestamos?</h1>
        </div>
        <div className="div-seleccion-opciones">
          <div className="row">
            <div className="col center">
              <button
                className={`${
                  this.state.valorPrestamo == 200000
                    ? "button-active"
                    : "button-inactive"
                }`}
                onClick={(e) => this.llenarData(e)}
                data-tipo="valor"
                value="200000"
              >
                200.000
                <i className="far fa-check-circle"></i>
              </button>
            </div>
            <div className="col center">
              <button
                className={`${
                  this.state.valorPrestamo == 400000
                    ? "button-active"
                    : "button-inactive"
                }`}
                onClick={(e) => this.llenarData(e)}
                data-tipo="valor"
                value="400000"
              >
                400.000
                <i className="far fa-check-circle"></i>
              </button>
            </div>
            <div className="col center">
              <button
                className={
                  this.state.valorPrestamo == 600000
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={(e) => this.llenarData(e)}
                data-tipo="valor"
                value="600000"
              >
                600.000
                <i className="far fa-check-circle"></i>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col center">
              <button
                className={
                  this.state.valorPrestamo == 800000
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={(e) => this.llenarData(e)}
                data-tipo="valor"
                value="800000"
              >
                800.000 <i className="far fa-check-circle"></i>
              </button>
            </div>

            <div className="col center">
              <button
                className={
                  this.state.valorPrestamo == 1000000
                    ? "button-active"
                    : "button-inactive"
                }
                onClick={(e) => this.llenarData(e)}
                data-tipo="valor"
                value="1000000"
              >
                1.000.000<i className="far fa-check-circle"></i>
              </button>
            </div>
          </div>

          <p>¿A cuántas cuotas?</p>
          <div className="row">
            <div className="col center">
              <button
                className={
                  this.state.cuotas == 1 ? "button-active" : this.state.valorPrestamo <= 600000 ? "button-inactive" :
                  "button-disabled"
                }
                // onClick={(e) => setCuotas(1)}
                onClick={(e) => this.llenarData(e)}
                data-tipo="cuota"
                value="1"
                id="btn-cuota-1"
              >
                1 Cuota
                <i className="far fa-check-circle"></i>
              </button>
            </div>
            <div className="col center">
              <button
                className={
                  this.state.cuotas == 2 ? "button-active" : this.state.valorPrestamo <= 600000 ? "button-inactive" :
                  "button-disabled"
                }
                // onClick={(e) => setCuotas(2)}
                onClick={(e) => this.llenarData(e)}
                data-tipo="cuota"
                value="2"
                id="btn-cuota-2"
              >
                2 Cuotas
                <i className="far fa-check-circle"></i>
              </button>
            </div>
            <div className="col center">
              <button
                className={
                  this.state.cuotas == 3 ? "button-active" : "button-inactive"
                }
                // onClick={(e) => setCuotas(3)}
                onClick={(e) => this.llenarData(e)}
                data-tipo="cuota"
                value="3"
                id="btn-cuota-3"
              >
                3 Cuotas
                <i className="far fa-check-circle"></i>
              </button>
            </div>
          </div>
          {/* <p>¿En cuanto tiempo lo pagarías por nomina?</p>
          <div className="row">
            <div className="col center">
              <button
                className={(this.state.plazo == 30 ? "button-active" : "button-inactive")}
                // onClick={(e) => setPlazo(7)}
                onClick={(e) => this.llenarData(e)}
                data-tipo="plazo"
                value="30"
              >30 Días &nbsp;&nbsp;<i className="far fa-check-circle"></i></button>
            </div>
            <div className="col center">
              <button
                className={(this.state.plazo == 60 ? "button-active" : "button-inactive")}
                // onClick={(e) => setPlazo(15)}
                onClick={(e) => this.llenarData(e)}
                data-tipo="plazo"
                value="60"
              >60 Días &nbsp;&nbsp;<i className="far fa-check-circle"></i></button>
            </div>
            <div className="col center">
              <button
                className={(this.state.plazo == 90 ? "button-active" : "button-inactive")}
                // onClick={(e) => setPlazo(30)}
                onClick={(e) => this.llenarData(e)}
                data-tipo="plazo"
                value="90"
              >90 Días &nbsp;&nbsp;<i className="far fa-check-circle"></i></button>
            </div>
          </div> */}
        </div>
        {/* body card */}
        <div className="body-formulario-prestamo">
          <p className="titulo">Resumen de préstamo</p>
          <div className="row">
            <div className="col-8">
              <p className="suave izquierda">Valor solicitado</p>
            </div>
            <div className="col">
              <p className="suave derecha">
                {`$` +
                  new Intl.NumberFormat("es-MX").format(
                    this.state.valorPrestamo
                  )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <p className="suave izquierda">Cuotas del préstamo</p>
            </div>
            <div className="col">
              <p className="suave derecha">{this.state.cuotas}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <p className="suave izquierda">Intereses 26% E.A.</p>
            </div>
            <div className="col">
              <p className="suave derecha">
                $
                {new Intl.NumberFormat("es-MX").format(
                  this.state.interes.toFixed(0)
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <p className="suave izquierda">Tarifa Administrativa</p>
            </div>
            <div className="col">
              <p className="suave derecha">
                $
                {new Intl.NumberFormat("es-MX").format(
                  this.state.tarifaAdministrativa
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <p className="suave izquierda">IVA Tarifa Administrativa</p>
            </div>
            <div className="col">
              <p className="suave derecha">
                $
                {new Intl.NumberFormat("es-MX").format(
                  this.state.ivaTarifaAdministrativa
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <p className="suave izquierda">Consultar Perfil</p>
            </div>
            <div className="col">
              <p className="suave derecha">
                $
                {new Intl.NumberFormat("es-MX").format(
                  this.state.consultaPerfil
                )}
              </p>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-8">
              <p className="llenita izquierda">Total a Pagar por cada cuota</p>
            </div>
            <div className="col">
              <p className="llenita derecha">
                $
                {new Intl.NumberFormat("es-MX").format(
                  this.state.totalPagar.toFixed(0)
                )}
              </p>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-8">
              <p className="llenita izquierda">Fecha de Pago</p>
            </div>
            <div className="col">
              <p className="llenita derecha">{this.state.fechaPlazo}</p>
            </div>
          </div> */}
        </div>
        {/* footer card */}
        {/* <button className="footer-card" ><Link  className="blanco" to='/paga_prestamo'>¡Solicítalo Ahora!</Link>&nbsp;&nbsp;&nbsp;</button> */}
        <button className="footer-card " onClick={(e) => this.enviarData(e)}>
          <Link className="blanco" to="/paga_prestamo">
            ¡Solicítalo Ahora!
          </Link>
        </button>
        {/* MODAL DE PLATAFORMA EN MANTENIMIENTO */}
        {/* <div
          className="back-modal"
          id="semana"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          >
          <div className="modal-dialog-centered s_modal ">
            <div className="modal-content curva">
              <img src={imagen_advertencia}></img>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

Calculadora.propTypes = {
  propiedadTesteo: PropTypes.func.isRequired,
};

export default Calculadora;
