import React from "react";
import "../../../css/VerifyOTP.css";

class VerifyOTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otpValues: ["", "", "", "", "", ""],
    };
  }

  inputRefs = [];

  digitValidate = (input, index) => {
    const { otpValues } = this.state;
    otpValues[index] = input.value;
    this.setState({ otpValues });
  };

  tabChange = (currentInput) => {
    const input = this.inputRefs[currentInput];
    const nextInput = this.inputRefs[currentInput + 1];
    const previousInput = this.inputRefs[currentInput - 1];

    if (input.value.length > 0 && nextInput) {
      nextInput.focus();
    } else if (input.value.length === 0 && previousInput) {
      previousInput.focus();
    }
  };

  handleVerification = () => {
    const { otpValues } = this.state;

    if (otpValues.some((value) => value === "")) {
      console.log("Todos los campos son obligatorios");
      return;
    }

    const otpCode = otpValues.join("");
    console.log(otpCode);
    this.verificationCode(otpCode);
  };

  verificationCode = (otpCode) => {
    const { confirmationResult } = this.props;
    const code = otpCode;
    confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        localStorage.setItem(
          "otpVerification",
          JSON.stringify({ validation: true })
        );
        document.getElementById("confirmModal").style.display = "block";
        document.getElementById("otpContainer").style.display = "none";
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        document.getElementById("errorModal").style.display = "block";
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  render() {
    return (
      <div className="container">
        <div className="row justify-content-md-center mb-4">
          <div className="col-md-9 text-center">
            <div className="row">
              <div className="col-sm-12 mt-2 bgWhite" id="otpContainer">
                <div className="title">Verificar Código</div>

                <form action="" className="mt-2 mb-3">
                  <div className="form-group row">
                    <div className="col-2">
                      <input
                        className="otp form-control"
                        type="text"
                        onInput={(e) => this.digitValidate(e.target, 0)}
                        onKeyUp={() => this.tabChange(0)}
                        maxLength={1}
                        ref={(ref) => (this.inputRefs[0] = ref)}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        className="otp form-control"
                        type="text"
                        onInput={(e) => this.digitValidate(e.target, 1)}
                        onKeyUp={() => this.tabChange(1)}
                        maxLength={1}
                        ref={(ref) => (this.inputRefs[1] = ref)}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        className="otp form-control"
                        type="text"
                        onInput={(e) => this.digitValidate(e.target, 2)}
                        onKeyUp={() => this.tabChange(2)}
                        maxLength={1}
                        ref={(ref) => (this.inputRefs[2] = ref)}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        className="otp form-control"
                        type="text"
                        onInput={(e) => this.digitValidate(e.target, 3)}
                        onKeyUp={() => this.tabChange(3)}
                        maxLength={1}
                        ref={(ref) => (this.inputRefs[3] = ref)}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        className="otp form-control"
                        type="text"
                        onInput={(e) => this.digitValidate(e.target, 4)}
                        onKeyUp={() => this.tabChange(4)}
                        maxLength={1}
                        ref={(ref) => (this.inputRefs[4] = ref)}
                      />
                    </div>
                    <div className="col-2">
                      <input
                        className="otp form-control"
                        type="text"
                        onInput={(e) => this.digitValidate(e.target, 5)}
                        onKeyUp={() => this.tabChange(5)}
                        maxLength={1}
                        ref={(ref) => (this.inputRefs[5] = ref)}
                      />
                    </div>
                  </div>
                </form>
                <hr className="mt-2" />
                <button
                  className="btn btn-primary btn-block mt-4 mb-2 customBtn"
                  onClick={this.handleVerification}
                  disabled={this.state.otpValues.some((value) => value === "")}
                >
                  Verificar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="confirmModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-body">
                <h5 className="h5S">Su número fue confirmado correctamente</h5>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("confirmModal").style.display =
                      "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="errorModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              <div className="modal-body">
                <h5 className="h5S">
                  El código ingresado no es correcto, intente nuevamente o reenvia el codigo en la opción "Verificar Número"
                </h5>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("errorModal").style.display =
                      "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyOTP;
