import React from 'react'
import '../css/inicio.css'
import Footer from './Footer'
import SeccionBurbujas from './inicio/SeccionBurbujas'
import SeccionCalculadora from './inicio/SeccionCalculadora'
import SeccionCodigoAmigos from './inicio/SeccionCodigoAmigos'
import SeccionSolicita from './inicio/SeccionSolicita'
import Modal from './Modal'

function Inicio() {
  return (
    <div className="inicio">
      <div className="primera-seccion">
        <div className="container">
          <SeccionCalculadora></SeccionCalculadora>
          <SeccionCodigoAmigos></SeccionCodigoAmigos>
        </div>
      </div>
       <div className="segunda-seccion">
        <div className="container">
          <SeccionBurbujas></SeccionBurbujas>
        </div>
      </div>
      <div className="tercera-seccion">
        <div className="container">
          <SeccionSolicita></SeccionSolicita>
        </div>
      </div>
      <Footer></Footer> 
    </div>
  )
}

export default Inicio
