import React from 'react'
import './css/App.css';
import Header from './components/Header';


function App() {
  return (
    <div className="App">
      <Header></Header>
    </div>
  );
}

export default App;
