export default function procedimientoIntereses(
  monto,
  tasaEM,
  valor_cuota,
  tarifaAdministrativa,
  consultaPerfil,
  cuotas
) {
  let capitalVivo = monto;
  let totalIntereses = 0;
  let cuota = valor_cuota;

  while (capitalVivo > 0) {
    let interes = capitalVivo * tasaEM;
    let capital1 = (tarifaAdministrativa + consultaPerfil) / cuotas;
    let capital2 = cuota - interes;
    let capitalAmortizado = capital2 - capital1;
    capitalVivo = capitalVivo - capitalAmortizado;
    totalIntereses = interes + totalIntereses;
  }

  return totalIntereses;
}
