import {db} from "./firebase";
import {collection,addDoc} from "firebase/firestore";

export default async function sendEmail(email,subject,body){

    const collectionRef = collection(db,'kevin.lopez@intevo.com.co');
    const emailContent ={
        to: email,
    message: {
      subject: subject,
      text: body,
      html: body,
    }
    };
    
    return await addDoc(collectionRef,emailContent);


}