import React, { useState } from 'react'
import Footer from './Footer'
import '../css/Perfil.css'
import imgBurbuja8 from '../img/burbuja8.png';
import imgBurbuja9 from '../img/burbuja9.png';
import imgBurbuja10 from '../img/burbuja10.png';
import grafica from '../img/grafica.png';
import calendario from '../img/calendario.png';
import muchacho from '../img/muchacho.png';

function Perfil() {
  const [nombre = 'Carlos Andres Leal', setNombre] = useState();
  const [id = '123456789', setId] = useState();
  const [cod = '123456789', setCod] = useState();

  return (
    <div className="mi-perfil">
      <div className="fondo-perfil"> {/* Foto de perfil, portada, burbujas, grafica */}
        <div className="container">
          <div className="row justify-content-center margin-25-0">
            <div className="col-6 datos-perfil">
              <h1>{nombre}</h1>
              <p><b>ID:</b>&nbsp;{id}</p>
              <p><b>Código:</b>&nbsp;{cod}</p>
            </div>
          </div>
          <div className="row margin-25-0">
            <div className="col col-imagen">
              <img className="imgBurbuja" src={imgBurbuja8} />
            </div>
            <div className="col col-imagen">
              <img className="imgBurbuja" src={imgBurbuja9} />
            </div>
            <div className="col col-imagen">
              <img className="imgBurbuja-90" src={imgBurbuja10} />
            </div>
          </div>
          <div className="row margin-25-0">
            <div className="col div-grafica">
              <img className="imgBurbuja" src={grafica} />
            </div>
            <div className="col-4">
              <h3>Artículos Recientes</h3>
              <ul>
                <li>
                  <p>Your content goes here. Edit or remove this text inline or in the.</p>
                  <label>Nov. 10 - 2021</label>
                </li>
                <li>
                  <p>Your content goes here. Edit or remove this text inline or in the.</p>
                  <label>Nov. 10 - 2021</label>
                </li>
                <li>
                  <p>Your content goes here. Edit or remove this text inline or in the.</p>
                  <label>Nov. 10 - 2021</label>
                </li>
                <li>
                  <p>Your content goes here. Edit or remove this text inline or in the.</p>
                  <label>Nov. 10 - 2021</label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col div-calendario">
              <h1>Tu programación de pagos</h1>
              <p>Noviembre 2021</p>
              <img className="imgBurbuja" src={calendario} />
            </div>
          </div>
        </div>
      </div>
      <div className="perfil-azul">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Encuentra todo a la mano toda tu información</h1>
              <p>Gestiona y administra tu perfil súper fácil y a la mano </p>
            </div>
            <div className="col">
              <img className="imgBurbuja" src={muchacho} />
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Perfil
