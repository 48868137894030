import anuncio from "../img/anuncio.png" 
import React, { useState } from 'react';
export default function Modal() {

    const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };
    return (
    <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" style={{ display: isOpen ? 'block' : 'none' }}>
      <div
          className="back-modal"
          id="semana"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          onClick={closeModal}
        >

        <div className="h-100 w-20 pt-2 pb-2">
            <img src={anuncio} className="w-100 h-100" alt="anuncio" />
        </div>
          
        </div> 
    </div>
  );

}