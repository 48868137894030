function formulaCalculos(monto, tasaEM, cuotas) {
  let primeraParteFormula = monto * tasaEM;
  let segundaParteFormula = Math.pow(1 + tasaEM, -cuotas);
  return primeraParteFormula / (1 - segundaParteFormula);
}

//TODO

// function formulaCalculosFinancieros(
//   tasaEA,
//   tarifaAdministrativa,
//   consultaPerfil,
//   monto,
//   cuotas
// ) {
//   let tasaEM = Math.pow(1 + tasaEA, 1 / 12) - 1;
//   let ivaTarifaAdministrativa = 0;
//   let intereses = monto * cuotas * tasaEM;
//   let totalDeuda = monto + intereses + tarifaAdministrativa + consultaPerfil;
//   let valorCuota = totalDeuda / cuotas;
//   let totalInteresDiario = Math.pow(1 + tasaEA, 1 / 360) - 1;
//   let lista = {
//     totalInteresDiario: totalInteresDiario,
//     interes: intereses,
//     tarifaAdministrativa: tarifaAdministrativa,
//     ivaTarifaAdministrativa: ivaTarifaAdministrativa,
//     consultaPerfil: consultaPerfil,
//     totalPagar: valorCuota,
//     tasa_interes_em: tasaEM,
//   };
//   return lista;
// }

function valorCuota(fCalculos, tarifaAdministrativa, consultaPerfil, cuotas) {
  let valor_cuota =
    fCalculos + ((tarifaAdministrativa + consultaPerfil) / cuotas);
  return valor_cuota;
}

export default { formulaCalculos: formulaCalculos, valorCuota: valorCuota };
