import React from "react";
import Footer from "./Footer";
import Maps from "./Maps";
import grupo214 from "../img/grupo214.png";
import grupo221 from "../img/grupo221.png";
import grupo222 from "../img/grupo222.svg";
import enmascarargrupo6 from "../img/enmascarargrupo6.png";
import enmascarargrupo7 from "../img/enmascarargrupo7.png";
import enmascarargrupo8 from "../img/enmascarargrupo8.png";
import { Link } from "react-router-dom";
import "../css/PagaPrestamoTercero.css";
import axios from "axios";


class PagaPrestamoTercero extends React.Component {
  constructor() {
    super();
    this.dataLocal = JSON.parse(localStorage.getItem("testeo"));
    this.restric = localStorage.getItem("restric");

    if (this.restric != "P;&{6K+qGxA5") {
      window.location = "/";
      localStorage.clear();
    }

    this.state = {
      tipo: "",
      nume: "",
      ape: "",
      fecha: "",
      celu: "",
      empre: "",
      ingre: "",
      bille: "",
      texto: false,
      confirmationResult: "",
    };
  }
  componentDidMount() {
    //Lee el script determinado para el boton del servicio
    this.dataInfo = JSON.parse(localStorage.getItem("info"));
    const script = document.createElement("script");
    const cedula = this.dataInfo.nume;

    script.src = "https://web-button.getmati.com/button.js";
    script.async = true;

    document.body.appendChild(script);

    const button = document.getElementById("metamap-button");

    // devuelve el id de transacion
    button.addEventListener("mati:userFinishedSdk", ({ detail }) => {
      const id_ususario = detail;

      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          authorization:
            "Basic NjQxOWY0ZGU1MWYxOGYwMDFiYzJkNjI0OlUzUlk0NlFSUFNJQVFQSE40WkpJRkZHUEFST0JHRFBU",
        },
        body: new URLSearchParams({ grant_type: "client_credentials" }),
      };

      fetch("https://api.getmati.com/oauth", options)
        .then((response) => response.json())
        .then((token) => {
          const mitoken = token;
          const options = {
            method: "GET",
            url: `https://api.getmati.com/v2/verifications/${id_ususario.verificationId}`,
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              authorization: `Bearer ${mitoken.access_token}`,
            },
          };

          axios
            .request(options)
            .then(function (response) {
              console.log(response);
              const respuesta = response.data.identity.status;
              const cedula_respuesta =
                response.data.documents["0"]["fields"]["documentNumber"].value;
              let num_cedula = cedula_respuesta.replace(/\./g, "");
              if (num_cedula == cedula && respuesta == "verified") {
                document.getElementById("aprobado").style.display = "block";
              } else if (respuesta == "rejected") {
                document.getElementById("negado").style.display = "block";
              } else {
                document.getElementById("vacio").style.display = "block";
              }
            })
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch((err) => console.error(err));
    });
    button.addEventListener("mati:exitedSdk", ({ detail }) => {});
  }

  validacionP = (e) => {
    localStorage.setItem("restric", "7ll%gzi5W06^");
  };


  easybio = async (e) => {
    let date = new Date();
    let fechaC =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0");
    this.dataInfo = JSON.parse(localStorage.getItem("info"));
    const cedula = this.dataInfo.nume;
    //   let res = await axios.get(`https://easybio.easydata.com.co/api2/v2/form/easybio_id/?cedula=${cedula}`)
    //   let validation = res

    //    document.getElementById("vacio").style.display="block";

    //    console.log("dataeasy")
    //   let fechaeasy= validation["data"]["timestamp"].substring(0,10)
    //    console.log(fechaeasy)
    //    console.log(fechaC)

    //   try{
    //   if (fechaeasy>=fechaC && validation["data"]["result"]["transaction"] == "approved"){

    //     document.getElementById("aprobado").style.display ="block";
    //     document.getElementById("vacio").style.display="";

    //   }
    //  else if(fechaeasy!=fechaC){
    //         document.getElementById("fecha").style.display ="block";
    //         document.getElementById("vacio").style.display="";
    //  }

    //   else if (validation["data"]["result"]["transaction"] == "denied") {

    //     document.getElementById("negado").style.display="block";
    //     document.getElementById("vacio").style.display="";

    //   }

    // }
    // catch(error){
    //   console.error(error);

    // }
  };

  cancelar() {
    window.location.reload();
  }

  terminos = () => {
    this.setState({
      texto: !this.state.texto,
      nume: !this.state.nume,
    });
  };

  render() {
    return (
      <div className="paga-prestamo-tercero">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <h1 className="h1T">PASO 4: Verificación de identidad</h1>
            <h3 className="h3T">
              Para realizar la verificación de tu identidad sigue estas
              instrucciones:
            </h3>
            <img className="imgFlecha" src={grupo221}></img>
            <h5 className="h5T">
              <b>Dar click aquí</b>
            </h5>
            {/* boton Meta Map */}
            <div className="boton-verify">
              <mati-button
                clientid="6419f4de51f18f001bc2d624"
                flowId="6419f4de51f18f001bc2d623"
                id="metamap-button"
                color="#0061ff"
              />
            </div>
            <h3 className="h3T">
              <b>- Inicia la validación de tu identidad</b>
            </h3>
          </div>
          <div className="col-md-5">
            <img className="importante-tercero" src={grupo214}></img>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light menu abajo">
          <div className=" offset-md-3 container-fluid-tercero">
            <h1 className="letra">Tips para la validación de tu identidad</h1>
          </div>
        </nav>
        <div className="row">
          <div className="col-md-4 ">
            <h1 className="h1T11 col-8">Reconocimiento facial</h1>
            <img className="imgEnmascarar2" src={enmascarargrupo6} />
            <br></br>
            <div className="row col-7">
              <b className="aquiT1 ">
                Tener conexión a internet estable al momento de tomar el video
                de tu rostro, retira gafas, sombreros o cualquier elemento que
                cubra.
              </b>
            </div>
          </div>
          <div className="col-md-4">
            <h1 className="h1T11 col-8">Foto frontal documento</h1>
            <img className="imgEnmascarar" s src={enmascarargrupo7} />
            <br></br>
            <div className="row col-7">
              <b className="aquiT1">
                Asegúrate de elegir la foto de la parte frontal del documento de
                identidad, la imagen debe ser de excelente calidad, de fácil
                lectura y en formato JPG, PNG o PDF.
              </b>
            </div>
          </div>
          <div className="col-md-4">
            <h1 className="h1T11">Foto reverso documento</h1>
            <img className="imgEnmascarar" src={enmascarargrupo8} />
            <br></br>
            <div className="row col-7">
              <b className="aquiT1">
                Asegúrate de elegir la foto del reverso del documento de
                identidad, la imagen debe ser de excelente calidad, de fácil
                lectura y en formato JPG, PNG o PDF.
              </b>
            </div>
          </div>

          {/* boton Meta Map */}
          <div>
            <script src="https://web-button.getmati.com/button.js"></script>
            <div className="boton-verify btn-enunciado-pagaT">
              <mati-button
                clientid="6419f4de51f18f001bc2d624"
                flowId="6419f4de51f18f001bc2d623"
                id="metamap-button"
                color="#0061ff"
              />
            </div>
          </div>

          {/* <button className="btn-enunciado-pagaT blanco" data-bs-toggle="modal" data-bs-target="#confirmacion">Continuar en whatsapp</button> */}
        </div>

        <div
          class="modal fade"
          id="confirmacion"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content curva">
              {/* <div className="modal-header">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div> */}
              <div class="modal-body">
                <h5 className="moverT">Recomendación:</h5>
                <ul>Por favor realizar la validación en su teléfono movil.</ul>
                <a
                  checked={this.state.texto}
                  onClick={this.terminos}
                  className="btn-enunciado-pagaTE blanco"
                  href="https://api.whatsapp.com/send/?phone=573143764327&text=Buenos+d%C3%ADas%2C+deseo+realizar+el+proceso+de+validaci%C3%B3n+de+identidad+para+solicitar+un+Neopr%C3%A9stamo"
                  target="_blank"
                >
                  whatsapp
                </a>
                {this.state.texto && (
                  // <h5 className='moverT1'>¿Ya realizaste la validación de identidad?</h5>
                  <div className="formularioCodigo">
                    <h5 className="moverT">
                      ¿Ya realizaste la validación de identidad?
                    </h5>
                    <div class="modal-footer center">
                      <button
                        type="button"
                        onClick={(e) => this.easybio(e)}
                        className="btn-enunciado-pagaSi blanco d-flex justify-content-center"
                        data-bs-toggle="modal"
                        data-bs-target="#aprobado"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Sí, continuar
                      </button>

                      <a
                        className="btn-enunciado-pagaNo blanco d-flex justify-content-center"
                        href="https://api.whatsapp.com/send?phone=573143764313&text=Validar%20identidad%20sin%20Easybyo"
                      >
                        No, no fue posible
                      </a>

                      {/* <a className="btn-enunciado-pagaNo blanco d-flex justify-content-center"  data-bs-dismiss="modal" aria-label="Close" onClick={this.cancelar} >No, voy a realizarlo</a>   */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="aprobado"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered modal-peque">
            <div className="modal-content curva modal-peque">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  {" "}
                  <b>! Su validación de identidad ha sido exitosa !</b>
                </h4>
                <button
                  type="button"
                  onClick={this.validacionP}
                  className="btn-enunciado-paga91 justify-content-center"
                  aria-label="Close"
                >
                  <Link
                    className="blanco justify-content-center"
                    to="/paga_prestamo_quinto"
                  >
                    Siguiente
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="vacio"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  {" "}
                  <b>
                    Realiza nuevamente la validacion de identidad. El proceso no
                    fue exitoso.
                  </b>
                </h4>
                <button
                  type="button"
                  onClick={() =>
                    (document.getElementById("vacio").style.display = "none")
                  }
                  className="btn-enunciado-paga91"
                  aria-label="Close"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="fecha"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  {" "}
                  <b>
                    Por favor realizar nuevamente tu validación de identidad.
                  </b>
                </h4>
                <br></br>
                <h4 className="h5S">
                  {" "}
                  <b>
                    Recuerda, que la validación se debe realizar el mismo día de
                    la solicitud del préstamo.
                  </b>
                </h4>
                <button
                  type="button"
                  onClick={() =>
                    (document.getElementById("fecha").style.display = "none")
                  }
                  className="btn-enunciado-paga91"
                  aria-label="Close"
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="negado"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: `none` }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  Tu proceso de validación de identidad <b>NO</b> fue exitoso.
                  Por favor, inicia nuevamente la solicitud de tu préstamo
                </h4>
                <button
                  type="button"
                  className="btn-enunciado-paga91"
                  aria-label="Close"
                >
                  <Link className="blanco" to="/paga_prestamo">
                    Repetir proceso
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default PagaPrestamoTercero;
