import React from "react";
import Footer from "./Footer";
import amigazos from "../img/amigos-de-color.png";
import { Link } from "react-router-dom";
import "../css/PoliticasPrivacidad.css";

function PoliticasTerminos({ titulo }) {
  return (
    <div className="politicas-terminos">
      <div>
        <div className="col-md-11">
          <div className="formularioCodigo222">
            <div className="header-card-politicas">
              {/* <h1>POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES</h1> */}
              <h1>{titulo}</h1>
            </div>
            <div className="input-group mb-3">
            <p>
              <br />
                La presente política regula el tratamiento de los datos
                personales de los clientes, contratistas, trabajadores y
                cualquier otra persona que tenga una relación jurídica con
                MONONOVO S.A.S., persona jurídica identificada con NIT No.
                901648093 - 5, constituida conforme a las leyes de la República
                de Colombia, domiciliada en Bogotá D.C. (en adelante y para
                efectos del presente documento se denomina MONONOVO S.A.S.),
                MONONOVO S.A.S. está comprometida con la protección de la
                privacidad y toda la información que pueda asociarse o
                relacionarse con personas naturales o jurídicas determinadas,
                (en adelante y para efectos del presente documento se denominan
                los Datos Personales) a los cuales MONONOVO S.A.S. tenga acceso
                en el desarrollo de sus actividades financieras y mercantiles.
                En este sentido, la sociedad recibe a través de los diferentes
                canales de información en lo referente a su recolección,
                almacenamiento, administración, utilización, procesamiento,
                análisis, transferencia, transmisión, protección y supresión de
                datos de identificación (nombre, cédula, edad, género) de
                contacto (teléfono, correo electrónico, dirección), de
                preferencia de consumo, de visitas y de comportamiento en
                internet, información financiera y otros datos relevantes para
                la operación mercantil; información que podrá ser obtenida
                directamente de los titulares o a través del uso de plataformas
                de Internet pertenecientes a la sociedad, previa autorización
                del cliente. Al ingresar sus datos e información, el titular
                expresa y otorga su consentimiento al uso de dichos datos
                conforme se describe en esta Política de Privacidad y
                Tratamiento de Datos Personales. <br />
                <br />
                <b>IDENTIFICACIÓN DEL RESPONSABLE DE TRATAMIENTO</b>
                <br />
                <br />
                MONONOVO S.A.S. constituida y existente bajo las leyes de la
                República de Colombia, en adelante MONONOVO S.A.S., actuando
                como responsable del tratamiento de información personal, se
                identifica a través de los siguientes datos:
                <br />
                <br />
                RAZÓN SOCIAL MONONOVO S.A.S.
                <br /> NIT No. 901648093 – 5<br /> Teléfono: Dirección: Carrera
                14 # 94 – 44 of 801
                <br />
                <br /> DEFINICIONES
                <br />
                Autorización: Consentimiento previo, expreso e informado del
                Titular para llevar a cabo el Tratamiento de datos personales.
                <br />
                Base de Datos: Conjunto organizado de datos personales que sea
                objeto de Tratamiento.
                <br /> Dato personal: cualquier información vinculada o que
                pueda asociarse a una o a varias personas naturales determinadas
                o determinables.
                <br /> Dato personal semiprivado: Son aquellos datos que no
                tienen una naturaleza íntima, reservada, ni pública y cuyo
                conocimiento o divulgación puede interesar no solo a su titular,
                sino a un grupo de personas o a la sociedad en general. En este
                caso, para su tratamiento se requiere la autorización expresa
                del Titular de la información. Por ejemplo: datos de carácter
                financiero, datos relativos a las relaciones con las entidades
                de seguridad social (EPS, AFP, ARL, Cajas de Compensación).{" "}
                <br />
                Dato personal sensible: Son aquellos que afectan la intimidad
                del Titular o cuyo uso indebido puede generar su discriminación,
                tales como aquellos que revelen el origen racial o étnico, la
                orientación política, las convicciones religiosas o filosóficas,
                la pertenencia a sindicatos, organizaciones sociales, de
                derechos humanos o que promueva intereses de cualquier partido
                político o que garanticen los derechos y garantías de partidos
                políticos de oposición así como los datos relativos a la salud,
                a la vida sexual y los datos biométricos. <br />
                Dato personal privado: Es un dato personal que por su naturaleza
                íntima o reservada solo interesa a su Titular y para su
                tratamiento requiere de su autorización expresa. Por ejemplo:
                Nivel de escolaridad, libros de los comerciantes, entre otros.{" "}
                <br />
                Dato personal público: es aquel tipo de dato personal que las
                normas y la Constitución han determinado expresamente como
                públicos y, para cuya recolección y tratamiento, no es necesaria
                la autorización del Titular de la información. Por ejemplo:
                estado civil de las personas, datos contenidos del RUNT, datos
                contenidos en sentencias judiciales ejecutoriadas, entre otros.{" "}
                <br />
                Encargado del Tratamiento: persona natural o jurídica, pública o
                privada, que por sí misma o en asocio con otros, realice el
                Tratamiento de datos personales por cuenta del responsable del
                Tratamiento. Responsable del Tratamiento: Persona natural o
                jurídica, pública o privada, que por sí misma o en asocio con
                otros, decida sobre la base de datos y/o el Tratamiento de los
                datos.
                <br /> Titular: Persona natural cuyos datos personales sean
                objeto de Tratamiento.
                <br /> Tratamiento: Cualquier operación o conjunto de
                operaciones sobre datos personales, tales como la recolección,
                almacenamiento, uso, circulación o supresión. <br />
                Transferencia: La transferencia de datos tiene lugar cuando el
                responsable y/o Encargado del Tratamiento de datos personales,
                ubicado en Colombia, envía la información o los datos personales
                a un receptor, que a su vez es Responsable del Tratamiento y se
                encuentra dentro o fuera del país. Transmisión: Tratamiento de
                datos personales que implica la comunicación de estos dentro o
                fuera del territorio de la República de Colombia cuando tenga
                por objeto la realización de un Tratamiento por el Encargado por
                cuenta del responsable.
                <br />
                <br />
                OBJETIVO
                <br />
                <br /> La presente Política de Tratamiento de Datos Personales
                tiene como propósito establecer los criterios bajo los cuales se
                realiza el tratamiento de la información personal que reposa en
                las bases de datos, archivos físicos y digitales de MONONOVO
                S.A.S., dando así cumplimiento al artículo 15 y 20 de la
                Constitución Nacional, Ley 1266 de 2008, la Ley 1581 de 2012, el
                Capítulo 25 del Decreto 1074 de 2015 y la Sentencia C-748 de
                2011. <br />
                <br />
                ALCANCE DE LA POLÍTICA
                <br />
                <br /> Esta política aplica para toda la información personal
                registrada en las bases de datos de MONONOVO S.A.S., quien actúa
                en calidad de responsable del tratamiento de datos personales.
                Así mismo establece los criterios que MONONOVO S.A.S. ha
                incorporado para el tratamiento de los datos personales, los
                mecanismos para que los titulares puedan ejercer sus derechos,
                las finalidades, las medidas de seguridad, y otros aspectos
                relacionados con la protección de la información personal. La
                presente Política de tratamiento de datos personales está
                dirigida a cualquier titular de la información o aquel que actúe
                como su representante legal y del cual MONONOVO S.A.S. haya
                requerido información personal para el desarrollo de alguna
                actividad. <br />
                <br />
                TIPOS DE DATOS PERSONALES TRATADOS
                <br /> MONONOVO S.A.S., en el desarrollo de sus actividades
                requiere realizar el tratamiento de las siguientes categorías de
                datos:
                <br />
                <br /> Datos académicos.
                <br /> Datos de características personales.
                <br />
                Datos de circunstancias sociales.
                <br /> Datos de contacto.
                <br /> Datos de salud. <br />
                Datos electrónicos. <br />
                Datos identificativos. <br />
                Datos laborales. <br />
                Datos patrimoniales.
                <br /> Datos públicos.
                <br /> Datos sensibles.
                <br />
                <br />
                TRATAMIENTO AL CUAL SE SOMETEN LOS DATOS PERSONALES
                <br /> Los datos personales que son obtenidos por MONONOVO
                S.A.S. están sometidos a los siguientes tratamientos:
                <br />
                <br /> Recolección: MONONOVO S.A.S. recolecta información
                personal a través de diversos medios en el desarrollo de las
                diferentes actividades relacionadas con su objeto social, y las
                obligaciones que tiene como empleador. La información personal
                será obtenida de tres formas diferentes: a) directamente del
                titular, b) de un tercero siempre y cuando este cuente con la
                autorización y c) de fuentes públicas de información. Así mismo
                la recolección de información personal podrá llevarse a cabo a
                través de medios físicos, digitales o electrónicos, y en cada
                uno de ellos se incorporará un aviso de privacidad y
                autorización, dando así cumplimiento a los requisitos
                establecidos en el art. 2.2.2.25.3.2 y 2.2.2.25.3.3 del decreto
                1074 y obedeciendo los principios de libertad y finalidad del
                art. 4 de la Ley 1581. Almacenamiento: El almacenamiento de la
                información personal contenida en las bases de datos o sistemas
                de información se encuentra en los servidores propios dentro del
                país, y en servidores externos de terceros, los cuales cuentan
                con medidas de seguridad física, técnicas y administrativas, y
                cuenta con controles de acceso a la información, garantizando el
                principio de acceso y circulación restringida. La información
                personal que se encuentre sujeta a requerimientos de Ley
                permanecerá almacenada en nuestras bases de datos de acuerdo con
                lo que para esto la Ley haya establecido, en aquellos casos
                donde la Ley no se ha pronunciado la información permanecerá
                mientras que la finalidad para la cual fue recolectada se
                encuentre vigente. Circulación: Por regla general, MONONOVO
                S.A.S. no comparte los datos personales que recolecta con
                terceros. No obstante, para el cumplimiento efectivo de sus
                obligaciones puede entregar los datos a entidades, amparados en
                los artículos 2.2.2.25.5.1 y 2.2.2.25.5.2 del decreto 1074 que
                establece que se encuentra permitido la transmisión de datos
                personales cuando sea necesario para la ejecución de un contrato
                entre el titular y el responsable del tratamiento, o para la
                ejecución de medidas precontractuales siempre y cuando se cuente
                con la autorización del titular o exista un contrato de
                transmisión de datos personales. Supresión: La supresión de la
                información personal que haya sido recolectada se llevará a cabo
                cuando: (I) no sea necesaria para el cumplimiento de aspectos
                legales, contractuales, tributarios, financieros, de auditoría o
                esté cubierta por disposiciones o requerimientos de Ley, (II) No
                afecte o implique la perdida de trazabilidad o integridad de las
                bases de datos o sistemas de información donde reposa la
                información; (III) Se haya cumplido o eliminado la finalidad
                para la cual fueron recolectados. (IV) Sea solicitada por el
                titular de los datos o aquel que demuestre que se encuentra
                autorizado y no vaya en contra de las definiciones anteriores.
                No obstante, pueda que alguna información se conserve únicamente
                con fines estadísticos o de auditoría.<br /><br /> USOS O FINALIDADES DE LA
                RECOLECCIÓN DE INFORMACIÓN. <br /><br /> El uso o finalidad que se da a la
                información personal recolectada de cada tipo de titular es:<br /><br />
                TIPOS DE TITULAR.<br /> FINALIDADES DE USO.<br /> PROVEEDORES O
                CONTRATISTAS.<br /> Generar estadísticas internas.<br /> Adelantar
                procedimientos administrativos. <br />Realizar actividades de gestión
                administrativa. Realizar actividades de cobro y pago.<br /> Generar
                facturas. <br />Gestionar las relaciones, derechos y deberes que se
                tienen con proveedores.<br /> Cumplir con los deberes económicos y
                contables.<br /> Gestionar y mantener un histórico de relaciones
                comerciales. <br />Gestionar la seguridad en todos sus aspectos.<br />
                Controlar el acceso y salida de personas en las instalaciones.<br />
                Adelantar campañas de Actualización de datos e información de
                cambios en el tratamiento de datos personales. <br />Realizar la
                verificación de requisitos jurídicos, técnicos y/o financieros.<br />
                Realizar el envío de comunicaciones a nivel general.<br /> Asegurar la
                gestión de la seguridad en nuestras instalaciones.<br /> Adelantar
                investigaciones en caso de situaciones de riesgo o violaciones a
                la seguridad. <br />Cumplir con los requisitos legales para la
                prevención del lavado de activos y financiación del terrorismo.
                <br />Realizar la verificación de datos o referencias suministradas
                con terceros o entidades. <br /><br />EMPLEADOS<br /><br /> Gestionar actividades de
                capacitación.<br /> Cumplir con la atención de derechos de los
                ciudadanos.<br /> Adelantar procedimientos administrativos. <br />Realizar
                el registro de asistencia a eventos o recepciones sociales y/o
                administrativos.<br /> Realizar actividades de gestión administrativa.
                Realizar control de horario y cumplimiento de protocolos de
                bioseguridad. <br />Gestionar y realizar el pago de nómina. Gestionar
                las actividades asociadas al manejo de personal. <br />Gestionar las
                actividades propias del trabajo temporal. <br />Cumplir con los
                requisitos de pago de prestaciones sociales. <br />Cumplir con los
                requisitos de gestión de riesgos laborales. <br />Promocionar y
                gestionar ofertas de empleo. <br />Realizar la selección y promoción
                de oportunidades laborales. <br />Gestionar la seguridad en todos sus
                aspectos. <br />Controlar el acceso y salida de personas en las
                instalaciones.<br /> Adelantar campañas de Actualización de datos e
                información de cambios en el tratamiento de datos personales.<br />
                Realizar la verificación de datos y referencias.<br /> Realizar el
                envío de comunicaciones a nivel general. <br />Asegurar la gestión de
                la seguridad en nuestras instalaciones. <br />Adelantar
                investigaciones en caso de situaciones de riesgo o violaciones a
                la seguridad. <br />Preservar la seguridad de los activos y personas.
                <br />Gestionar riesgos o accidentes dentro de las instalaciones.<br />
                Adelantar comunicaciones o contacto vía correo electrónico, SMS,
                teléfono u otro medio.<br /> Cumplir lo dispuesto por el ordenamiento
                jurídico colombiano en materia laboral y de seguridad social,
                entre otras, aplicables a exempleados o empleados actuales. <br />
                Cumplir lo dispuesto por el ordenamiento jurídico colombiano en
                materia de seguridad y salud en el trabajo.<br /> Realizar
                convocatorias o citas para entrevistas o pruebas. <br />Adelantar la
                gestión de riesgos laborales y la prevención y protección del
                recurso humano ante enfermedades y accidentes del ámbito
                laboral. <br />Trámite de requerimientos por parte de entidades como
                IPS, EPS, ARL. <br /><br />ASPIRANTES<br /><br /> Gestionar actividades de capacitación.
                <br />Adelantar procedimientos administrativos. <br />Realizar actividades
                de gestión administrativa.<br /> Realizar la gestión de clientes.
                <br />Realizar análisis de perfiles.<br /> Realizar encuestas de opinión.
                Realizar procesos de formación de personal interno. <br />Cumplir con
                los requisitos de gestión de riesgos laborales. Promocionar y
                gestionar ofertas de empleo. <br />Realizar la selección y promoción
                de oportunidades laborales. <br />Controlar el acceso y salida de
                personas en las instalaciones.<br /> Adelantar campañas de
                Actualización de datos e información de cambios en el
                tratamiento de datos personales. <br />Realizar la verificación de
                datos y referencias. Adelantar la administración de Sistemas de
                Información.<br /> Asegurar la gestión de la seguridad en nuestras
                instalaciones. <br />Adelantar investigaciones en caso de situaciones
                de riesgo o violaciones a la seguridad. <br />Cumplir lo dispuesto por
                el ordenamiento jurídico colombiano en materia de seguridad y
                salud en el trabajo.<br /> Alimentar la base de datos de candidatos
                para tenerlo en cuenta en futuras oportunidades. <br />Realizar
                convocatorias o citas para entrevistas o pruebas.<br /> Cumplir con
                los requisitos legales para la prevención del lavado de activos
                y financiación del terrorismo.<br /> Cumplir con los requisitos
                legales para la prevención del lavado de activos y financiación
                del terrorismo.<br /> Adelantar la gestión de riesgos laborales y la
                prevención y protección del recurso humano ante enfermedades y
                accidentes del ámbito laboral. <br /><br />CLIENTES<br /><br /> Cumplir con la atención
                de derechos de los ciudadanos. <br />Recibir y gestionar
                requerimientos internos o externos sobre productos o servicios.<br />
                Realizar actividades de fidelización.<br /> Generar estadísticas
                internas. Adelantar procedimientos administrativos.<br /> Realizar
                actividades de gestión administrativa.<br /> Realizar la gestión de
                clientes. Realizar actividades de marketing tradicional o
                digital mediante el uso de las tecnologías de la información.<br />
                Realizar análisis de perfiles. Realizar encuestas de opinión.<br />
                Realizar prospección comercial.<br /> Realizar actividades de
                segmentación de mercado. <br />Generar modelos y datos para la toma de
                decisiones.<br /> Adelantar comunicaciones a distancia para la venta
                de productos o servicios.<br /> Adelantar actividades de comercio
                electrónico. <br />Adelantar campañas de Actualización de datos e
                información de cambios en el tratamiento de datos personales.<br />
                Realizar consultas y reportes ante las centrales de riesgos
                financieras cuando así sea requerido.<br /> Realizar la verificación
                de datos y referencias.<br /> Realizar la verificación de requisitos
                jurídicos, técnicos y/o financieros.<br /> Adelantar la administración
                de Sistemas de Información.<br /> Adelantar la administración de
                Sistemas de Información, gestión de claves, administración de
                usuarios, etc. <br />Realizar el envío de comunicaciones a nivel
                general.<br /> Remitir o enviar información relacionada con el objeto
                social de la organización. <br />Adelantar comunicaciones o contacto
                vía correo electrónico, SMS, teléfono u otro medio.<br /> Cumplir con
                los requisitos legales para la prevención del lavado de activos
                y financiación del terrorismo. Realizar validaciones de
                identidad. <br /><br />TRATAMIENTO DE DATOS SENSIBLES.<br /><br /> Los datos sensibles
                recolectados serán tratados con las siguientes finalidades: (i)
                Gestionar la seguridad de la información en todos sus aspectos;
                (ii) Adelantar investigaciones en caso de situaciones de riesgo
                o violaciones a la seguridad; (iii) Realizar validaciones de
                identidad. <br /><br />AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS.<br /><br /> MONONOVO
                S.A.S. solicitará de manera libre, previa, expresa y debidamente
                informada, la autorización por parte de los titulares de los
                datos personales y para ello generará mecanismos idóneos
                garantizando para cada caso que sea posible verificar el
                otorgamiento de dicha autorización. La misma, podrá constar en
                cualquier medio, bien sea un documento físico, digital,
                electrónico o en cualquier formato que garantice su posterior
                consulta a través de herramientas técnicas, cumpliendo con los
                requisitos establecidos en la Ley, especialmente los aspectos
                referidos en la Ley 1266 de 2008.<br /><br /> AUTORIZACIÓN TRATAMIENTO DE
                DATOS NIÑOS, NIÑAS Y ADOLESCENTES.<br /><br />En el Tratamiento de datos
                personales de niños, niñas y adolescentes se asegurará el
                respeto a los derechos de los menores de edad. MONONOVO S.A.S.
                podrá requerir el tratamiento de datos personales de menores de
                edad para dar cumplimiento a aspectos legales como: I) El
                artículo 32 de la Ley 789 de 2002 relacionado con la obligación
                de adelantar la vinculación de aprendices y II) El derecho de
                los afiliados al Sistema de Seguridad Social de afiliar a
                cualquier pariente dentro del tercer grado de consanguinidad o a
                cualquier menor de 12 años (sea o no pariente) siempre y cuando
                dependa económicamente. En caso de requerir la recolección
                directa de datos personales de niños, niñas y adolescentes,
                MONONOVO S.A.S. solicitará la autorización de tratamiento con el
                consentimiento informado de los padres o adultos responsables de
                los menores de edad. <br /><br />MEDIDAS DE SEGURIDAD Y PROTECCIÓN.<br /><br /> MONONOVO
                S.A.S. ha adoptado las medidas técnicas, jurídicas, humanas y
                administrativas necesarias para procurar la seguridad de los
                datos de carácter personal protegiendo la confidencialidad,
                integridad, uso, acceso no autorizado y/o fraudulento. Así
                mismo, internamente se han implementado protocolos y
                lineamientos de seguridad de obligatorio cumplimiento para todo
                el personal con acceso a datos de carácter personal y a los
                sistemas de información. <br /><br />OBLIGACIONES DE LOS ENCARGADOS DE LA
                INFORMACIÓN PERSONAL.<br /><br /> Las empresas y/o personas externas a
                MONONOVO S.A.S., que en virtud de una relación contractual
                realice el tratamiento de datos personales, deberá cumplir con
                las siguientes obligaciones: Garantizar al titular el acceso,
                consulta, actualización, rectificación de sus datos personales.
                Solicitar y conservar copia de la respectiva autorización para
                el tratamiento de datos personales informando la finalidad de la
                recolección Conservar la información bajo las condiciones de
                seguridad que impida la adulteración, pérdida, consulta, uso o
                acceso no autorizado o fraudulento Adoptar un manual interno de
                políticas que garanticen el cumplimiento de la Ley 1581 de 2012,
                relativa a la protección de datos personales. Permitir el acceso
                a la información únicamente a las personas que pueden tener
                acceso a ella. Cumplir con las obligaciones establecidas en el
                artículo 18 de la Ley 1581 de 2012, y sus respectivos decretos
                reglamentarios, relativos a la protección de datos personales.
                <br /><br />EN CASO DE OPERAR COMO ENCARGADO DE LA INFORMACIÓN.<br /><br /> En los casos
                de que MONONOVO S.A.S. opere como encargado de la información,
                los Responsables de la información deberán solicitar y conservar
                la autorización del titular de la información, para el
                tratamiento de los datos personales por parte nuestra, por lo
                tanto MONONOVO S.A.S. presume que el Responsable de la
                información, cuenta con las autorizaciones previas y expresas de
                los titulares con quien tiene contacto, para hacer uso de sus
                datos personales y suministrará copia de tales autorizaciones en
                caso de que como encargados lo requiramos, para los fines
                consagrados en la política de tratamiento de datos personales.
                <br /><br />DERECHOS DE LOS TITULARES.<br /><br /> El Derecho Fundamental al Habeas
                Data, faculta al titular del dato solicitar el acceso,
                actualización, rectificación y supresión de sus datos personales
                que se encuentran en posesión de un tercero, a su vez, puede
                revocar la autorización que ha otorgado para el tratamiento. Si
                un titular de datos personales considera que MONONOVO S.A.S.
                tiene acceso a sus datos personales, esta persona puede en todo
                momento solicitar la consulta de sus datos, o si considera que
                MONONOVO S.A.S. está haciendo mal uso de sus datos, puede
                realizar la respectiva reclamación. Igualmente, el titular de la
                información tiene a su disposición los derechos contenidos en el
                Titulo II y V de la Ley 1266 de 2008. <br /><br />El titular está facultado
                para solicitar:<br /><br /> Acceder en forma gratuita a sus datos personales
                que hayan sido objeto de Tratamiento. Conocer, actualizar y
                rectificar su información. Este derecho se podrá ejercer, entre
                otros frente a datos parciales, inexactos, incompletos,
                fraccionados, que induzcan a error, o aquellos cuyo tratamiento
                esté expresamente prohibido o no haya sido autorizado. Conocer
                por qué y para qué MONONOVO S.A.S., recolecta información en
                base de datos. Revocar la autorización dada para contener
                información personal en las bases de datos de MONONOVO S.A.S.
                y/o solicitar la supresión del dato siempre que no exista un
                deber legal o contractual que impida eliminarlos. Presentar
                queja ante la Superintendencia de Industria y Comercio, cuando
                MONONOVO S.A.S. no haya atendido satisfactoriamente consultas o
                reclamos. Solicitar prueba de la autorización otorgada al
                responsable del tratamiento salvo cuando expresamente se
                exceptúe como requisito para el tratamiento, de conformidad con
                lo previsto en el artículo 10 de la Ley 1581 de 2012. Abstenerse
                de responder las preguntas sobre datos sensibles Tendrá carácter
                facultativo las respuestas que versen sobre datos sensibles o
                sobre datos de las niñas y niños y adolescentes. <br /><br />TRANSFERENCIA
                DE DATOS.<br /><br /> El Titular autoriza expresamente a MONONOVO S.A.S.
                para compartir sus Datos Personales con sus aliados comerciales
                quienes desarrollarán y ejecutarán el servicio elegido por el
                usuario. Así mismo, autoriza a MONONOVO S.A.S. y a sus aliados
                comerciales para utilizar los Datos Personales suministrados en
                la gestión propia de MONONOVO S.A.S. y dentro de las alianzas
                comerciales que suscriba MONONOVO S.A.S., para generar valores
                agregados. Por ello, autoriza a MONONOVO S.A.S. para
                conservarlos, actualizarlos, procesarlos, consultarlos y
                tratarlos en las formas establecidas en la presente Política.
                <br /><br />COOKIES.<br /><br /> MONONOVO S.A.S., utiliza” Cookies” que son pequeñas
                unidades de información almacenada temporalmente en el disco
                duro del computador del usuario, útiles para navegar en el
                sitio. La información contenida en las ‘Cookies’ sirve, v.g.,
                para el control de sesiones, en particular navegación mejorada y
                para obtener un alto desempeño como usuario amigable de un sitio
                web, y para almacenar información personal relativa a
                identificación. La mayoría de los ‘browsers’ de la red aceptan
                las ‘cookies’ automáticamente. El usuario puede evitar esto
                cambiando la configuración de su ‘browser’. Puede eliminar las
                ‘cookies’ almacenadas en el PC en cualquier momento suprimiendo
                los archivos de Internet temporales (“Herramientas/Extras” de la
                barra del ‘Browser’ -“Opciones de Internet”).<br /><br /> SEGURIDAD.<br /><br /> En
                desarrollo del principio de seguridad establecido en la Ley 1581
                de 2012, MONONOVO S.A.S. adoptará las medidas técnicas,
                tecnológicas, humanas y administrativas que sean necesarias para
                otorgar seguridad a los registros evitando su adulteración,
                pérdida, consulta, uso o acceso no autorizado o fraudulento.
                MONONOVO S.A.S. mantendrá protocolos de seguridad de obligatorio
                cumplimiento para el personal con acceso a los datos de carácter
                personal y a los sistemas de información. <br /><br />ATENCIÓN Y GESTIÓN DE
                PQRS.<br /><br /> Peticiones: Mecanismo mediante el cual las personas
                naturales y/o jurídicas pueden presentar solicitudes respetuosas
                ante la autoridad competente, con el fin de obtener respuesta a
                sus requerimientos y/o la prestación de un servicio. <br /><br />Tiempo de
                respuesta:<br /><br /> Información de carácter general 15 días hábiles
                contados a partir del día siguiente de recibido. Solicitud de
                documentos 10 días hábiles contados a partir del día siguiente
                de recibido. Quejas: Inconformidad en relación a una conducta
                que se considera irregular de los empleados de la compañía en
                desarrollo de sus funciones. Tiempo de respuesta: general 15
                días hábiles contados a partir del día siguiente de recibido.
                Reclamos: Inconformidad por un proceso relacionado a la
                prestación del servicio. Tiempo de respuesta: general 15 días
                hábiles contados a partir del día siguiente de recibido.
                Sugerencias: Propuesta de mejoramiento de un proceso interno
                relacionado con la prestación del servicio. Tiempo de respuesta:
                general 15 días hábiles contados a partir del día siguiente de
                recibido. Consultas: Los Titulares o sus causahabientes podrán
                consultar la información personal del Titular que repose en
                cualquier base de datos, sea esta del sector público o privado.
                Tiempo de respuesta: De acuerdo a lo establecido en el Artículo
                14 de la Ley 1581 de 2012, 10 días hábiles contados a partir de
                la fecha de recibido. Canales de atención MONONOVO S.A.S. tiene
                habilitado los siguientes canales para que los titulares ejerzan
                su derecho al Habeas Data. Dirección Notificaciones Web Líneas
                de atención Chat (WhatsApp) <br /><br />PROCEDIMIENTO PARA EJERCER LOS
                DERECHOS COMO TITULAR DE LOS DATOS.<br /><br /> Titulares o personas
                facultadas para ejercer los derechos: Podrá ejercer sus derechos
                cualquier titular de la información o aquel que actúe como su
                representante legal. Los siguientes titulares son meramente con
                carácter ejemplificativo y no son exclusivos, ni excluyentes. <br /><br />1.
                PROVEEDORES O CONTRATISTAS.<br /> 2. EMPLEADOS.<br />3. ASPIRANTES.<br /> 4.
                CLIENTES.<br /><br /> Información que debe acreditar el titular o persona:
                Para el radicado y atención de su solicitud le solicitamos
                suministrar la siguiente información: <br /><br />1. Nombres y apellidos
                completos. <br />2. Tipo y número de identificación. <br />3. Datos de
                contacto (Dirección física y/o electrónica y teléfonos de
                contacto),<br /> 4. Medios para recibir respuesta a su solicitud,<br /> 5.
                Motivo(s)/hecho(s) que dan lugar al reclamo con una breve
                descripción del derecho que desea ejercer (conocer, actualizar,
                rectificar, solicitar prueba de la autorización otorgada,
                revocarla, suprimir, acceder a la información) <br />6. Firma (si
                aplica) y número de identificación.<br /><br />Todo ejercicio de sus
                derechos requiere de legitimación previa, de acuerdo con lo
                descrito en el artículo 2.2.2.25.4.1 de decreto 1074, por lo
                cual toda petición o reclamo asociados a solicitudes de acceso,
                rectificación, actualización o eliminación de datos deberá estar
                acompañada de cualquiera de los siguientes documentos:<br /><br /> 1.
                Fotocopia de su documento de identidad. <br />2. Carta autenticada de
                acreditación de causahabiente. <br />3. Carta autenticada de
                representación o apoderamiento. <br />4. Otro documento que acredite
                la facultad de representación del titular de los datos.
                <br /><br />Solicitud de imágenes o videos: En caso de que el titular de la
                información solicite acceso a imágenes y/o videos donde se
                capture su información, deberá seguir el siguiente
                procedimiento: <br /><br />1. Indicar los hechos de la solicitud,
                estableciendo fecha, hora, lugar y demás información que
                facilite la ubicación del fragmento de imagen o video. <br />2.
                Justificar la necesidad de la solicitud. <br />3. Aportar los
                documentos que permitan justificar que el titular es la persona
                indicada para realizar dicha solicitud. En caso de que el
                interesado sea un tercero, deberá aportar el documento de
                autorización para el acceso a esa información por parte del
                titular del dato. <br /><br />Procedimiento para trámites por solicitud de
                imágenes y/o videos: Para que proceda el trámite, MONONOVO
                S.A.S.: <br /><br />1. Verificará que la información aún se encuentre
                almacenada en sus servidores, de acuerdo con lo establecido en
                el numeral 2.2.2 de la presente política. <br />2. Revisará dicha
                solicitud, y verificará si es procedente, revisando que no
                afecte el derecho a la intimidad, y otros derechos fundamentales
                de terceras personas, diferentes al titular de la información
                que se encuentren en dichas imágenes y/o video. <br />3. En caso de
                que afecte derechos de terceros, MONONOVO S.A.S. notificará que
                dentro de la información solicitada se encuentran imágenes de
                terceros, por lo cual se requiere realizar la anonimización
                (hacer borrosa o fragmentar) de los datos. Los costos de dicho
                proceso de anonimización deben ser cancelados por el
                solicitante. <br />4. En caso de que no afecte derechos fundamentales
                de terceros, MONONOVO S.A.S. citará al titular de la información
                en sus instalaciones para que pueda visualizar la información
                que requiere. <br /><br />RESPONSABLE DEL CUMPLIMIENTO DE LA POLÍTICA.<br /><br /> El
                responsable del efectivo cumplimiento de la Política, así como
                de las consultas y reclamos relacionados con la protección de
                datos personales de los titulares en MONONOVO S.A.S. será el
                área de Legales de la sociedad. En todo caso el responsable,
                podrá requerir otras áreas de MONONOVO S.A.S. para efectos de
                verificar el cumplimiento de la normativa relativa a protección
                de datos personales. En caso de cualquier duda respecto a esta
                política, puede contactarnos a través de los canales
                anteriormente descritos. <br /><br />DISPOSICIONES FINALES.<br /><br /> Medidas
                permanentes: En el tratamiento de datos personales, MONONOVO
                S.A.S. de manera permanente, verificará en sus procesos,
                protocolos, procedimientos y políticas, que se garantice el
                derecho de hábeas data a los titulares de la información.
                <br /><br />Vínculo con la política: Cualquier titular de la información que
                tenga alguna relación con MONONOVO S.A.S., deberá acatar la
                presente política. Los titulares de la información, diferente a
                los usuarios finales, deberán cumplir con el manual interno de
                políticas relativas a la protección de datos personales.
                Cumplimiento de los principios: MONONOVO S.A.S. garantiza los
                principios de legalidad, finalidad, libertad, veracidad o
                calidad, transparencia, acceso y circulación restringida,
                seguridad y confidencialidad sobre los datos que reposan en las
                bases de datos de MONONOVO S.A.S.<br /><br /> Inscripción de las bases de
                datos en el RNBD: Dando cumplimiento a las disposiciones
                normativas, MONONOVO S.A.S. llevará a cabo la inscripción de las
                bases de datos que tiene en posesión de acuerdo con lo descrito
                en el Decreto 090 de enero de 2018, el cual modifica y redefine
                los artículos 2.2.2.26.1.2 Ámbito de Aplicación y 2.2.2.26.3.1
                Plazo de Inscripción del Decreto 1074 de 2015. <br /><br />Permanencia: Las
                bases de datos en las que se registrarán los datos personales
                tendrán una vigencia igual al tiempo en que se mantenga y
                utilice la información para las finalidades descritas en esta
                política. Los datos personales proporcionados se conservarán
                mientras se mantenga la relación contractual con el Titular de
                la información o mientras no se solicite su supresión por el
                interesado y siempre que no exista un deber legal de
                conservarlos.<br /><br /> Fecha de aprobación de la política y entrada en
                vigor: La presente política entra en vigor a partir de, 1 de
                octubre de 2022 fecha de publicación registrada inicialmente, en
                su versión 3 reemplazando nuestra política anterior.
              </p>
            </div>
          </div>
        </div>
        <button className="btn-enunciado-poli">
          <Link className="blanco" to="/paga_prestamo">
            ¡Solicítalo Ahora!
          </Link>
          &nbsp;&nbsp;&nbsp;
        </button>
      </div>
      <hr></hr>
      <Footer></Footer>
    </div>
  );
}

export default PoliticasTerminos;
