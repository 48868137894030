import React from 'react'
import neo from '../img/grupo182.svg'
import { Link, Outlet } from "react-router-dom";
import iconFacebook from '../img/icon-facebook.png'
import iconInstagram from '../img/icon-instagram.png'
import iconTiktok from '../img/icon-tiktok.png'
import iconYoutube from '../img/icon-youtube.png'
import '../css/footer.css'


function Footer() {
  return (
    <div id="footer">
      <div className="datos-contacto">
        <div className="container">
          <div className="row">
            <div className="col">
            <div><img src={neo} className="img-foo"/></div> <br></br>
              {/* <div className="row icons-social">
              <div className="col left"><img src={iconInstagram} className="img-responsive"/></div>
                <div className="col left"><img src={iconFacebook} className="img-responsive"/></div>
                <div className="col left"><img src={iconYoutube} className="img-responsive"/></div>
                <div className="col left"><img src={iconTiktok} className="img-responsive"/></div>               
              </div> */}
              <p className='center'>www.tubongo.com</p>
            </div>
            <div className="col ">
              
              <h2 className='arre'>¿Cómo podemos ayudarte?</h2>
              <p>Comunícate con nosotros a través del correo </p>
              {/* <a className="blanco2 d-flex justify-content-center center" href="https://api.whatsapp.com/send?phone=573143764313&text=Validar%20identidad%20sin%20Easybyo"  >3143764313</a>   */}
              <a className="blanco2 d-flex justify-content-center center">soporte@tubongo.com</a>
            </div>
            <div className="col enlaces">
            <Link className="letra-fouter center" to='/Preguntas_Frecuentes'>Preguntas Frecuentes</Link>
              <br></br>
              <Link className="letra-fouter center" to='/Terminos_Condiciones'>Términos y Condiciones</Link>
              <br></br>
              {/* <a >Términos y Condiciones</a>< br /> */}
              <Link className="letra-fouter center" to='/Politicas_Privacidad'>Políticas de Privacidad </Link>
              <br></br>
              {/* <a>Artículos</a> */}
            </div>
          </div>
        </div> 
      </div>
      <div className="row copy-right">
        <b>tu bongo 2022 | Todos los derechos reservados 2022</b>
      </div>
    </div>
  )
}

export default Footer
