export default function AmortizacionIntereses(
  monto,
  numCuotas,
  valorCuota,
  costosAdim,
  diferenciaDiasCasoUno,
  diferenciaDiasCasoDos,
  tasaEd,
  tasaEm,
  ultimoDiaMesSiguiente,
  ultimoDiaMesActual,
  mesTerceraCuota,
  diaActual
) {
  let interesTotal = 0;
  let intentos = 0;
  let saldo = monto;
  let montoPrestamo = monto;
  let interes = 0;
  let amortizacion = 0;
  let total = 0;
  let mes = 1;
  let nunCuotas = numCuotas;
  let diferenciaDiasDos = diferenciaDiasCasoDos;
  let diferenciaDiasUno = diferenciaDiasCasoUno;
  let cuota = valorCuota;
  let costosAdmin = costosAdim;
  let mesActual = ultimoDiaMesActual;
  let mesSiguiente = ultimoDiaMesSiguiente;
  let cuotaCredito = cuota - costosAdmin;
  let ultimoDiaMesTerceraCuota = new Date(
    mesTerceraCuota.getFullYear(),
    mesTerceraCuota.getMonth() + 1,
    0
  );
  var diferenciaDiasMes3 =
    (ultimoDiaMesTerceraCuota - mesSiguiente) / (1000 * 60 * 60 * 24) + 1;

  var diferenciaDiasMes2 =
    (mesSiguiente - mesActual) / (1000 * 60 * 60 * 24) + 1;

  //variables para la laquidacion- guardar en la db
  //variables de saldo
  var saldoc1 = 0;
  var saldoc2 = 0;
  var saldoc3 = 0;

  //variables de intereses
  var interesc1 = 0;
  var interesc2 = 0;
  var interesc3 = 0;

  //variables para amortitazion
  var amortizacionc1 = 0;
  var amortizacionc2 = 0;
  var amortizacionc3 = 0;


  while (intentos < numCuotas) {
    if (diaActual < 20) {
      if (mes === 1) {
        if (mes <= numCuotas) {
          if (mes === 1) {
            interes =
              montoPrestamo * Math.pow(1 + tasaEd, diferenciaDiasCasoDos) -
              montoPrestamo;
          } else {
            interes = saldo * tasaEm;
          }
        } else {
          interes = 0;
        }
        amortizacion = cuotaCredito - interes;
        saldo = saldo - amortizacion;
        interesTotal = interes + interesTotal;
        amortizacionc1 = amortizacion;
        saldoc1 = saldo;
        interesc1 = interes;
      } else {
        if (mes === 2) {
          if (mes <= numCuotas) {
            if (mes === 1) {
              interes =
                cuotaCredito * Math.pow(1 + tasaEd, diferenciaDiasMes2) -
                cuotaCredito;
            } else {
              interes = saldo * tasaEm;
            }
          } else {
            interes = 0;
          }
        } else if (mes === 3) {
          if (mes <= numCuotas) {
            if (mes === 1) {
              interes =
                costosAdmin * Math.pow(1 + tasaEd, diferenciaDiasMes3) -
                costosAdmin;
            } else {
              interes = saldo * tasaEm;
            }
          } else {
            interes = 0;
          }
        }
        amortizacion = cuotaCredito - interes;
        saldo = saldo - amortizacion;
        interesTotal = interes + interesTotal;
        if (intentos === 1) {
          amortizacionc2 = amortizacion;
          saldoc2 = saldo;
          interesc2 = interes;
        }else if (intentos === 2) {
          amortizacionc3 = amortizacion;
          saldoc3 = saldo;
          interesc3 = interes;
        }
      }
      mes = mes + 1;
      intentos = intentos + 1;
    } else {
      if (mes === 1) {
        if (mes <= numCuotas) {
          if (mes === 1) {
            interes =
              montoPrestamo * Math.pow(1 + tasaEd, diferenciaDiasCasoUno) -
              montoPrestamo;
          } else {
            interes = saldo * tasaEm;
          }
        } else {
          interes = 0;
        }
        amortizacion = cuotaCredito - interes;
        saldo = saldo - amortizacion;
        interesTotal = interes + interesTotal;
        amortizacionc1 = amortizacion;
        saldoc1 = saldo;
        interesc1 = interes;
      } else {
        if (mes === 2) {
          if (mes <= numCuotas) {
            if (mes === 1) {
              interes =
                cuotaCredito * Math.pow(1 + tasaEd, diferenciaDiasMes2) -
                cuotaCredito;
            } else {
              interes = saldo * tasaEm;
            }
          } else {
            interes = 0;
          }
        } else if (mes === 3) {
          if (mes <= numCuotas) {
            if (mes === 1) {
              interes =
                costosAdmin * Math.pow(1 + tasaEd, diferenciaDiasMes3) -
                costosAdmin;
            } else {
              interes = saldo * tasaEm;
            }
          } else {
            interes = 0;
          }
        }
        amortizacion = cuotaCredito - interes;
        saldo = saldo - amortizacion;
        interesTotal = interes + interesTotal;
        if (intentos === 1) {
          amortizacionc2 = amortizacion;
          saldoc2 = saldo;
          interesc2 = interes;
        }else if (intentos === 2) {
          amortizacionc3 = amortizacion;
          saldoc3 = saldo;
          interesc3 = interes;
        }
      }
      mes = mes + 1;
      intentos = intentos + 1;
    }
  }

  let infoIntereses = {
    saldoc1: saldoc1,
    saldoc2: saldoc2,
    saldoc3: saldoc3,
    interesc1: interesc1,
    interesc2: interesc2,
    interesc3: interesc3,
    amortizacionc1: amortizacionc1,
    amortizacionc2: amortizacionc2,
    amortizacionc3: amortizacionc3,
  }

  localStorage.setItem("infoIntereses", JSON.stringify(infoIntereses));

  return interesTotal;
}
